import React from 'react';
// import axios from 'axios';
import  axiosRequest  from './apiCalls';


class TeacherService {

    constructor() {
       
        this.saveTeacher = this.saveTeacher.bind(this);
        this.getYear = this.getYear.bind(this); 
        this.getTeacherList = this.getTeacherList.bind(this);        
    }
   
   async saveTeacher(teacherModel) {
       console.log("service--", teacherModel);
       const id = 0;
       teacherModel.teacherID=0;
       //await axios.post(
       //    'https://localhost:7042/api/Student/Save',
       //    {
       //        id: id
       //       /* description: description,*/
       //    }
       //);
       return await axiosRequest.post('Teacher/Save/', teacherModel)
           .then(resp => this.id = resp.data) 
       
    }
    async deactivateTeacher(teacherId) {
      console.log("service--", teacherId);
      const result=false;
      return axiosRequest.patch('Teacher/DeactivateTeacher?teacherId='+teacherId+'&isActive=false')
      .then((response) => {
         console.log("response",response.data);
         return response.data 
       })
        
    }
    async getYear() {
        return await axiosRequest.get('Teacher/GetYear').then(response => response.data)
    }
    async getTeacheres() {
      return await axiosRequest.get('Teacher/GetTeacheres').then(response => response.data)
  }
    async getTeacherDetails(teacherId) {
     // return await axiosRequest.get('Teacher/GetTeacherDetails/?teacherId=${teacherId}');
      return await axiosRequest.get(`Teacher/GetTeacherDetails/?teacherId=${teacherId}`);
  }
    async getTeacherList(teacherRequest) {

     // console.log("teacherRequest",teacherRequest)
        return  axiosRequest({
            method: "post",
            url: 'Teacher/GetTeacherList',
            data:teacherRequest,           
            config: {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
          })
            .then((response) => {
              console.log(response.data);
              return response.data
           
            })
            .catch((error) => {
              console.log("the error has occured: " + error);
            });
    }
    async updateTeacher(teacherModel) {
      //console.log("service--", teacherModel);
      const result=0;
      //studentModel.studentID=0;
      
      return await axiosRequest.put('Teacher/UpdateTeacher/', teacherModel)
          .then(resp => this.result = resp.data) 
      
    }
}


export default TeacherService;