
export default class StudentModel {

    
    studentID;
    /*firstName: string;*/
    yearOfJoining;
    rollNumber;
   
    registrationNumber;
    fullName;
    dateOfBirth;
    gender;
    email;
    mobileNumber;
    courseId;
    batchId;
    courseYearId;
    semesterId;
   /* private salary: number;*/

    constructor(studentID, yearOfJoining, rollNumber, registrationNumber, fullName, dateOfBirth, gender, email,
        mobileNumber, courseId, batchId, courseYearId, semesterId) {
        this.studentID = studentID;
        this.yearOfJoining = yearOfJoining;
        this.rollNumber = rollNumber;
        this.registrationNumber = registrationNumber;
        this.fullName = fullName;
        this.dateOfBirth = dateOfBirth;
        this.gender = gender;
        this.email = email;
        this.mobileNumber = mobileNumber;
        this.courseId = courseId;
        this.batchId = batchId;
        this.courseYearId = courseYearId;
        this.semesterId = semesterId;
        //this.lastName = lastName;
        //this.salary = salary;
    }
   
}

