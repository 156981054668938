
export default class PromotionModel {
    
    courseId;
    batchId;
    yearOfJoining;
    rollNumber;
      
    fromCourseYearId;
    fromSemesterId;
    toCourseYearId;
    toSemesterId;
   /* private salary: number;*/

    constructor(courseId,batchId,yearOfJoining,fromCourseYearId, fromSemesterId,toCourseYearId,toSemesterId) {
        //this.studentID = studentID;
        this.courseId = courseId;
        this.batchId = batchId;
        this.yearOfJoining = yearOfJoining;

        // this.rollNumber = rollNumber;
        // this.registrationNumber = registrationNumber;
        // this.fullName = fullName;
        // this.dateOfBirth = dateOfBirth;
        // this.gender = gender;
        // this.email = email;
        // this.mobileNumber = mobileNumber;
        
        this.fromCourseYearId = fromCourseYearId;
        this.fromSemesterId = fromSemesterId;
        this.toCourseYearId = toCourseYearId;
        this.toSemesterId = toSemesterId;
       
    }
   
}

