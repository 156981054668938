import "./student-upload-widget.scss";
import { Button, Form, Input, Select,Upload,notification,Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import CourseService from "../../services/courseService";
import BatchService from "../../services/batchService";
import SemesterService from "../../services/semesterService";
import CourseYearService from "../../services/courseYearService";
import StudentService from '../../services/studentService';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const StudentUploadWidget = () => {
  const { Option } = Select; 
  const courseService = new CourseService();
  const batchService = new BatchService();
  const semesterService = new SemesterService();
  const courseYearService = new CourseYearService();
  const studentService = new StudentService();

 const [courseList, setCourseList] = useState(0);
  const [batchList, setBatchList] = useState(0);
  const [courseYearList , setcourseYearList] = useState(0);
  const [semesterList , setsemesterList] = useState(0);
  const [yearList , setyearList] = useState(0);
  const [loading , setloading] = useState(0);
  const navigate = useNavigate();
  
  // const onChange = (date, dateString) => 
  // {
  //   console.log(date, dateString);
  // };
  const [form] = Form.useForm();

  const populateCourse = async () => {
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId;
    const _courseList = await courseService.getCoursesForTeacher(teacherId);
    setCourseList(_courseList.data);
  }
  const hanleCourseChange=(e)=>
  {
    form.setFieldsValue({ courseId: e });
     populateBatchByCourse(e);
     populateCourseYearByCourse(e);
  }
  const hanleBatchChange=(e)=>{
    form.setFieldsValue({ batchId: e });
  }
  const  populateBatchByCourse=async(courseId)=> {   
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId;
    const _batchList = await batchService.getBatchesByCourseForTeacher(courseId,teacherId);
    setBatchList(_batchList.data);
}
const populateYearOfJoining=async()=> {
  const _yearList = await studentService.getYear();  
  setyearList(_yearList);       
}
  const  populateCourseYearByCourse=async(courseId)=> {
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId;
    const _courseYearList = await courseYearService.getCourseYearForTeacher(courseId,teacherId);
    setcourseYearList(_courseYearList.data);
  }
  const handleCourseYearChange = (event) => {
    form.setFieldsValue({ courseYearId: event });
    populateSemesterByCourseYear(event);
}
const handleSemesterChange=(e)=>{
  form.setFieldsValue({ semesterId: e });
}
const handleYearOfJoining=(e)=>
{
  form.setFieldsValue({ yearOfJoining: e });
}
const populateSemesterByCourseYear=async(courseYearId)=> {
  const items = JSON.parse(localStorage.getItem('userDetails'));    
  let teacherId=items.referenceId;
  const _semesterList = await semesterService.getSemesterForTeacher(courseYearId,teacherId);
  setsemesterList(_semesterList.data);
 }
 const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}
const uploadFile=e=>{
  // console.log("uploadFile",e);
  setloading(true);
   const fmData = new FormData();
   fmData.append("studentsfile", e);
   try {
         const res =  axios.post(
           "https://localhost:7042/api/StudentBulkUpload/StudentFileUpload",fmData,          
           {
             headers: { "content-type": "multipart/form-data" },           
           }
         );
        // setloading(false);
         console.log(res.data);
     }
   catch (ex) {
     console.log(ex);
   }
   setloading(false);
 }
 let basefile="";
const fileuploadChange =  e => {
   
 // debugger;
  //let f=e.fileList[0];
  //console.log("f",f);
  //setloading(true);
  let extension=e.file.name.slice(e.file.name.lastIndexOf('.')+1);
 // console.log("extension",extension);
 
  const isJpgOrPng = extension === 'xlsx' || extension === 'xls';
  if (!isJpgOrPng) {
    openNotificationWithIcon('error','You can only upload excel file!');
   // console.log('You can only upload JPG/PNG file!');
  }
  
 // console.log("f",f);
 else{
 
  let fileList = [...e.fileList];
 // console.log("fileList",fileList[0]);
  basefile=e.fileList[0];
  //console.log(e.fileList[0]);
    // Accept 5 files only
    //fileList = fileList.slice(-5);
  //   const file =e.fileList[0];
  //   console.log("file", file);
  //  // basefile=file;
  //   const reader = new FileReader();
  //       reader.onloadend = () => {
  //           console.log(reader.result);
  //           basefile=reader.result;
  //           // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
  //       };
  //       reader.readAsDataURL(file.originFileObj);

    /*fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
        console.log("base64",e.target.result);
        basefile= e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });
    */
  //var reader = new FileReader();
//  const base64 = await new Promise((resolve, reject) => {
//   const fileReader = new FileReader();
//   fileReader.readAsDataURL(e.file.originFileObj)
 
//   fileReader.onload = () => {
//     resolve(fileReader.result);
//   }
//   fileReader.onerror = (error) => {
//     reject(error);
//   }
// })
// debugger;
//action="https://localhost:7042/api/Student/StudentBulkUpload1"
    form.setFieldsValue({ studentsFile: basefile });
  }
  //setloading(false);

};
const openNotificationWithIcon = (type,message) => {
  notification[type]({
    message: 'KLA',
    description:
      message,
  });
};
const handleSaveDataToStorage = (values) => {
  localStorage.setItem('uploadData', JSON.stringify(values));
  //localStorage.setItem('Password', pwd);
};
const handleRemoveDataToStorage = () => {
  localStorage.removeItem('Name');
  localStorage.removeItem('Password');
};
 const onSubmit = (values) => { 
  setloading(true);
    values["studentsFile"]= basefile.name;
     const fields = form.getFieldsValue();
     let result="";
      try {
          axios.post("https://localhost:7042/api/StudentBulkUpload/SaveStudentBulkUpload", values).then(response => {
          console.log("response", response.data);          
          if (response != null) {  
            result= response.data;           
            openNotificationWithIcon('success',result);
            handleSaveDataToStorage(values);
            onReset();
            setloading(false);
            navigate("/studentUploadList");             
          }
      });   
      
  } 
  catch (ex) {
      console.log(ex);
  }
};
  

  const onReset = () => {
    form.resetFields();
  };
  useEffect(() => { 
    setloading(false);  
    populateCourse();
   populateYearOfJoining();
    
  },[]);
  return (
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Upload Student Details</h2></div>
      <div className="content-block">
        <section className="content">
        <Spin tip="Loading" size="large" spinning={loading}>
        <Form  form={form}  name="control-hooks" onFinish={onSubmit}>  
                  <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseId"  rules={[{ required: true,message: 'Course is required' }]}>
              <label className="form-label">Course<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Course"
                  size="large"                  
                  optionFilterProp="children"
                  onChange={(e)=>hanleCourseChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {courseList&&courseList.map(course => {return <Option value={course.courseId} key={course.courseId}>{course.courseName}</Option>})}
                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="batchId"  rules={[{ required: true,message: 'Batch is required'  }]}>
              <label className="form-label">Batch<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Batch"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>hanleBatchChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                 {batchList&&batchList.map(batch => {return <Option value={batch.batchId} key={batch.batchId}>{batch.batchName}</Option>})}
              </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseYearId"  rules={[{ required: true,message: 'Course Year is required' }]}>
                <label className="form-label">Course Year<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Course Year"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleCourseYearChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                {courseYearList&&courseYearList.map(courseYear => {return <Option value={courseYear.courseYearId} key={courseYear.courseYearId}>{courseYear.years}</Option>})}
                  </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="semesterId"  rules={[{ required: true,message: 'Semester is required'}]}>
              <label className="form-label">Semester<span className="l-mandatory">*</span></label>
              <Select className="w-100"
                  showSearch
                  placeholder="Semester"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleSemesterChange(e)}  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                 {semesterList&&semesterList.map(semester => {return <Option value={semester.semesterId} key={semester.semesterId}>{semester.semester}</Option>})}
                </Select>
              </Form.Item>
              </div>

              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="studentsFile"  rules={[{ required: true }]}>
              <label className="form-label">File Upload<span className="l-mandatory">*</span></label>
              <Upload onChange={fileuploadChange} accept="xlsx/xls"  className='form-control'
               action={uploadFile}>
              <Button>Select file</Button>
            </Upload>
              </Form.Item> 
              </div>



              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="yearOfJoining"  rules={[{ required: true }]}>
              <label className="form-label">Year Of Joining<span className="l-mandatory">*</span></label>
              <Select className="w-100"
                  showSearch
                  placeholder="Year Of Joining"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleYearOfJoining(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                  {yearList&&yearList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                 
                </Select>
              </Form.Item> 
              </div>
            
              <div className="form-group col-md-6 col-lg-3"></div>
             
              <div className="l-btn-wrapper mt-2">
                <Button type="button" onClick={onReset} className="l-btn l-btn--outline">Cancel</Button>
                <Button  htmlType="submit" className="l-btn l-btn--primary">Upload</Button>
              </div>
            </div>
          </Form>
          </Spin>
        </section>
      </div>
    </div>
  );
};
 
export default StudentUploadWidget;
