import "./SuperAdminSidenav.scss";
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import SvgIcon from "../svg-icon/SvgIcon";

import { useLocation } from 'react-router-dom'


const SuperAdminSidenav = () => {
  const SidebarData = [
    {
      title: "Home",
      path: "/superAdminHome",
      icon: <SvgIcon icon="dashboard" width="20" height="20" />,
    } 
    // {
    //   title: "Teacher Registration",
    //   path: "/teacherRegistration",
    //   icon: <SvgIcon icon="reg" width="20" height="20" />,
    // },
    // {
    //   title: "Teacher Allocation",
    //   path: "/teacherAllocation",
    //   icon: <SvgIcon icon="teacher" width="20" height="20" />,
    // },   
    // {
    //   title: "Teachers List",
    //   path: "/teacherList",
    //   icon: <SvgIcon icon="teacher-list" width="20" height="20" />,
    // },
    // {
    //   title: "Internship Applications",
    //   path: "/internshipAllApplications",
    //   icon: <SvgIcon icon="portfolio" width="20" height="20" />,
    // },

  ];

 const menuItemClick=(path)=>{
 
  if(path=="/teacherList")
  {
    console.log("path",path);
    localStorage.removeItem('teacherSearchData');
    this.forceUpdate();  
  }
 }
  const location = useLocation();
  
  return (
    <aside className="sidenav">
      <Menu
        selectedKeys={[location.pathname]}
        mode="inline"
        inlineIndent="20"
      >
        {SidebarData.map((item) => {
          const menuItems =  (
            <Menu.Item key={item.path} icon={item.icon}>
              <Link to={item.path} onClick={()=>menuItemClick(item.path)}>{item.title}</Link>
            </Menu.Item>
          );
          return menuItems;
        })}
      </Menu>

    </aside>
  );
};

export default SuperAdminSidenav;


