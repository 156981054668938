import "./verify-internship-application-widget.scss";
import { Table,TableProps, Button, Input,Form,notification,Modal } from "antd";
import { useForm } from "react-hook-form";
// import * as moment from 'moment';
import React, { useState, useEffect } from 'react';
import InterenshipService from "../../services/internshipService ";
import { json, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const VerifyInternshipApplicationWidget = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const { internshipRequestId } = useParams();

  const [internshipApplicationDetails , setinternshipApplicationDetails] = useState(0);
  const [studentList , setStudentList] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [internshipStatusId, setInternshipStatusId] = useState(0);

 // const [studentList , setStudentList] = useState([]); 
  
  const navigate = useNavigate(); 
  const interenshipService=new InterenshipService();

const populateIntershipDetails = async () => {  
  const result = (await interenshipService.getInternshipDetails(internshipRequestId));
 // console.log("result",result.data);
  var _internshipDetails=result.data.internshipDetailsResponse;
  //console.log("_internshipDetails",_internshipDetails);
  setinternshipApplicationDetails(_internshipDetails);
  var _internshipGroup=result.data.internshipGroupResponse;
  setStudentList(_internshipGroup); 
  setInternshipStatusId(_internshipDetails.applicationStatusId);
}
const showModal = () => {
  setIsModalOpen(true);
};

const handleOk =async() => {
  const remarks=document.getElementById("Remarks").value;
  // console.log("remarks",remarks);
   const items = JSON.parse(localStorage.getItem('userDetails'));
    let teacherId=items.referenceId;
  let verifyInternshipRequest={
    "internShipRequestId":internshipRequestId,
    "teacherId":teacherId,
    "remarks": remarks,     
    "ApplicationStatusId":3
  }
  const result = await interenshipService.updateInterenshipStatus(verifyInternshipRequest);
  if(result==true)
  {
    setIsModalOpen(false);
    openNotificationWithIcon('success','Application Rejected');
    navigate("/teacherHome");
  }
  
};

const handleCancel = () => {
  setIsModalOpen(false);
};

const columns = [
  {
    title: 'Roll Number',
    dataIndex: 'rollNumber',      
    key: 'rollNumber', 
  },
  {
    title: 'Batch Name',
    dataIndex: 'batchName',      
    key: 'batchName', 
  }, 
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',    
  }, 
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',    
  },   
 
];

const handelCancel=()=>
{
  const items = JSON.parse(localStorage.getItem('userDetails'));  
  let roleId=items.roleId;
  console.log("roleId",roleId);
  if(roleId==4)
  {
     navigate("/superAdminHome");
  }
  else{
    navigate("/teacherHome");
  }
 
}
const openNotificationWithIcon = (type,message) => {
  notification[type]({
    message: 'KLA',
    description:
      message,
  });
};

const handelApprove=async ()=>
{
   const items = JSON.parse(localStorage.getItem('userDetails'));
    let teacherId=items.userLoginId;
    let roleId=items.roleId;
    console.log("roleId",roleId);
  let verifyInternshipRequest={
    "internShipRequestId":internshipRequestId,
    "teacherId":teacherId,
    "remarks": "",     
    "ApplicationStatusId":4
  }
  const result = await interenshipService.updateInterenshipStatus(verifyInternshipRequest);
  if(result==true)
  {
    openNotificationWithIcon('success','Application Approved');
    if(roleId==4)
    {
       navigate("/superAdminHome");
    }
    else{
      navigate("/teacherHome");
    }
    //navigate("/teacherHome");
  }
}
  useEffect(() => {
    if(internshipApplicationDetails!=null)
    {
        populateIntershipDetails();
    }
    localStorage.removeItem('internshipData'); 
  },[]);

  return (
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Interenship Application Details</h2></div>
      <div className="content-block">
        <section className="content">
       <div className="row gx-3 gy-4">
      <div className="form-group col-md-12 col-lg-12"><b>Application Details</b> </div>
      <div className="form-group col-md-6 col-lg-3"> 
        <label className="form-label">Application Number<span className="l-mandatory"></span></label>               
                <label className="form-label">{internshipApplicationDetails.applicationNumber}</label>       
      </div>
      <div className="form-group col-md-6 col-lg-2"> 
        <label className="form-label">Application Type<span className="l-mandatory"></span></label>               
                <label className="form-label">{internshipApplicationDetails.internshipType}</label>       
      </div>
      <div className="form-group col-md-6 col-lg-3"> 
               <label className="form-label">Application Submitted By<span className="l-mandatory"></span></label>               
                <label className="form-label">{internshipApplicationDetails.applicationSubmittedBy}</label>       
             </div>
      <div className="form-group col-md-6 col-lg-2">        
                <label className="form-label">Start Date</label>
                <label className="form-label">{internshipApplicationDetails.startDate}</label>       
             </div>
              <div className="form-group col-md-6 col-lg-2">              
                <label className="form-label">End Date</label>
                <label className="form-label">{internshipApplicationDetails.endDate}</label>               
              </div>
        </div> 
            <div className="row gx-3 gy-4">
            <div className="form-group col-md-12 col-lg-12"><b>Organization Details</b></div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label">Name</label>
                <label className="form-label">{internshipApplicationDetails.organizationName}</label>  
               </div>
              <div className="form-group col-md-6 col-lg-3">             
                <label className="form-label">Address</label>
                <label className="form-label">{internshipApplicationDetails.organizationAddress}</label>  
              </div>
              <div className="form-group col-md-6 col-lg-3">                
                <label className="form-label">Contact Number<span className="l-mandatory">*</span></label>
                <label className="form-label">{internshipApplicationDetails.organizationContactNumber}</label>  
              </div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label">Email<span className="l-mandatory"></span></label>
                <label className="form-label">{internshipApplicationDetails.organizationEmail}</label>                
              </div>
              <div className="form-group col-md-12 col-lg-12"><b> Contact Person Details</b></div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label">Person Name</label>               
                <label className="form-label">{internshipApplicationDetails.contactPersonName}</label>
              </div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label"> Phone Number<span className="l-mandatory">*</span></label>
                <label className="form-label">{internshipApplicationDetails.contactNumber}</label>                
              </div>
            
              <div className="form-group col-md-6 col-lg-3">             
                <label className="form-label">Designation<span className="l-mandatory">*</span></label>
                <label className="form-label">{internshipApplicationDetails.designationName}</label>               
              </div>
              <div className="form-group col-md-6 col-lg-3">             
                <label className="form-label">Email</label>
                <label className="form-label">{internshipApplicationDetails.contactEmail}</label>                
              </div>
              
              </div>
              {studentList && studentList.length?  ( 
              <section className="content">       
                <Table dataSource={studentList} columns={columns} rowKey="studentId" pagination={false} />       
              </section>
                ):<div className="l-norecords">No records found !!</div>
              }
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handelCancel}>Cancel</Button> 
                {console.log("internshipStatusId",internshipStatusId)}
                {internshipStatusId!=4 ?(
                <Button type="button" className="l-btn l-btn--primary" onClick={handelApprove}>Approve</Button> 
                ):"" }
                 {internshipStatusId!=3 ?(
                <Button type="button" className="l-btn l-btn--primary" onClick={showModal}>Reject</Button>  
                ):"" }             
              </div>
              <Modal title="Interenship Application Remarks" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              <div className="content-block">
              <section className="content">
                <div className="row gx-3 gy-4">
                
                <div className="form-group col-md-12 col-lg-12"> 
                  <label className="form-label">Application Number</label>               
                          <label className="form-label">{internshipApplicationDetails.applicationNumber}</label>       
                </div>
                <div className="form-group col-md-12 col-lg-12">             
                <label className="form-label">Remarks<span className="l-mandatory">*</span></label>
                  <TextArea rows={4} columns={10} id="Remarks"/>            
              </div>
                </div>
                </section>
                </div>
              </Modal>
           </section>
       
      </div>
    </div>
  );
};
 
export default VerifyInternshipApplicationWidget;
