import React from 'react';
import  axiosRequest  from './apiCalls';
//import axios from 'axios';

class ReportService {
    constructor() {        
        this.generatePdfReport = this.generatePdfReport.bind(this);          
    }
    async generatePdfReport(internshipRequestId) {
        return await axiosRequest.get('Report/GeneratePdfReport/?internshipRequestId='+internshipRequestId)
              
    } 
  
}
export default ReportService;