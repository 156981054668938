import React from 'react';
import  axiosRequest  from './apiCalls';
//import axios from 'axios';


class SemesterService {

    constructor() {      
    
        this.getSemester = this.getSemester.bind(this);   
        this.getSemesterForTeacher = this.getSemesterForTeacher.bind(this);         
    }
    async getSemester(courseYearId) {
        return await axiosRequest.get(`Semester/GetSemester/?courseYearId=${courseYearId}`);
    }
    async getSemesterForTeacher(courseYearId,teacherId) {
       // return await axios.get(`https://localhost:7042/api/Semester/GetSemester/?courseYearId=${courseYearId}`);
       return await axiosRequest.get('Semester/GetSemesterForTeacher?courseYearId='+courseYearId+'&teacherId='+teacherId);
    }
}

export default SemesterService;