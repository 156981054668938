import React from 'react';
//import axios from 'axios';
import  axiosRequest  from './apiCalls';


class InternshipService {

    constructor() {
       
        this.saveInternShip = this.saveInternShip.bind(this);
        this.saveGroupInternShip=this.saveGroupInternShip.bind(this);
        this.getInternshipDetails = this.getInternshipDetails.bind(this); 
        this.getStudentInternshipListForAdmin=this.getStudentInternshipListForAdmin.bind(this);
        //this.getTeacherList = this.getTeacherList.bind(this);        
    }
   
   async saveInternShip(interenShipModel) {
       console.log("service--", interenShipModel);     
      var data=null;     
       return await axiosRequest.post('InternShip/SaveInternShip/', interenShipModel)
           .then(resp => this.data = resp.data)            
    }
    async saveGroupInternShip(groupInterenShipModel) {
      console.log("internShipDetailsRequestes service",groupInterenShipModel.internShipDetailsRequestes);
      console.log("groupInterenShipModel",groupInterenShipModel.internShipDetailsRequestes);
     let groupInternshipRequest={internshipRequest:groupInterenShipModel.interenShipModel,internShipDetailsRequestes:groupInterenShipModel.internShipDetailsRequestes};
     console.log("service--", groupInternshipRequest);    
     var data=null;
     return await axiosRequest.post('InternShip/SaveGroupInternShip/', groupInternshipRequest)
           .then(resp => this.data = resp.data)        
   }
   async getInternshipDetails(internshipRequestId) {
    return await axiosRequest.get(`InternShip/GetInternShipApplication/?internshipRequestId=${internshipRequestId}`);
 }
 async getStudentInternshipList(studentId) {
   // console.log("studentId",studentId)
    return await axiosRequest.get(`InternShip/GetStudentInternshipList/?studentId=${studentId}`);
 }
 async getStudentInternshipListForTeacher(teacherId,applicationStatusId) {    
    return await axiosRequest.get('InternShip/GetInternShipRequest?teacherId='+teacherId+'&applicationStatusId='+applicationStatusId);
 }
 async getStudentInternshipListForAdmin(applicationStatusId) {    
  return await axiosRequest.get(`InternShip/GetInternShipList/?applicationStatusId=${applicationStatusId}`);
}


async getAllInternshipList(applicationNumber) {    
  return await axiosRequest.get('InternShip/GetAllInternshipList?applicationNumber='+applicationNumber);
}


 async updateInterenshipStatus(verifyInternshipRequest) {
    console.log("service--", verifyInternshipRequest);     
   var data=null;     
    return await axiosRequest.put('InternShip/UpdateInterenshipStatus/', verifyInternshipRequest)
        .then(resp => this.data = resp.data)            
 }
 async updateInterenshipSatusForAdmin(printInternshipRequest) {
  console.log("service--", printInternshipRequest);     
 var data=null;     
  return await axiosRequest.put('InternShip/UpdateInterenshipSatusForAdmin/', printInternshipRequest)
      .then(resp => this.data = resp.data)            
}
 }

export default InternshipService;