import "./student-promotion-widget.scss";
import { Select, Button,Input, DatePicker,Form,notification,Spin } from "antd";
import { useForm } from "react-hook-form";
import CourseService from "../../services/courseService";
import React, { useState, useEffect } from 'react';
import BatchService from "../../services/batchService";
import SemesterService from "../../services/semesterService";
import CourseYearService from "../../services/courseYearService";
import StudentService from '../../services/studentService';
import * as moment from 'moment';
import PromotionModel from "./promotionModel";

const StudentPromotionWidget = () => {
   const [form] = Form.useForm();
  const { Option } = Select;
  const [courseList, setCourseList] = useState(0);
  const [batchList, setBatchList] = useState(0);
  const [fromCourseYearList , setfromCourseYearList] = useState(0);
  const [courseToYearList , setcourseToYearList] = useState(0);
  const [semesterFromList , setsemesterFromList] = useState(0);
  const [semesterToList , setsemesterToList] = useState(0);
  const [yearList , setyearList] = useState(0);
  const [loading , setloading] = useState(0);
  const [teacherId, setteacherId] = useState(0);
 
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    form.setFieldsValue({ dateOfBirth: date });
  };
  const courseService = new CourseService();
  const batchService = new BatchService();
  const semesterService = new SemesterService();
  const courseYearService = new CourseYearService();
  const studentService = new StudentService();
  const promotionModel = new PromotionModel();
 
  const populateCourse = async () => {
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let teacherId=items.referenceId;
   // console.log("teacherId",teacherId);
    setteacherId(teacherId);    
    const _courseList = await courseService.getCoursesForTeacher(teacherId);
    setCourseList(_courseList.data);
  } 
  const  populateBatchByCourse=async(courseId)=> {   
    const _batchList = await batchService.getBatchesByCourseForTeacher(courseId,teacherId);
    setBatchList(_batchList.data);
}
  const hanleCourseChange=(e)=>
  {  
    form.setFieldsValue({ courseId: e });  
     populateBatchByCourse(e);
     populateFromCourseYearByCourse(e,teacherId);
     populateToCourseYearByCourse(e);
  }
  const hanleBatchChange=(e)=>{
    form.setFieldsValue({ batchId: e });
  }
  const  populateFromCourseYearByCourse=async(courseId,teacherId)=> {
    const _fromCourseYearList = await courseYearService.getCourseYearForTeacher(courseId,teacherId);
    setfromCourseYearList(_fromCourseYearList.data);

  }
  const  populateToCourseYearByCourse=async(courseId)=> {
    const _courseYearList = await courseYearService.getCourseYear(courseId);
    setcourseToYearList(_courseYearList.data);

  }
  const populateFromSemesterByCourseYear=async(courseYearId,teacherId)=> {
    const _semesterList = await semesterService.getSemester(courseYearId);
    setsemesterToList(_semesterList.data);
   }
   const populateToSemesterByCourseYear=async(courseYearId)=> {
    const _semesterList = await semesterService.getSemester(courseYearId);
    setsemesterFromList(_semesterList.data);
   }

const handleFromCourseYearChange = (event) => {
  form.setFieldsValue({ fromCourseYearId: event });
  populateFromSemesterByCourseYear(event,teacherId);
  populateToSemesterByCourseYear(event);
}
const handleFromSemesterChange=(e)=>{
  form.setFieldsValue({ fromSemesterId: e });
}
const handleToSemesterChange=(e)=>{
  form.setFieldsValue({ toSemesterId: e });
}
const handleToCourseYearChange=(e)=>{
  form.setFieldsValue({ toCourseYearId: e });
}
const handleYearOfJoining=(e)=>
{
  form.setFieldsValue({ yearOfJoining: e });
}
const populateYearOfJoining=async()=> {
  const _yearList = await studentService.getYear();  
  setyearList(_yearList);       
}


  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const handleSubmit = (values) => {
    setloading(true);
   
    promotionModel.courseId = values.courseId;
    promotionModel.batchId = values.batchId;
    promotionModel.yearOfJoining = values.yearOfJoining;
   
    promotionModel.fromCourseYearId = values.fromCourseYearId;
    promotionModel.fromSemesterId = values.fromSemesterId;
    promotionModel.toCourseYearId = values.toCourseYearId;
    promotionModel.toSemesterId = values.toSemesterId;
    studentService.studentPromotion(promotionModel).then(val => {
           console.log("val",val);
        if (val > 0) {
           
            openNotificationWithIcon('success','Data saved success fully');
           // console.log(studentModel.studentID);
            handleInputFieldRest(); 
            setloading(false);
        }
        else if(val==-101)
        {
          openNotificationWithIcon('error','Record Already Existing');
          setloading(false);
        }
        else if(val==-102)
        {
          openNotificationWithIcon('error','Duplicate Email');
          setloading(false);
        }
        else if(val==-103)
        {
          openNotificationWithIcon('error','Duplicate Roll Number');
          setloading(false);
        }
        else{
          openNotificationWithIcon('error','Data saving failed');
          setloading(false);
        }
    });
    
 
}
 const handleInputFieldRest=()=>{  
  form.resetFields();
 }
  useEffect(() => {   
    populateCourse();
    populateYearOfJoining();
    setloading(false);
  },[]);
 
  return (
     
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Student Promotion</h2></div>
      <div className="content-block">
        <section className="content">
        <Spin tip="Loading" size="large" spinning={loading} delay={500}>
        <Form      
      form={form}
      name="frmStudentRegistration"      
      onFinish={handleSubmit}    
      autoComplete="off">  
      <div className="row gx-3 gy-4">
              
             
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseId"  rules={[{ required: true,message: 'Course is required' }]}> 
                <label className="form-label">Course<span className="l-mandatory">*</span></label>                
                <Select className="w-100"
                  showSearch
                  placeholder="Course"
                  size="large"                
                  optionFilterProp="children"
                  onChange={(e)=>hanleCourseChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {courseList&&courseList.map(course => {return <Option value={course.courseId} key={course.courseId}>{course.courseName}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="batchId"  rules={[{ required: true,message: 'Batch is required' }]}> 
                <label className="form-label">Batch<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Batch"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>hanleBatchChange(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                 {batchList&&batchList.map(batch => {return <Option value={batch.batchId} key={batch.batchId}>{batch.batchName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="yearOfJoining"  rules={[{ required: true,message: 'Year Of Joining is required'}]}>
                <label className="form-label">Year Of Joining<span className="l-mandatory">*</span></label>
                  <Select className="w-100"
                  showSearch
                  placeholder="Year Of Joining"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleYearOfJoining(e)}                   
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                
                  {yearList&&yearList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
             </div>
             <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="fromCourseYearId"  rules={[{ required: true,message: 'From course Year is required' }]}> 
                <label className="form-label">From Course Year<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="From Course Year"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleFromCourseYearChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                {fromCourseYearList&&fromCourseYearList.map(courseYear => {return <Option value={courseYear.courseYearId} key={courseYear.courseYearId}>{courseYear.years}</Option>})}
                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="fromSemesterId"  rules={[{ required: true,message: 'From Semester is required'}]}>
                <label className="form-label">Semester<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Semester"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleFromSemesterChange(e)}  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                 {semesterFromList&&semesterFromList.map(semester => {return <Option value={semester.semesterId} key={semester.semesterId}>{semester.semester}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="toCourseYearId"  rules={[{ required: true,message: 'To Course Year is required' }]}> 
                <label className="form-label">To Course Year<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Course Year"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleToCourseYearChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                {courseToYearList&&courseToYearList.map(courseYear => {return <Option value={courseYear.courseYearId} key={courseYear.courseYearId}>{courseYear.years}</Option>})}
                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="toSemesterId"  rules={[{ required: true,message: 'To Semester is required'}]}>
                <label className="form-label">Semester<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Semester"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleToSemesterChange(e)}  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                 {semesterToList&&semesterToList.map(semester => {return <Option value={semester.semesterId} key={semester.semesterId}>{semester.semester}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Cancel</Button>
                <Button type="primary" htmlType="submit" className="l-btn l-btn--primary">
                Promotion
        </Button>
              </div>
            </div>
        
         </Form>
         </Spin>
        </section>
      </div>
    </div>
    
  );
};
 
export default StudentPromotionWidget;
