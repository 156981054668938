import React from 'react';
import  axiosRequest  from './apiCalls';
//import axios from 'axios';


class StudentService {

    constructor() {       
        this.svaeUser = this.saveStudent.bind(this);
        this.getYear = this.getYear.bind(this);
        this.getStudentsList = this.getStudentsList.bind(this);    
        this.getStudentsDetails = this.getStudentsDetails.bind(this);  
        this.updateStudent=this.updateStudent.bind(this);  
        this.deleteStudent=this.deleteStudent.bind(this); 
    }
    
   async saveStudent(studentModel) {
       console.log("service--", studentModel);
       const id = 0;
       studentModel.studentID=0;
       
       return await axiosRequest.post('Student/Save/', studentModel)
           .then(resp => this.id = resp.data) 
       
    }
    async getYear() {
        return await axiosRequest.get('Student/GetYear').then(response => response.data)
    }
    
    async getStudentsDetails(studentId) {
      return await axiosRequest.get(`Student/GetStudentsDetails/?studentId=${studentId}`);
  }
    async getStudentsList(studentRequest) {
        // let params =JSON.stringify( {
        //     courseId: 1,
        //     batchId: 1,
        //     courseYearId:1,
        //     semesterId:1,
        //     yearOfJoining:2023,
        //     rollNumber:'101'
        //   });
        // let params = {
        //     "courseId": 1,
        //     "batchId": 1,
        //     "courseYearId":1,
        //     "semesterId":1,
        //     "yearOfJoining":2023,
        //     "rollNumber":""
        //       };
          console.log("params",studentRequest);
//           axios({
//   method: 'get',
//   url: 'https://localhost:7042/api/Student/GetStudentDetails/',
//  // data: params,
//   config: { headers: {
//       //'Accept': 'application/json',
//       'Content-Type': 'application/json' 
//     },body: params}
//   })
//     .then((response) => {console.log(response)}) 
//     .catch(error => {console.log( 'the error has occured: ' + error) })
// }

/*axios('https://localhost:44306/api/Employee',{
      method: 'POST',
      headers:{'Content-type':'application/json'},
        body: empInfo
    }).then(r=>r.json()).then(res=>{
      if(res){
        this.setState({message:'New Employee is Created Successfully'});
      }
    });
    */
    //     return await axios.get('https://localhost:7042/api/Student/GetStudentDetails/',params,{
    //     headers: {
    //         'Content-Type': 'application/json',
    //     }
    // }).then(response => response.data)
   // }

      // const response = axios.post('https://localhost:7042/api/Student/GetStudentDetails', body: params);
           /*.then(response => element.innerHTML = response.data.id);*/
       //return await axios.post(`https://localhost:7042/api/Student/SaveStudentDetails/?studnetRegistrationRequest=${studentModel}`);
        //registrationModel.userRole=1;
        //localStorage.setItem('Name', 'Rahul');
        //var s = localStorage.getItem('Name');
        //return s;
        // return this.data;
        // return this.userModel.
    /*}*/
    // const response = await fetch(
    //     'https://localhost:7042/api/Student/GetStudentDetails?studentDetailsRequest='+params
    //   )

    // return await axios.get('https://localhost:7042/api/Student/GetStudentDetails/', {
    //        // method: 'GET',
    //         headers: {
    //             'Accept': 'application/json, text/plain',
    //             'Content-Type': 'application/json;charset=UTF-8'
    //         },
    //       //  mode: 'no-cors',
    //         body: params,
    //     }).then(response => response.text())
    //         .then(data => console.log(data))    
    //         .catch(error => console.log("Error detected: " + error))

    return  axiosRequest({
        method: "post",
        url: 'Student/GetStudentsList',
        data:studentRequest,        
        config: {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      })
        .then((response) => {
          console.log(response.data);
          return response.data
        //   toast.success("Movie updated successfully", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        })
        .catch((error) => {
          console.log("the error has occured: " + error);
        });
}

async updateStudent(studentModel) {
  console.log("service--", studentModel);
  const result=0;
  //studentModel.studentID=0;
  
  return await axiosRequest.put('Student/UpdateStudent/', studentModel)
      .then(resp => this.result = resp.data) 
  
}
async deleteStudent(studentId) {
  console.log("service--", studentId);
  const result=false;
  return axiosRequest.patch('Student/DeactivateStudent?studentId='+studentId+'&isActive=false')
  .then((response) => {
     console.log("response",response.data);
     return response.data 
   })
    
}
    
async getStudentsDetaisForGroup(rollNumber,batchId) {
  console.log("rollNumber",rollNumber);
  console.log("batchId",batchId);
  return await axiosRequest.post('Student/GetStudentsDetaisForGroup?rollNumber='+rollNumber+'&batchId='+batchId).then((response) => {
    console.log("response",response.data);
    return response.data 
  })
}
async studentPromotion(promotionModel) {
  console.log("service--", promotionModel);
  const result=0;
  //studentModel.studentID=0;
  
  return await axiosRequest.put('Student/StudentPromotion/', promotionModel)
      .then(resp => this.result = resp.data) 
  
}
}


export default StudentService;