import "./student-profile-widget.scss";
import { Select, Button,Input, DatePicker,Form,notification,Spin } from "antd";
import { useForm } from "react-hook-form";
import CourseService from "../../services/courseService";
import React, { useState, useEffect } from 'react';
import BatchService from "../../services/batchService";
import SemesterService from "../../services/semesterService";
import CourseYearService from "../../services/courseYearService";
import StudentService from '../../services/studentService';
import * as moment from 'moment';
import { json, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import StudentModel from "./studentModel";

const StudentProfileWidget = () => {
   const [form] = Form.useForm();
  const { Option } = Select;
  const [courseList, setCourseList] = useState(0);
  const [batchList, setBatchList] = useState(0);
  const [courseYearList , setcourseYearList] = useState(0);
  const [semesterList , setsemesterList] = useState(0);
  const [yearList , setyearList] = useState(0);
  const [loading , setloading] = useState(0);
  const [viewStatus , setviewStatus] = useState(false);
  const navigate = useNavigate();
 
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setEditedValues("dateOfBirth",date);
    form.setFieldsValue({ dateOfBirth: date });
  };
  const courseService = new CourseService();
  const batchService = new BatchService();
  const semesterService = new SemesterService();
  const courseYearService = new CourseYearService();
  const studentService = new StudentService();
  const studentModel = new StudentModel(); 
  const [studentDetails, setStudentDetails] = useState(0);
  const [studentId, setStudentId] = useState(0);
  
  const populateStudentsDetails = async () => {
    //localStorage.setItem('userDetails', JSON.stringify(res))
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let studentId=items.referenceId;
    console.log("studentId",studentId);
    setStudentId(studentId);
    const _studentDetails = await studentService.getStudentsDetails(studentId);
   
   if(_studentDetails.data.registrationNumber=='NA')
   {
       _studentDetails.data.registrationNumber="";      
   }
   if(_studentDetails.data.courseId>0)
   {
      populateBatchByCourse(_studentDetails.data.courseId);
      populateCourseYearByCourse(_studentDetails.data.courseId);
   }
    if(_studentDetails.data.courseYearId>0)
    {
       populateSemesterByCourseYear(_studentDetails.data.courseYearId);       
    }
    else{
      _studentDetails.data.courseYearId="";
      _studentDetails.data.semesterId="";
    } 
    setStudentDetails(_studentDetails.data);
    setFormFieldsValue(_studentDetails.data);
   
  }
  const setFormFieldsValue=(_studentDetails)=>{
  //  console.log("studentDetails",_studentDetails.dateOfBirth )
   // const _studentDetails=JSON.parse(JSON.stringify(studentDetails));
    console.log("setFormFieldsValue",_studentDetails);
    if(_studentDetails.fullName!='')
    form.setFieldsValue({ fullName: _studentDetails.fullName });  
    if(_studentDetails.registrationNumber!='')
    form.setFieldsValue({ registrationNumber: _studentDetails.registrationNumber });  
    if(_studentDetails.rollNumber!='')
    form.setFieldsValue({ rollNumber: _studentDetails.rollNumber });  
    if(_studentDetails.dateOfBirth!='')
    form.setFieldsValue({ dateOfBirth: moment(_studentDetails.dateOfBirth, 'DD/MM/YYYY') });
    if(_studentDetails.courseId!='')
    form.setFieldsValue({ courseId: _studentDetails.courseId });
    if(_studentDetails.batchId!='')    
    form.setFieldsValue({ batchId: _studentDetails.batchId });
    if(_studentDetails.courseYearId!='')    
    form.setFieldsValue({ courseYearId: _studentDetails.courseYearId });
    if(_studentDetails.semesterId!='')   
    form.setFieldsValue({ semesterId: _studentDetails.semesterId });
    if(_studentDetails.yearOfJoining!='')   
    form.setFieldsValue({ yearOfJoining: _studentDetails.yearOfJoining });
    if(_studentDetails.gender!='')
    form.setFieldsValue({ gender: _studentDetails.gender });
    if(_studentDetails.email!='')
    form.setFieldsValue({ email: _studentDetails.email });
    if(_studentDetails.mobileNumber!='')
    form.setFieldsValue({ mobileNumber: _studentDetails.mobileNumber });
  }
  const populateCourse = async () => {
    const _courseList = await courseService.getCourse();
    setCourseList(_courseList.data);
  }
 
  const  populateBatchByCourse=async(courseId)=> {   
    const _batchList = await batchService.getBatches(courseId);
    setBatchList(_batchList.data);
}

  const handleCourseChange=(e)=>
  {   
   studentDetails.batchId="";  
   studentDetails.courseYearId=""; 
   studentDetails.semesterId="";
     
   form.setFieldsValue({ courseId: e });   
     populateBatchByCourse(e);
     populateCourseYearByCourse(e);
  }
  const handleBatchChange=(e)=>{  
    studentDetails.courseYearId=""; 
    studentDetails.semesterId="";
   
    form.setFieldsValue({ batchId: e });    
  }
  const  populateCourseYearByCourse=async(courseId)=> {
    const _courseYearList = await courseYearService.getCourseYear(courseId);
    setcourseYearList(_courseYearList.data);

  }
  const populateSemesterByCourseYear=async(courseYearId)=> {
    const _semesterList = await semesterService.getSemester(courseYearId);
    setsemesterList(_semesterList.data);
   }
const genderList = [        
  { genderName: "Male", genderId: "male" },
  { genderName: "Female", genderId: "female" }     
];
const setEditedValues = async (fieldName,value) => {
  const _studentDetails=JSON.parse(JSON.stringify(studentDetails));
  switch(fieldName) {
    case 'fullName':
      _studentDetails.fullName=value;
      break;   
    case 'registrationNumber':
          _studentDetails.registrationNumber=value;
          break;
    case 'rollNumber':
            _studentDetails.rollNumber=value;
            break; 
    case 'dateOfBirth':
              _studentDetails.dateOfBirth=value;
              break;           
    case 'courseId':
              _studentDetails.courseId=value;
              break;          
    case 'batchId':
          _studentDetails.batchId=value;
          break;
    case 'courseYearId':
            _studentDetails.courseYearId=value;
            break;          
    case 'semesterId':
            _studentDetails.semesterId=value;
            break;
    case 'yearOfJoining':
              _studentDetails.yearOfJoining=value;
              break;            
    case 'gender':
              _studentDetails.gender=value;
              break;              
    case 'email':
                _studentDetails.email=value;
                break;  
    case 'mobileNumber':
                  _studentDetails.mobileNumber=value;
                  break; 
     default:
         return '';

  }
  setStudentDetails(_studentDetails);
}
const handleCourseYearChange = (e) => {
  studentDetails.semesterId="";
  
  form.setFieldsValue({ courseYearId: e });
  populateSemesterByCourseYear(e);
}
const handleSemesterChange=(e)=>{
 
  form.setFieldsValue({ semesterId: e });
}
const handleYearOfJoining=(e)=>
{
  //setEditedValues("yearOfJoining",e);
  form.setFieldsValue({ yearOfJoining: e });
}
const populateYearOfJoining=async()=> {
  const _yearList = await studentService.getYear();  
  setyearList(_yearList);       
}
const handleregistrationNumberChange=(e)=>
{
  setEditedValues("registrationNumber",e.target.value);
  form.setFieldsValue({ registrationNumber: e.target.value });
}
const handleFullNameChange=(e)=>
  {   
    setEditedValues("fullName",e.target.value);
    form.setFieldsValue({ fullName: e.target.value });
  }
  const handleRollNumberChange=(e)=>
  {
  
    form.setFieldsValue({ rollNumber: e.target.value });
  }
  const handleGenderChange=(e)=>
  {
    setEditedValues("gender",e);
    //console.log(e);
    form.setFieldsValue({ gender: e });
  }
  const hanleEmailChange=(e)=>
  {
    setEditedValues("email",e.target.value);
    form.setFieldsValue({email: e.target.value });
  }
  const hanleMobileNumberChange=(e)=>
  {
    setEditedValues("mobileNumber",e.target.value);
    form.setFieldsValue({ mobileNumber: e.target.value });
  }
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const handleSubmit = (values) => {
    setloading(true);
    //console.log("values",values)
    let dob = new Date();
    dob = moment(values.dateOfBirth, 'DD-MM-YYYY')
   studentModel.studentID=studentId;
    studentModel.fullName=values.fullName;
    studentModel.yearOfJoining = values.year;
    studentModel.courseId = values.courseId;
    studentModel.batchId = values.batchId;
    studentModel.courseYearId = values.courseYearId;
    studentModel.semesterId = values.semesterId;
    studentModel.rollNumber = values.rollNumber;
    if(values.registrationNumber!== undefined)
    studentModel.registrationNumber = values.registrationNumber;
    else
    studentModel.registrationNumber = "";
    studentModel.semesterId = values.semesterId;
    studentModel.dateOfBirth = dob;
    studentModel.email = values.email;
    studentModel.gender=values.gender;
    studentModel.mobileNumber = values.mobileNumber;
    studentModel.yearOfJoining=values.yearOfJoining;
    studentService.updateStudent(studentModel).then(val => {
        if (val > 0) {           
            openNotificationWithIcon('success','Data updated success fully');
            handleInputFieldRest(); 
            setloading(false);
           // navigate("/StudentList");
        }
        else if(val==-101)
        {
          openNotificationWithIcon('error','Record Already Existing');
          //setloading(false);
        }
        else if(val==-102)
        {
          openNotificationWithIcon('error','Duplicate Email');
         // setloading(false);
        }
        else if(val==-103)
        {
          openNotificationWithIcon('error','Duplicate Roll Number');
         // setloading(false);
        }
        else{
          openNotificationWithIcon('error','Data updation failed');
          //setloading(false);
        }
       
    });
    setloading(false);
}
const handleCancel=()=>
{
  navigate("/Studentlist");
}
 const handleInputFieldRest=()=>{  
  form.resetFields();
 }
  useEffect(() => {  
    setloading(true); 
    populateStudentsDetails();
   
    populateCourse();    
    populateYearOfJoining();
    setloading(false); 
    //populateStudentsDetails();
    //HomeScreen();
  },[]);
 
  return (
     
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Student Details</h2></div>
      <div className="content-block">
        <section className="content">
        <Spin tip="Loading" size="large" spinning={loading} delay={500}>
        <Form      
      form={form}
      name="frmStudentDetails"      
      onFinish={handleSubmit}    
      autoComplete="off">  
      <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="fullName"  rules={[{ required: true,message: 'Full Name is required' }]}>
                <label className="form-label">Full Name<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Full Name" 
                    onChange={(e)=>handleFullNameChange(e)} id="fullName" value={studentDetails.fullName||null}
                 />                
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="registrationNumber">
                <label className="form-label">Registration Number</label>
                
                <Input type="text" className='form-control' value={studentDetails.registrationNumber}
                onChange={(e)=>handleregistrationNumberChange(e)}
                placeholder="Registration Number" />
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="rollNumber"  rules={[{ required: true,message: 'Roll Number is required' }]}>
                <label className="form-label">Roll Number<span className="l-mandatory">*</span></label>
                <Input type="number" className='form-control' placeholder="Roll Number" 
                value={studentDetails.rollNumber} disabled={true}
                onChange={(e)=>handleRollNumberChange(e)}
                 />                 
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="dateOfBirth" rules={[{ required: true,message: 'Date of Birth is required'  }]}>
                <label className="form-label">Date of Birth<span className="l-mandatory">*</span></label>
                <DatePicker onChange={onChange} format={"DD/MM/YYYY"} 
                     defaultValue={moment((studentDetails.dateOfBirth))}
                    disabledDate={(current) => current.isAfter(moment().subtract(16,"year"))}/>   
                </Form.Item>            
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseId"  rules={[{ required: true,message: 'Course is required' }]}> 
                <label className="form-label">Course<span className="l-mandatory">*</span></label>                
                <Select className="w-100"  value={studentDetails.courseId}
                disabled={true}
                //value={studentDetails.courseName} 
                  showSearch
                  placeholder="Course" 
                  size="large"                
                  optionFilterProp="children"
                  onChange={(e)=>handleCourseChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {courseList&&courseList.map(course => {return <Option value={course.courseId} key={course.courseId}>{course.courseName}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="batchId"  rules={[{ required: true,message: 'Batch is required' }]}> 
                <label className="form-label">Batch<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={studentDetails.batchId}
                  showSearch  disabled={true}
                  placeholder="Batch"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleBatchChange(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                 {batchList&&batchList.map(batch => {return <Option value={batch.batchId} key={batch.batchId}>{batch.batchName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseYearId"  rules={[{ required: true,message: 'Course Year is required' }]}> 
                <label className="form-label">Course Year<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={studentDetails.courseYearId}
                  showSearch disabled={true}
                  placeholder="Course Year"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleCourseYearChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                {courseYearList&&courseYearList.map(courseYear => {return <Option value={courseYear.courseYearId} key={courseYear.courseYearId}>{courseYear.years}</Option>})}
                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="semesterId"  rules={[{ required: true,message: 'Semester is required'}]}>
                <label className="form-label">Semester<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={studentDetails.semesterId}
                  showSearch disabled={true}
                  placeholder="Semester"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleSemesterChange(e)}  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                 {semesterList&&semesterList.map(semester => {return <Option value={semester.semesterId} key={semester.semesterId}>{semester.semester}</Option>})}
                </Select>
                </Form.Item>
              </div>

              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="yearOfJoining"  rules={[{ required: true,message: 'Year Of Joining is required'}]}>
                <label className="form-label">Year Of Joining<span className="l-mandatory">*</span></label>
                  <Select className="w-100" value={studentDetails.yearOfJoining}
                  showSearch disabled={true}
                  placeholder="Year Of Joining"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleYearOfJoining(e)}                   
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                
                  {yearList&&yearList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="gender"  rules={[{ required: true,message: 'Gender is required'}]}>
                <label className="form-label">Gender<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={studentDetails.gender}
                  showSearch
                  placeholder="Gender"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleGenderChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {genderList&&genderList.map(gender => {return <Option value={gender.genderId} key={gender.genderId}>{gender.genderName}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              
                <Form.Item
    name="email"
    
    rules={[
      { required: true, message: 'E-mail is required' },
      {
        type: 'email',
        message: 'The input is not valid E-mail!',
      },
    ]}
  >
    <label className="form-label">E-mail<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="E-mail" value={studentDetails.email}
                onChange={(e)=>hanleEmailChange(e)}    />  
  </Form.Item> 
              </div>
              <div className="form-group col-md-6 col-lg-3">
               <Form.Item name="mobileNumber"  rules={[{ required: true,message: 'Mobile Number is required' }]}>
                <label className="form-label">Mobile Number<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" value={studentDetails.mobileNumber}
                onChange={(e)=>hanleMobileNumberChange(e)}    
                />
               </Form.Item>   
                
              </div>


              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit" className="l-btn l-btn--primary">
                Update
        </Button>
              </div>
            </div>
        
         </Form>
         </Spin>
        </section>
      </div>
    </div>
    
  );
};
 
export default StudentProfileWidget;
