import React from 'react';
import  axiosRequest  from './apiCalls';


class DesignationService {

    constructor() {       
        
        this.getDesignation = this.getDesignation.bind(this);      
    }
    async getDesignation() {
       return await axiosRequest.get(`Designation/GetDesignationes`);
    }
   
}

export default DesignationService;