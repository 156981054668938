import "./reset-password-widget.scss";
import { Select, Button,Input, Form,notification,Spin } from "antd";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from 'react';
import ResetPasswordModel from "./resetPasswordModel";
import UserLoginService from '../../services/userLoginService';
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';


const ResetPasswordWidget = () => {
  const [form] = Form.useForm();
  const { Option } = Select; 
  const [loading , setloading] = useState();
  const navigate = useNavigate();

  const  resetPasswordModel=new ResetPasswordModel();
  const userLoginService = new UserLoginService();

  const handleInputFieldRest=()=>{  
    form.resetFields();
   }
   const handleCurrentPasswordChange=(e)=>
  {  
       form.setFieldsValue({ currentPassword: e.target.value });
  }
  const handleNewPasswordChange=(e)=>
  {  
       form.setFieldsValue({ newPassword: e.target.value });
  }
  const handleConfirmNewPasswordChange=(e)=>
  {  
       form.setFieldsValue({ confirmNewPassword: e.target.value });
  }
  
  useEffect(() => {   
    setloading(false);
  },[]);
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const handleSubmit = (values) => {
    setloading(true);
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let referenceId=items.referenceId;
    let roleId=items.roleId;
    resetPasswordModel.newPassword=values.newPassword;
    resetPasswordModel.currentPassword=values.currentPassword;
    resetPasswordModel.referenceId=referenceId;
    resetPasswordModel.roleId=roleId;
    userLoginService.updatePassword(resetPasswordModel).then(val => {
      if (val > 0) {           
          openNotificationWithIcon('success','Data updated success fully');
          handleInputFieldRest(); 
          window.localStorage.clear();
          setloading(false);          
          navigate("/login");
          window.location.reload();
      }
      else if(val==-100)
      {
        openNotificationWithIcon('error','Current password is wrong');
        setloading(false);
      }
    });
    //setloading(false);

  }

  return (
    <div className="resetpassword">
      <div className="l-title-block"><h2>Student Details</h2></div>
      <div className="content-block">
      <section className="content">
        <Spin tip="Loading" size="large" spinning={loading} delay={500}>
        <Form      
      form={form}
      name="frmStudentRegistration"      
      onFinish={handleSubmit}    
      autoComplete="off">  
      <div className="row gx-3 gy-4">
      <div className="form-group col-md-6 col-lg-3">
      <Form.Item name="currentPassword"  rules={[{ required: true,message: 'Current Password is required' }]}> 
                <label className="form-label">Current Password<span className="l-mandatory">*</span></label>                
                {/* <Input type="text" className='form-control' placeholder="Current Password"
                    onChange={(e)=>handleCurrentPasswordChange(e)}
                 /> */}
                   <Input.Password 
        placeholder="Current Password" onChange={(e)=>handleCurrentPasswordChange(e)}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
                </Form.Item>
             </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="newPassword"  rules={[{ required: true,message: 'New Password is required' }]}> 
                <label className="form-label">New Password<span className="l-mandatory">*</span></label>
                {/* <Input type="text" className='form-control' placeholder="New Password"
                    onChange={(e)=>handleNewPasswordChange(e)}
                 /> */}
                   <Input.Password 
        placeholder="New Password" onChange={(e)=>handleNewPasswordChange(e)}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="confirmNewPassword"  rules={[{ required: true,message: 'Confirm New Password is required' }]}> 
                <label className="form-label">Confirm New Password<span className="l-mandatory">*</span></label>
                {/* <Input type="text" className='form-control' placeholder="Confirm New Password"
                    onChange={(e)=>handleConfirmNewPasswordChange(e)}
                 /> */}
                 <Input.Password 
                    placeholder="Confirm New Password" onChange={(e)=>handleConfirmNewPasswordChange(e)}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </div>
              
             
      </div>
      
      {/* </div> */}
      <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Cancel</Button>
                <Button type="primary" htmlType="submit" className="l-btn l-btn--primary">
                Change Password
        </Button>
              </div>
              </Form>
              </Spin>
              </section>
             
              </div>
    </div>
  );
};

export default ResetPasswordWidget;
