
export default class InternShipModel {    
     internShipRequestId;
     organizationName;
     organizationAddress;
     organizationContactNumber;
     organizationEmail;
     contactPersonName;
     contactNumber;
     designationName;
     contactEmail;
     internshipType;
     startDate;
     endDate;
     applicationStatusId;
     applicationSubmittedBy;

    constructor(internShipRequestId,organizationName,organizationAddress,organizationContactNumber,
        organizationEmail,contactPersonName, contactNumber, designationName,contactEmail, 
        internshipType, startDate, endDate,applicationStatusId,applicationSubmittedBy) {
        this.internShipRequestId = internShipRequestId;
        this.organizationName = organizationName;
        this.organizationAddress = organizationAddress;
        this.organizationContactNumber = organizationContactNumber;
        this.organizationEmail = organizationEmail;
        this.contactPersonName = contactPersonName;
        this.contactNumber = contactNumber;        
        this.designationName = designationName;
        this.contactEmail = contactEmail;
        this.internshipType = internshipType;       
        this.startDate = startDate;      
        this.endDate = endDate;
        this.applicationStatusId=applicationStatusId;
        this.applicationSubmittedBy=applicationSubmittedBy;
    }
  
}

