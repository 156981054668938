import React from 'react';
import  axiosRequest  from './apiCalls';
// import axios from 'axios';

class BatchService {
    constructor() {        
        this.getBatch = this.getBatches.bind(this); 
        this.getBatchesByCourseForTeacher = this.getBatchesByCourseForTeacher.bind(this);        
    }
    async getBatches(courseId) {
        return await axiosRequest.get(`Batch/GetBatches/?courseId=${courseId}`);
    } 
    async getBatchesByCourseForTeacher(courseId,teacherId) {
        return await axiosRequest.get('Batch/GetBatchesByCourseForTeacher?courseId='+courseId+'&teacherId='+teacherId);
    } 
       
}
export default BatchService;