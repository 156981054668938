import "./teacher-list-widget.scss";
import { Table,Input,Form,Button,Modal,Spin,notification } from 'antd';
import { useForm } from "react-hook-form";

import React, { useState, useEffect } from 'react';

import TeacherService from '../../services/teacherService';
import { useNavigate } from "react-router-dom";
//import {  useParams } from "react-router-dom";


const TeacherListWidget = () => {
  const [form] = Form.useForm();

  const [teacherList , setteacherList] = useState(0);
  const [loading , setloading] = useState(false);
  const teacherService = new TeacherService();
  const [searchData , setsearchData] = useState(0);
  
  const navigate = useNavigate();
  //const { status } = useParams();
  const handleFullNameChange=(e)=>
  {   
    form.setFieldsValue({ fullName: e.target.value });   
  }
  const handleMobileNumberChange=(e)=>
  {   
    form.setFieldsValue({ mobileNumber: e.target.value });   
  }
  useEffect(() => {     
      populateDataFromLocalStorage();   
  },[]);
  const onClickViewTeacher=(teacherId)=>{
    console.log("view",teacherId);
    if(teacherId!=undefined)
        navigate("/teacherDetails/"+teacherId);
  }
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  const populateDataFromLocalStorage = () => {
    const _searchData = JSON.parse(localStorage.getItem('teacherSearchData'));    
    if(_searchData!==null)
    {     
      setsearchData(_searchData);
      setFormFieldsValue(_searchData);       
       let btnSearchTeacher= document.getElementById("btnSearchTeacher");       
        if(btnSearchTeacher)
        {
          btnSearchTeacher.click();
        }
    }
   
  };
  const setFormFieldsValue=(_searchData)=>{
    
     if(_searchData!==null)
     {     
      console.log("setFormFieldsValue",_searchData);
      if(_searchData.fullName!='')
      form.setFieldsValue({ fullName: _searchData.fullName }); 
      if(_searchData.mobileNumber!='')
      form.setFieldsValue({ mobileNumber: _searchData.mobileNumber });
     
     }
      
    }
  const handleInputFieldRest=()=>{  
    const _searchData = JSON.parse(localStorage.getItem('teacherSearchData'));
    
    if(_searchData!==null)
    {
        localStorage.removeItem('teacherSearchData');
        _searchData.fullName=null;
        _searchData.mobileNumber=null;       
         setsearchData(_searchData);
         setteacherList(null);
    }
    form.resetFields();
   }
   const onDeleteTeacher = (teacherId) => {
    console.log("record",teacherId);
    Modal.confirm({
      title: "Are you sure, you want to delete this teacher record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setloading(true);
        teacherService.deactivateTeacher(teacherId).then(res => {
       if(res==true)
       {
        openNotificationWithIcon('success','Data deleted success fully');      

          let btnSearchTeacher= document.getElementById("btnSearchTeacher");       
          if(btnSearchTeacher)
          {
            btnSearchTeacher.click();
          }
      }
      else
      {
        openNotificationWithIcon('error','Data deletion failed');     
      }
        });   
        setloading(false);    
      },
    });
  };
   const populateTeachersList=async(values)=> {
    setloading(true);
    localStorage.removeItem('teacherSearchData');   
    if(values.mobileNumber!=undefined || values.mobileNumber!='')
    {
      values.mobileNumber =""
    }
    let teacherRequest = {
      "fullName": values.fullName,     
      "mobileNumber": values.mobileNumber      
      };
      localStorage.setItem('teacherSearchData', JSON.stringify(teacherRequest)); 
    const _teacherList = await teacherService.getTeacherList(teacherRequest);//.then(data => {console.log("data",data)});
    //console.log("_studentList",_studentList)
    setteacherList(_teacherList);    
    setloading(false);   
  }
    
  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',      
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName)     
    },
    {
      title: 'Designation Name',
      dataIndex: 'designationName',
      key: 'designationName'
      
    },
   
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      key: 'jobType',
      
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
   
    {
      title: 'Alternative MobileNumber',
      dataIndex: 'alternativeMobileNumber',
      key: 'alternativeMobileNumber',
    },
    {
      title: '',
      dataIndex: 'teacherID',      
      key: 'teacherID',
      render: (teacherID) => <a className="l-link" onClick={()=>onClickViewTeacher(teacherID)}>View</a>
    },
    {
      title: '',
      dataIndex: 'teacherID',      
      key: 'teacherID',
      render: (teacherID) => <a className="l-link" onClick={()=>onDeleteTeacher(teacherID)}>Delete</a>
    }
  ];
   
  return (
    <div className="home">
      <div className="l-title-block"><h2>Teacher List</h2></div>
      <div className="content-block"> 
      <Spin tip="Loading" size="large" spinning={loading} delay={500}>     
      <Form      
      form={form}
      name="frmTeacherList"      
      onFinish={populateTeachersList}
      autoComplete="off">  
      <div className="row gx-3 gy-4">
      <div className="form-group col-md-6 col-lg-3">   
      <Form.Item name="fullName"  rules={[{ required: true,message: 'Full Name is required' }]}> 
                <label className="form-label">Full Name<span className="l-mandatory">*</span></label>                
                <Input type="text" className='form-control' placeholder="Full Name" 
                onChange={(e)=>handleFullNameChange(e)} value={searchData.fullName}
                 />                 
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="mobileNumber"> 
                <label className="form-label">Mobile Number<span className="l-mandatory"></span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" 
                 onChange={(e)=>handleMobileNumberChange(e)}    value={searchData.mobileNumber}            
                 />   
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">              
              </div>              
             
              </div>
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Clear</Button>
                <Button type="button" htmlType="submit" className="l-btn l-btn--primary" id="btnSearchTeacher">
                Search
        </Button>

              </div>
              </Form>
       
       {teacherList && teacherList.length?  ( 
        <section className="content">       
          <Table dataSource={teacherList} columns={columns} rowKey="teacherID"/>       
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
         </Spin>
      </div>
    </div>
  );
};

export default TeacherListWidget;
