import "./user-login-widget.scss";
import { Button,Input, Form,notification } from "antd";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from 'react';
 import AuthService from '../../services/authService';
 import UserLoginModel from "./userloginModel";
 import { useNavigate } from "react-router-dom";
 import logo from "assets/images/logo.png";
import loginImg from "assets/images/temp/login-bg.jpeg";

const UserLoginWidget = () => {
 
  const [form] = Form.useForm();
  const authService = new AuthService();
  const userLoginModel = new UserLoginModel();
  const navigate = useNavigate();
  const [invalidUser , setinvalidUser] = useState(false);
const handleUserNameChange=(e)=>
  { 
       form.setFieldsValue({ userName: e.target.value });
  }
 const handlePasswordChange=(e)=>
  {   
    form.setFieldsValue({ password: e.target.value });
  }
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  const handleSubmit = (values) => {
    userLoginModel.userName=values.userName;
    userLoginModel.password=values.password;
    //userLoginModel.password=values.password;
    console.log("userLoginModel",userLoginModel);
    authService.getUserLogin(userLoginModel).then(res => {   
      console.log("res",res);
      if(res.userLoginId>0)
      {
          if(res.roleId>0)
          {          
              localStorage.setItem('userDetails', JSON.stringify(res));
              if(res.roleId==2)
              navigate("/studentHome"); 
              else if(res.roleId==1)
              navigate("/teacherHome"); 
              else if(res.roleId==3)
              navigate("/adminHome"); 
              else if(res.roleId==4)
              navigate("/superAdminHome"); 
              window.location.reload();
              setinvalidUser(false);
          }
      }
        else{
          console.log("invalid");
           setinvalidUser(true);
        }
        // if(res.roleId!=null || res.roleId!='')
        // {
        //     localStorage.setItem('userDetails', JSON.stringify(res));
        //     if( res.roleId==2)
        //     navigate("/studentHome"); 
        //     else if( res.roleId==1)
        //     navigate("/teacherHome"); 
        //     else if( res.roleId==3)
        //     navigate("/adminHome"); 
        //     window.location.reload();
        // }
        // else{
        //   console.log("invalid");
        //    setinvalidUser(true);
        // }
  });
}
 const handleInputFieldRest=()=>{  
  form.resetFields();
 }
  useEffect(() => {  
    const _userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if(_userDetails) 
    {
      localStorage.removeItem('userDetails');
      localStorage.clear();  
      window.location.reload();
    }
    
  },[]);
 
  return (

    <div className="login">
    <div className="login__left">
      <img src={loginImg} alt="login" width="860" height="850" />
    </div>
    <div className="login__right">
      <div className="login__block">
        <div className="login__logo">
          <img src={logo} alt="iport" width="300" />
        </div>
        <div className="login__form">
            <Form   form={form}   name="frmStudentRegistration" onFinish={handleSubmit} autoComplete="off" >     
            <div className="form-group">
                    {invalidUser?
                <label className="lblMessage">
                You have entered an invalid username or password
                </label>
                :""}
              </div>  
          <div className="form-group">
          <Form.Item name="userName"  rules={[{ required: true,message: 'user name is required' }]}>
             
                <Input type="text" className='form-control' placeholder="User name"
                     onChange={(e)=>handleUserNameChange(e)}
                  />                
                 </Form.Item>
           
          </div>
          <div className="form-group">
          <Form.Item name="password"  rules={[{ required: true,message: 'Password is required'  }]}>
    
                <Input type="password" className='form-control' placeholder="Password" 
                 onChange={(e)=>handlePasswordChange(e)}    
                 />
                 </Form.Item>  
            {/* <input className="form-control" type="password" placeholder="Password"/> */}
          </div>
          <div className="login__actions">
          <Button htmlType="submit" className="l-btn l-btn--primary" >Login</Button>
            {/* <button className="l-btn l-btn--primary"  htmlType="Submit">Login</button> */}
            {/* <a href="#0" className="login__forget">Forget Password ?</a> */}
          </div>
          </Form>   
        </div>
      </div>
    </div>
  </div>
     
    // <div className="upload-widget">
    //   <div className="l-title-block"><h2 className="mb-0">User Login</h2></div>
    //   <div className="content-block">

    //     <section className="content">
         
    //     <Form      
    //   form={form}
    //   name="frmStudentRegistration"      
    //   onFinish={handleSubmit}    
    //   autoComplete="off"  
    // >    
    //         <div className="row gx-3 gy-4">
    //         {invalidUser?
    //     <label className="l-mandatory">
    //     You have entered an invalid username or password
    //     </label>
    //     :""}
    //           <div className="form-group col-md-6 col-lg-6">
    //           <Form.Item name="userName"  rules={[{ required: true,message: 'Full Name is required' }]}>
    //             <label className="form-label">User Name<span className="l-mandatory">*</span></label>
    //             <Input type="text" className='form-control' placeholder="User Name"
    //                 onChange={(e)=>handleUserNameChange(e)}
    //              />                
    //             </Form.Item>
    //           </div>
    //           </div>
    //           <div className="row gx-3 gy-4">
    //           <div className="form-group col-md-6 col-lg-6">
    //           <Form.Item name="password"  rules={[{ required: true,message: 'Date of Birth is required'  }]}>
    //             <label className="form-label">Password<span className="l-mandatory">*</span></label>
    //             <Input type="password" className='form-control' placeholder="Password" 
    //             onChange={(e)=>handlePasswordChange(e)}    
    //             />
    //             </Form.Item>            
    //           </div>
             
               
             
    //           <div className="form-group col-md-6 col-lg-6"></div>
    //           <div className="l-btn-wrapper mt-2">
    //             <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Clear</Button>
    //             <Button type="primary" htmlType="submit" className="l-btn l-btn--primary">
    //             Login
    //     </Button>
    //           </div>
    //         </div>
        
    //      </Form>
    //     </section>
    //   </div>
    // </div>
    
  );
};
 
export default UserLoginWidget;
