import React from 'react';
// import axios from 'axios';
import  axiosRequest  from './apiCalls';


class CourseService {

    constructor() {       
        
        this.getCourse = this.getCourse.bind(this);  
        this.getCoursesForTeacher = this.getCoursesForTeacher.bind(this);      
    }
    async getCourse() {
       return await axiosRequest.get(`Course/GetCourse`);
    }
    async getCoursesForTeacher(teacherId) {
        //console.log("service teacherId",teacherId);
        return await axiosRequest.get(`Course/GetCoursesForTeacher/?teacherId=${teacherId}`);
     }
    
}

export default CourseService;