import React from 'react';
// import axios from 'axios';
import  axiosRequest  from './apiCalls';


class QualificationService {

    constructor() {       
        
        this.getQualification = this.getQualification.bind(this);      
    }
    async getQualification() {
       return await axiosRequest.get(`Qualification/GetQualificationes`);
    }
   
}

export default QualificationService;