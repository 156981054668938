
export default class TeacherModel {    
     teacherID;
     fullName;
     dateOfBirth;
     qualificationIds;
     jobType;
     designationId;
     yearOfJoining;
     gender;
     email;
     mobileNumber;
     alternativeMobileNumber;
     address;
   

    constructor(teacherID,fullName,dateOfBirth,qualificationIds,jobType,designationId, yearOfJoining, gender,email, 
        mobileNumber, alternativeMobileNumber, address) {
        this.teacherID = teacherID;
        this.fullName = fullName;
        this.dateOfBirth = dateOfBirth;
        this.qualificationIds = qualificationIds;
        this.jobType = jobType;
        this.designationId = designationId;
        this.yearOfJoining = yearOfJoining;        
        this.gender = gender;
        this.email = email;
        this.mobileNumber = mobileNumber;       
        this.alternativeMobileNumber = alternativeMobileNumber;      
        this.address = address;
      
    }
  
}

