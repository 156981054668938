import "./TeacherSidenav.scss";
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import SvgIcon from "../svg-icon/SvgIcon";

import { useLocation } from 'react-router-dom'


const TeacherSidenav = () => {
  const SidebarData = [
    {
      title: "Home",
      path: "/teacherHome",
      icon: <SvgIcon icon="dashboard" width="20" height="20" />,
    },  
    {
      title: "Student Registration",
      path: "/StudentRegistration",
      icon: <SvgIcon icon="student" width="20" height="28" />,
    },
    {
      title: "Students List",
      path: "/studentList",
      icon: <SvgIcon icon="students-list" width="20" height="20" />,
    },
    {
      title: "Upload Student Details",
      path: "/studentUpload",
      icon: <SvgIcon icon="upload" width="20" height="20" />,
    },
    {
      title: "Upload Student List",
      path: "/studentUploadList",
      icon: <SvgIcon icon="list-svgrepo" width="20" height="20" />,
    },
    {
      title: "Promotion",
      path: "/promotion",
      icon: <SvgIcon icon="promotion1" width="20" height="20" />,
    },
    {
      title: "View Profie",
      path: "/teacherProfile",
      icon: <SvgIcon icon="view-profile" width="20" height="20" />,
    },
    {
      title: "Reset Password",
      path: "/resetpassword",
      icon: <SvgIcon icon="rest-password" width="20" height="20" />,
    },
   
  ];
  const menuItemClick=(path)=>{ 
    if(path=="/studentList")
    {
      console.log("path",path);
      localStorage.removeItem('searchData');
      this.forceUpdate();  
    }
    if(path=="/studentList")
    {
      console.log("path",path);
      localStorage.removeItem('searchData');
      this.forceUpdate();  
    }
    if(path=="/studentUploadList")
    {
      console.log("path",path);
      localStorage.removeItem('uploadData');
      this.forceUpdate();  
    }
    
   }
 
  const location = useLocation();
  
  return (
    <aside className="sidenav">
      <Menu
        selectedKeys={[location.pathname]}
        mode="inline"
        inlineIndent="20"
      >
        {SidebarData.map((item) => {
          const menuItems =  (
            <Menu.Item key={item.path} icon={item.icon}>
              <Link to={item.path} onClick={()=>menuItemClick(item.path)}>{item.title}</Link>
            </Menu.Item>
          );
          return menuItems;
        })}
      </Menu>

    </aside>
  );
};

export default TeacherSidenav;


