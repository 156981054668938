
export default class ResetPasswordModel {
    currentPassword;
    newPassword;
    referenceId;   
    roleId;
   
    constructor(currentPassword, newPassword, referenceId, roleId) {
        this.currentPassword = currentPassword;
        this.newPassword = newPassword;
        this.studentId = referenceId;
        this.roleId = roleId;        
    }
   
}

