import React from 'react';
import  axiosRequest  from './apiCalls';
//import axios from 'axios';


class CourseYearService {

    constructor() {
        
        this.getCourseYear = this.getCourseYear.bind(this);
        this.getCourseYearForTeacher = this.getCourseYearForTeacher.bind(this);
        
    }
    async getCourseYear(courseId) {
        return await axiosRequest.get(`CourseYear/GetCourseYears/?courseId=${courseId}`);
    }

    async getCourseYearForTeacher(courseId,teacherId) {
        return await axiosRequest.get('CourseYear/GetCourseYearForTeacher?courseId='+courseId+'&teacherId='+teacherId);
    }
    
}

export default CourseYearService;