import "./teacher-registration-widget.scss";
import { Select, Button,Input, DatePicker,Form,notification,TextArea,Spin } from "antd";
import { useForm } from "react-hook-form";
import DesignationService from "../../services/designationService";
import QualificationService from "../../services/qualificationService";
import React, { useState, useEffect } from 'react';
import TeacherService from '../../services/teacherService';

import * as moment from 'moment';
import TeacherModel from "./teacherModel";

const TeacherRegistrationWidget = () => {
 
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { Option } = Select;

  const [designationList, setDesignationList] = useState(0);
  const [qualificationList, setQualificationList] = useState(0);
  const [yearList , setyearList] = useState(0);
  const [loading , setloading] = useState();
  
  const onChange = (date, dateString) => {   
    form.setFieldsValue({ dateOfBirth: date });
  };
  const designationService = new DesignationService();
  const qualificationService = new QualificationService();  
  const teacherService = new TeacherService();
  const teacherModel = new TeacherModel();
  
  const populateDesignation = async () => {
    const _designationList = await designationService.getDesignation();
    setDesignationList(_designationList.data);
  } 
  const  populateQualification=async()=> {   
    const _qualificationList = await qualificationService.getQualification();
    setQualificationList(_qualificationList.data);
}
  const hanleQualificationChange=(e)=>
  {  
    //console.log("qualification:", e);
    form.setFieldsValue({ qualificationIds: e});      
  }
 
  const hanleDesignationChange=(e)=>{
    form.setFieldsValue({ designationId: e });
  }  
const genderList = [        
  { genderName: "Male", genderId: "male" },
  { genderName: "Female", genderId: "female" }     
];
const jobTypeList = [        
  { jobTypeName: "Contratct", jobTypeId: "Contract" },
  { jobTypeName: "Permanent", jobTypeId: "Permanent" },
  { jobTypeName: "Parttime", jobTypeId: "Parttime" }      
];

const handleYearOfJoining=(e)=>
{
  //console.log(e);
  form.setFieldsValue({ yearOfJoining: e });
}
const populateYearOfJoining=async()=> {
  const _yearList = await teacherService.getYear();  
  setyearList(_yearList);       
}
const handleFullNameChange=(e)=>
  { 
       form.setFieldsValue({ fullName: e.target.value });
  }
  const handleJobTypeChange=(e)=>
  {   
    form.setFieldsValue({jobType : e });
  }
  const handleGenderChange=(e)=>
  {   
    form.setFieldsValue({ gender: e });
  }
  const handleEmailChange=(e)=>
  {
    form.setFieldsValue({email: e.target.value });
  }
  const handleAlternativeMobileNumberChange=(e)=>{
    form.setFieldsValue({ alternativeMobileNumber: e.target.value });
  }
  const hanleMobileNumberChange=(e)=>
  {   
    form.setFieldsValue({ mobileNumber: e.target.value });
  }
  const handleAddressChange=(e)=>
  {   
    form.setFieldsValue({ address: e.target.value });
  }
  
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const handleSubmit = (values) => {

    setloading(true);     
    let dob = new Date();
    dob = moment(values.dateOfBirth, 'DD-MM-YYYY')
    teacherModel.fullName=values.fullName;
    teacherModel.dateOfBirth = dob;
    teacherModel.qualificationIds = values.qualificationIds.toString();
    teacherModel.jobType = values.jobType;
    teacherModel.designationId = values.designationId;
    teacherModel.yearOfJoining = values.yearOfJoining.toString();        
    teacherModel.gender = values.gender;
    teacherModel.email = values.email;
    teacherModel.mobileNumber = values.mobileNumber; 
    if(values.alternativeMobileNumber!== undefined)
    teacherModel.alternativeMobileNumber = values.alternativeMobileNumber;
    else
    teacherModel.alternativeMobileNumber = "";      
   
    if(values.address!== undefined)
    teacherModel.address = values.address;
    else
    teacherModel.address = "";    
   // console.log("teacherModel",teacherModel);
    teacherService.saveTeacher(teacherModel).then(val => {
      console.log("status",val.status);
        if (val.status==1) {           
            openNotificationWithIcon('success','Data saved success fully');
            console.log(teacherModel.teacherID);
            handleInputFieldRest(); 
        }
      else  if (val.status==-101) { 
        openNotificationWithIcon('warning','Duplicate Mobile Number');  
        }
        else  if (val.status==-102) {  
          openNotificationWithIcon('warning','Duplicate Email');   
        }
        else  if (val.status==-103) {
          openNotificationWithIcon('warning','Duplicate Record');      
        }
        else{
          openNotificationWithIcon('error','Data saving failed');
        }
    });
   setloading(false);
 
}
 const handleInputFieldRest=()=>{  
  form.resetFields();
 }
  useEffect(() => {   
    setloading(true); 
    populateDesignation();
    populateQualification();
    populateYearOfJoining();
    setloading(false);
  },[]);
 
  return (
     
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Teacher Registration</h2></div>
      <div className="content-block">
        <section className="content">
        <Spin tip="Loading" size="large" spinning={loading} delay={500}>
        <Form
      
      form={form}
      name="frmStudentRegistration"      
      onFinish={handleSubmit}    
      autoComplete="off"  
    >
         {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="fullName"  rules={[{ required: true,message: 'Full Name is required' }]}>
                <label className="form-label">Full Name<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Full Name"
                    onChange={(e)=>handleFullNameChange(e)}
                 />
                
                </Form.Item>
              </div>
            
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="dateOfBirth"  rules={[{ required: true,message: 'Date of Birth is required'  }]}>
                <label className="form-label">Date of Birth<span className="l-mandatory">*</span></label>
                <DatePicker onChange={onChange} format={"DD/MM/YYYY"} allowClear={false}
                    disabledDate={(current) => current.isAfter(moment().subtract(18,"year"))}/>   
                </Form.Item>            
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="designationId"  rules={[{ required: true,message: 'Designation is required' }]}> 
                <label className="form-label">Designation<span className="l-mandatory">*</span></label>                
                <Select className="w-100"
                  showSearch
                  placeholder="Designation"
                  size="large"                
                  optionFilterProp="children"
                  onChange={(e)=>hanleDesignationChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {designationList&&designationList.map(designation => {return <Option value={designation.designationId} key={designation.designationId}>{designation.designationName}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="qualificationIds"  rules={[{ required: true,message: 'Qualification is required' }]}> 
                <label className="form-label">qualification<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Qualification"
                  size="large"
                  optionFilterProp="children"
                  mode="multiple"
                  onChange={(e)=>hanleQualificationChange(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                 {qualificationList&&qualificationList.map(qualification => {return <Option value={qualification.qualificationId} key={qualification.qualificationId}>{qualification.qualificationName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="jobType"  rules={[{ required: true,message: 'Job Type is required' }]}> 
                <label className="form-label">Job Type<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Job Type"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleJobTypeChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {jobTypeList&&jobTypeList.map(job => {return <Option value={job.jobTypeId} key={job.jobTypeId}>{job.jobTypeName}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
             
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="yearOfJoining"  rules={[{ required: true,message: 'Year Of Joining is required'}]}>
                <label className="form-label">Year Of Joining<span className="l-mandatory">*</span></label>
                  <Select className="w-100"
                  showSearch
                  placeholder="Year Of Joining"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleYearOfJoining(e)}                   
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {yearList&&yearList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="gender"  rules={[{ required: true,message: 'Gender is required'}]}>
                <label className="form-label">Gender<span className="l-mandatory">*</span></label>
                <Select className="w-100"
                  showSearch
                  placeholder="Gender"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleGenderChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {genderList&&genderList.map(gender => {return <Option value={gender.genderId} key={gender.genderId}>{gender.genderName}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              
                <Form.Item  name="email"  
    rules={[
      { required: true, message: 'E-mail is required' },
      {
        type: 'email',
        message: 'The input is not valid E-mail!',
      },
    ]}
  >
    <label className="form-label">E-mail<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="E-mail" 
                onChange={(e)=>handleEmailChange(e)}    />  
  </Form.Item> 
              </div>
              <div className="form-group col-md-6 col-lg-3">
               <Form.Item name="mobileNumber"  rules={[{ required: true,message: 'Mobile Number is required' },
               {
               
                 pattern: new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
                 message: 'Phone must be in the right format'
               },
              ]}>
                <label className="form-label">Mobile Number<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" 
                onChange={(e)=>hanleMobileNumberChange(e)}    
                />
               </Form.Item>  
               </div> 
               <div className="form-group col-md-6 col-lg-3">
               <Form.Item name="alternativeMobileNumber"  rules={[{               
               pattern: new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
               message: 'Phone must be in the right format'
             }]}>
                <label className="form-label">Alternative Mobile Number<span className="l-mandatory"></span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" 
                onChange={(e)=>handleAlternativeMobileNumberChange(e)}    
                />
               </Form.Item>  
               </div> 
             
               <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="address">
                <label className="form-label">Address<span className="l-mandatory"></span></label>
                <TextArea rows={4} onChange={(e)=>handleAddressChange(e)}  />
                </Form.Item>
              </div> 
              <div className="form-group col-md-6 col-lg-6"></div>
                {/* <Form.Item
                  name="mobileNumber"
            
                  rules={[
                    { required: true, message: 'Mobile Number is required' },
                    {
                     // pattern: new RegExp('/^!*([0-9]!*){10,10}$/g'),
                      pattern: new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
                      message: 'Phone must be in the right format'
                    },
                  ]}
                > 
                <label className="form-label">Mobile Number<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" 
                onChange={(e)=>hanleMobileNumberChange(e)}    
                />
              </Form.Item>   */}
              


              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Cancel</Button>
                <Button type="primary" htmlType="submit" className="l-btn l-btn--primary">
                Register
        </Button>
              </div>
            </div>
         {/* </form> */}
         </Form>
         </Spin>
        </section>
      </div>
    </div>
    
  );
};
 
export default TeacherRegistrationWidget;
