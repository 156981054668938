import React from 'react';
//import axios from 'axios';
import  axiosRequest  from './apiCalls';

class StudentBulkUploadService {
    constructor() {       
       // this.svaeUser = this.saveStudent.bind(this);
        this.registerStudent = this.registerStudent.bind(this);
        this.getUploadStudentsList = this.getUploadStudentsList.bind(this);    
        this.getUploadStudentsDetails = this.getUploadStudentsDetails.bind(this);  
        this.updateFromStudentUploadToStudent=this.updateFromStudentUploadToStudent.bind(this);   
    }

    async getUploadStudentsList(studentUploadDetailsRequest) {
         // console.log("params",studentUploadDetailsRequest);    
    return  axiosRequest({
      method: "post",
      url: 'StudentBulkUpload/GetUploadStudentsList',
      data:studentUploadDetailsRequest,  
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    })
      .then((response) => {      
        return response.data 
      })
      .catch((error) => {
        console.log("the error has occured: " + error);
      });
    }

    async registerStudent(studnetUploadIds,yearOfJoining) {
       console.log("params",yearOfJoining);   
       let studnetUploadSaveRequest={
        studnetUploadIds: studnetUploadIds, yearOfJoining: yearOfJoining
       }
 return  axiosRequest({
   method: "post",
   
   url: 'StudentBulkUpload/Save',
   
   data:studnetUploadSaveRequest,  
   config: {
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
     },
   },
 })
   .then((response) => { 
    console.log("response",response.data);     
     return response.data 
   })
   .catch((error) => {
     console.log("the error has occured: " + error);
   });
 }
 async updateFromStudentUploadToStudent(updateStudentUploadModel) {
  console.log("service--", updateStudentUploadModel);
  const id = 0;
  updateStudentUploadModel.studentID=0;
  
  return await axiosRequest.patch('StudentBulkUpload/UpdateFromStudentUploadToStudent/', updateStudentUploadModel)
      .then(resp => this.id = resp.data) 
  
}
//  async updateFromStudentUploadToStudent() {

//  }
     async getUploadStudentsDetails(studentUploadId) {
     // console.log("GetUploadedStudentDetails",studentUploadId);
      return await axiosRequest.get(`StudentBulkUpload/GetUploadedStudentDetails/?studentUploadId=${studentUploadId}`);
  }
}
export default StudentBulkUploadService;