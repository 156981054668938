
export default class TeacherAllocationModel {   
    teacherAllocationID; 
     teacherID;
     courseId;
     batchId;
     courseYearId;
     semesterId;
    constructor(teacherAllocationID,teacherID,courseId,batchId,courseYearId,semesterId) {
            this.teacherAllocationID=teacherAllocationID;
        this.teacherID = teacherID;
        this.courseId = courseId;
        this.batchId = batchId;
        this.courseYearId = courseYearId;
        this.semesterId = semesterId;
              
    }
  
}

