import "./student-upload-list-widget.scss";
import { Table,TableProps, Select,Input,Form,Button,Tabs, Checkbox,Modal,Spin,DatePicker,notification} from 'antd';
import { useForm } from "react-hook-form";
import CourseService from "../../services/courseService";
import React, { useState, useEffect } from 'react';
import BatchService from "../../services/batchService";
import SemesterService from "../../services/semesterService";
import CourseYearService from "../../services/courseYearService";
import StudentService from '../../services/studentService';
import StudentBulkUploadService from '../../services/studentBulkUploadService';
import { useNavigate } from "react-router-dom";
import { json, useParams } from "react-router-dom";
import * as moment from 'moment';
import UpdateStudnetUploadModel from "./updateStudnetUploadModel";



const StudentUploadListWidget = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const { status } = useParams();

  const { Option } = Select;
  const [courseList, setCourseList] = useState(0);
  const [batchList, setBatchList] = useState(0);
  const [courseYearList , setcourseYearList] = useState(0);
  const [semesterList , setsemesterList] = useState(0);
  const [yearList , setyearList] = useState(0);
  const [sucessStudentList , setsucessStudentList] = useState(0);
  const [failedStudentList , setfailedStudentList] = useState(0);
  const [searchData , setsearchData] = useState(0);
  const [controlStatus , setcontrolStatus] = useState(0);
  const [visible, setVisible] = useState(false); 
  const [loading , setloading] = useState(false);
  const [selectedRowKeys , setSelectedRowKeys] = useState([]);
  const [studentUploadDetails, setStudentUploadDetails] = useState(0);
 
  const courseService = new CourseService();
  const batchService = new BatchService();
  const semesterService = new SemesterService();
  const courseYearService = new CourseYearService();
  const studentService = new StudentService();
  const studentBulkUploadService=new StudentBulkUploadService();
  const updateStudnetUploadModel = new UpdateStudnetUploadModel();
 
  const navigate = useNavigate();
  const [uploadedData , setuploadedData] = useState(0);
  const TabPane = Tabs.TabPane;

  const onChange = (date, dateString) => {
   // console.log(date, dateString);
   setEditedValues("dateOfBirth",date);
    form1.setFieldsValue({ dateOfBirth: date });
  };
  const populateValueFromLocalStorage=()=>
  {
    // if(status==='new')
    // {
    //const items =JSON.parse(JSON.stringify(localStorage.getItem('uploadData')))
    const items = JSON.parse(localStorage.getItem('uploadData'));
   
    console.log('populateValueFromLocalStorage',items);
    if(items!=null)
    {
      console.log("if condition")
        populateCourse();
        populateBatchByCourse(items.courseId);
        populateCourseYearByCourse(items.courseId);
        populateSemesterByCourseYear(items.courseYearId);
        setuploadedData(items);
        setFormFieldsValue(items);
        form.setFieldsValue({ courseId: items.courseId });
           form.setFieldsValue({ batchId: items.batchId });
           form.setFieldsValue({ courseYearId: items.courseYearId });
           form.setFieldsValue({ semesterId: items.semesterId });
           form.setFieldsValue({ yearOfJoining: items.yearOfJoining });
        setcontrolStatus(true);
        
          let btnSearchStuden= document.getElementById("btnSearchStudent");       
              if(btnSearchStuden)
              {
                btnSearchStuden.click();
              }  
    } 
    else{
      console.log("populateValueFromLocalStorage else condition")
      populateCourse();
      populateYearOfJoining();
     // populateStudentsList();
      setcontrolStatus(false);
    } 
    // }
  }
  const populateCourse = async () => {
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId;
    console.log("populateCourse",teacherId);
    if(teacherId>0)
    {
    const _courseList = await courseService.getCoursesForTeacher(teacherId);
    setCourseList(_courseList.data);
    }
  }
 
  const  populateBatchByCourse=async(courseId)=> {   
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId;
    const _batchList = await batchService.getBatchesByCourseForTeacher(courseId,teacherId);
    setBatchList(_batchList.data);
  }
  const hanleCourseChange=(e)=>
  {  
    form.setFieldsValue({ courseId: e });  
     populateBatchByCourse(e);
     populateCourseYearByCourse(e);
  }
  const hanleBatchChange=(e)=>{
    form.setFieldsValue({ batchId: e });
  }
  const  populateCourseYearByCourse=async(courseId)=> {
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId; 
    const _courseYearList = await courseYearService.getCourseYearForTeacher(courseId,teacherId);
    setcourseYearList(_courseYearList.data);

  }
  const populateSemesterByCourseYear=async(courseYearId)=> {
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId; 
    const _semesterList = await semesterService.getSemesterForTeacher(courseYearId,teacherId);
    setsemesterList(_semesterList.data);
   }
   const handleSubmit = (values) => {
   }
   const handleCourseYearChange = (event) => {
    form.setFieldsValue({ courseYearId: event });
       populateSemesterByCourseYear(event);
  }
  const handleSemesterChange=(e)=>{
    form.setFieldsValue({ semesterId: e });
  }
  const handleYearOfJoining=(e)=>
  {
    form.setFieldsValue({ yearOfJoining: e });
  }
  const populateYearOfJoining=async()=> {
    const _yearList = await studentService.getYear();  
    setyearList(_yearList);       
  }
  const handleInputFieldRestSearch=()=>{
    localStorage.removeItem('uploadData');
    form.resetFields();
    setcontrolStatus(false);
    setsucessStudentList([]); 
    setfailedStudentList([]);  
    //browserHistory.push('/studentUploadList');
   // navigate('/studentUploadList');
    // let btnSearchStuden= document.getElementById("btnSearchStudent");       
    // if(btnSearchStuden)
    // {
    //   btnSearchStuden.click();
    // }  
  }
  const EditData = (studentUploadId) => { 
    console.log("EditData")
    setVisible(true); 
    populateUploadStudentsDetails(studentUploadId);
    //setVisible(false); 
   }; 
   const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
   const UpdateData =async() => { 
    setloading(true);
    // var result=form.validateFields().then((response) => {      
    //   console.log(response.data); 
    // })
    form1.submit();
    const isFormValid = await form1.validateFields();//.then((item) => {console.log(item.errorFields)});  //form1.validateFields().fieldName((item) => item.errors.length > 0);
    //console.log("getFieldsError",form1.validateFields());
   console.log("isFormValid", isFormValid);
 if(isFormValid)
 {
    let dob = new Date();
    if(form.getFieldValue("dateOfBirth")!==undefined)
    {
      dob = moment(form.getFieldValue("dateOfBirth"), 'DD-MM-YYYY');
    }    
    updateStudnetUploadModel.StudentUploadId=studentUploadDetails.studentUploadId;
    updateStudnetUploadModel.courseId = form.getFieldValue("courseId");
    updateStudnetUploadModel.batchId = form.getFieldValue("batchId");
    updateStudnetUploadModel.courseYearId = form.getFieldValue("courseYearId");
    updateStudnetUploadModel.semesterId = form.getFieldValue("semesterId");
    updateStudnetUploadModel.fullName=form1.getFieldValue("fullName");
    updateStudnetUploadModel.rollNumber = form1.getFieldValue("rollNumber");
    if(form.getFieldValue("registrationNumber")!== undefined)
    updateStudnetUploadModel.registrationNumber = form1.getFieldValue("registrationNumber");
    else
    updateStudnetUploadModel.registrationNumber = "";
   // updateStudnetUploadModel.semesterId = values.semesterId;
    updateStudnetUploadModel.dateOfBirth = dob;
    updateStudnetUploadModel.email = form1.getFieldValue("email");
    updateStudnetUploadModel.gender=form1.getFieldValue("gender");
    updateStudnetUploadModel.mobileNumber = form1.getFieldValue("mobileNumber");
    updateStudnetUploadModel.yearOfJoining =form1.getFieldValue("yearOfJoining"); 

    studentBulkUploadService.updateFromStudentUploadToStudent(updateStudnetUploadModel).then(val => {
      if (val.status==1) {         
          openNotificationWithIcon('success','Data saved success fully');
          populateValueFromLocalStorage();
          populateStudentsList();
         // console.log(studentModel.studentID);
          
          // let btnSearchStuden= document.getElementById("btnSearchStudent");       
          //     if(btnSearchStuden)
          //     {
          //       btnSearchStuden.click();
          //     }  
              handleInputFieldRest();  
          setloading(false);
      }
      else if(val.status==-101)
      {
        openNotificationWithIcon('error','Record Already Existing');
        setloading(false);
      }
      else if(val.status==-102)
      {
        openNotificationWithIcon('error','Duplicate Email');
        setloading(false);
      }
      else if(val.status==-103)
      {
        openNotificationWithIcon('error','Duplicate Roll Number');
        setloading(false);
      }
      else{
        openNotificationWithIcon('error','Data saving failed');
        setloading(false);
      }
  });
}
   // setVisible(true); 
   // updateFromStudentUploadToStudent(studentUploadId);
    //setVisible(false); 
   }; 
  // const handleRollNumberChange=(e)=>
  // {   
  //   form.setFieldsValue({ rollNumber: e.target.value });   
  // }
  useEffect(() => {
    populateValueFromLocalStorage();   
   
  },[]);
  
  const populateUploadStudentsDetails = async (studentUploadId) => {  
    const _uploadStudentDetails = await studentBulkUploadService.getUploadStudentsDetails(studentUploadId);
    if(_uploadStudentDetails.data.dateOfBirth==='')
    {
     _uploadStudentDetails.data.dateOfBirth=new Date();      
    }
   
   if(_uploadStudentDetails.data.registrationNumber=='NA')
   {
    _uploadStudentDetails.data.registrationNumber="";      
   }
   if(_uploadStudentDetails.data.courseId>0)
   {
      populateBatchByCourse(_uploadStudentDetails.data.courseId);
      populateCourseYearByCourse(_uploadStudentDetails.data.courseId);
   }
    if(_uploadStudentDetails.data.courseYearId>0)
    {
       populateSemesterByCourseYear(_uploadStudentDetails.data.courseYearId);       
    }
    else{
      _uploadStudentDetails.data.courseYearId="";
      _uploadStudentDetails.data.semesterId="";
    } 
    setStudentUploadDetails(_uploadStudentDetails.data);
    setFormFieldsValue(_uploadStudentDetails.data);
  }
  const setFormFieldsValue=(_uploadStudentDetails)=>{

     console.log("setFormFieldsValue",_uploadStudentDetails.dateOfBirth);

      if(_uploadStudentDetails.fullName!='')
      form1.setFieldsValue({ fullName: _uploadStudentDetails.fullName });  
      if(_uploadStudentDetails.registrationNumber!='')
      form1.setFieldsValue({ registrationNumber: _uploadStudentDetails.registrationNumber });  
      if(_uploadStudentDetails.rollNumber!='')
      form1.setFieldsValue({ rollNumber: _uploadStudentDetails.rollNumber });  
    
      if(_uploadStudentDetails.dateOfBirth!=="" || _uploadStudentDetails.dateOfBirth!==undefined)
      {
        form1.setFieldsValue({ dateOfBirth:_uploadStudentDetails.dateOfBirth});
      }
      else
      {
        form1.setFieldsValue({ dateOfBirth: moment(new Date(), 'DD/MM/YYYY') });
      }
      // console.log("setFormFieldsValue",_uploadStudentDetails.dateOfBirth);

      if(_uploadStudentDetails.gender!='')
      form1.setFieldsValue({ gender: _uploadStudentDetails.gender });
      if(_uploadStudentDetails.email!='')
      form1.setFieldsValue({ email: _uploadStudentDetails.email });
      if(_uploadStudentDetails.mobileNumber!='')
      form1.setFieldsValue({ mobileNumber: _uploadStudentDetails.mobileNumber });
    }
    const setEditedValues = async (fieldName,value) => {
      const _uploadStudentDetails=JSON.parse(JSON.stringify(studentUploadDetails));
      switch(fieldName) {
        case 'fullName':
          _uploadStudentDetails.fullName=value;
          break;   
        case 'registrationNumber':
          _uploadStudentDetails.registrationNumber=value;
              break;
        case 'rollNumber':
          _uploadStudentDetails.rollNumber=value;
                break; 
        case 'dateOfBirth':
          _uploadStudentDetails.dateOfBirth=value;
                  break;          
        case 'gender':
          _uploadStudentDetails.gender=value;
                  break;              
        case 'email':
          _uploadStudentDetails.email=value;
                    break;  
        case 'mobileNumber':
          _uploadStudentDetails.mobileNumber=value;
                      break; 
         default:
             return '';
    
      }
      setStudentUploadDetails(_uploadStudentDetails);
    }
    // const populateStudentsListFromStorage=async()=>
    // {
    //   const items = JSON.parse(localStorage.getItem('uploadData'));
    // }
   const populateStudentsList=async()=> {    
   // console.log("status",status);
   //form.submit();
   setloading(true);
    let studentUploadDetailsRequest =null;
    // if(status=='new')
    // {    
     
     const items = JSON.parse(localStorage.getItem('uploadData'));
     console.log("uploadedData",items);
     if(items!=null)
     {
          studentUploadDetailsRequest = {
          "courseId": items.courseId,
          "batchId": items.batchId,
          "courseYearId":items.courseYearId,
          "semesterId":items.semesterId,
          "yearOfJoining":items.yearOfJoining,      
          };  
           form.setFieldsValue({ courseId: items.courseId });
           form.setFieldsValue({ batchId: items.batchId });
           form.setFieldsValue({ courseYearId: items.courseYearId });
           form.setFieldsValue({ semesterId: items.semesterId });
          setcontrolStatus(true);  
         
        //   const _studentList = await studentBulkUploadService.getUploadStudentsList(studentUploadDetailsRequest);//.then(data => {console.log("data",data)});
        //   var sucessStudents=_studentList;
        //   var failedStudents=_studentList;   
        //  sucessStudents=sucessStudents.filter(a => a.comments==null);    
        //  setsucessStudentList(sucessStudents);     
        //  failedStudents=failedStudents.filter(a => a.comments!=null);
        //  setfailedStudentList(failedStudents);  
    } 
   else
   {
    setcontrolStatus(false);
    console.log("courseId",form.getFieldValue("courseId"));
    if(form.getFieldValue("courseId")!=null || form.getFieldValue("courseId")!=undefined)
    {
    studentUploadDetailsRequest = {    
      "courseId": form.getFieldValue("courseId"),
      "batchId": form.getFieldValue("batchId"),
      "courseYearId":form.getFieldValue("courseYearId"),
      "semesterId":form.getFieldValue("semesterId"),
      "yearOfJoining":form.getFieldValue("yearOfJoining"),      
      };  
    } 
      
    // localStorage.setItem('uploadData')=studentUploadDetailsRequest;
   }

   if(studentUploadDetailsRequest!=null)
   {
    console.log("studentUploadDetailsRequest",studentUploadDetailsRequest);
    const _studentList = await studentBulkUploadService.getUploadStudentsList(studentUploadDetailsRequest);//.then(data => {console.log("data",data)});
    console.log("_studentList",_studentList);
    var sucessStudents=_studentList;
    var failedStudents=_studentList;   
      sucessStudents=sucessStudents.filter(a => a.comments==null);    
      setsucessStudentList(sucessStudents);     
      failedStudents=failedStudents.filter(a => a.comments!=null);
      setfailedStudentList(failedStudents);  
      localStorage.setItem('uploadData', JSON.stringify(studentUploadDetailsRequest)); 
      
   }
   setloading(false);
   // setVisible(false);
    // }
    // else
    // {
    //   setcontrolStatus(false);
    // //  let courseId=form.getFieldValue("batchId");
    // //  console.log("ddlCourseId",courseId);
      

      // let btnSearchStuden= document.getElementById("btnSearchStudent");       
      //     if(btnSearchStuden)
      //     {
      //       btnSearchStuden.click();
      //     }     
    //   const _studentList = await studentBulkUploadService.getUploadStudentsList(studentUploadDetailsRequest);//.then(data => {console.log("data",data)});
    // var sucessStudents=_studentList;
    // var failedStudents=_studentList;   
    //  sucessStudents=sucessStudents.filter(a => a.comments==null);    
    // setsucessStudentList(sucessStudents);     
    //  failedStudents=failedStudents.filter(a => a.comments!=null);
    //  setfailedStudentList(failedStudents);  
      console.log("studentUploadDetailsRequest",studentUploadDetailsRequest);
   // }
   
    // if(studentUploadDetailsRequest!==null)
    // {
      
    //}
  }
  const genderList = [        
    { genderName: "Male", genderId: "male" },
    { genderName: "Female", genderId: "female" }     
  ];
  const handleregistrationNumberChange=(e)=>
{
  setEditedValues("registrationNumber",e.target.value);
  form.setFieldsValue({ registrationNumber: e.target.value });
}
const handleFullNameChange=(e)=>
  {  
      setEditedValues("fullName",e.target.value);
       form1.setFieldsValue({ fullName: e.target.value });
  }
  const handleRollNumberChange=(e)=>
  {
    setEditedValues("rollNumber",e.target.value);
    form1.setFieldsValue({ rollNumber: e.target.value });
  }
  const handleGenderChange=(e)=>
  {
    setEditedValues("gender",e);
    form1.setFieldsValue({ gender: e });
  }
  const hanleEmailChange=(e)=>
  {
    setEditedValues("email",e.target.value);
    form1.setFieldsValue({email: e.target.value });
  }
  const hanleMobileNumberChange=(e)=>
  {
    setEditedValues("mobileNumber",e.target.value);    
    form1.setFieldsValue({ mobileNumber: e.target.value });
  }
  const handleInputFieldRest=()=>{  
     const _uploadData = JSON.parse(localStorage.getItem('uploadData'));
    
    // if(_uploadData!==null)
    //   {
  //   //     localStorage.removeItem('searchData');
  //   //     _searchData.courseId=null;
  //   //     _searchData.batchId=null;
  //   //     _searchData.courseYearId=null;
  //   //     _searchData.semesterId=null;
  //   //     _searchData.yearOfJoining=null;
  //   //     _searchData.rollNumber=null;
  //   //      setsearchData(_searchData);
  //   //     // setstudentList(null);
  //    }
   // console.log("handleInputFieldRest");
   setloading(false);
    form1.resetFields();
    setVisible(false);
    
   }
  const SucessColumns = [    
    {
      title: 'Roll Number',
      dataIndex: 'rollNumber',      
      key: 'rollNumber',     
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName)
    },   
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth'      
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },    
    {
      title: 'MobileNumber',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },    
    {
      title: '',
      dataIndex: 'studentUploadId',      
      key: 'studentUploadId',
      // onClick={()=>onClickViewStudent(studentID)}
      render: (studentUploadId) => <a className="l-link" onClick={()=>EditData(studentUploadId)}>Edit</a>
    }
  ];
  const failureColumns = [   
    {
      title: 'Roll Number',
      dataIndex: 'rollNumber',      
      key: 'rollNumber',     
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName)
    },   
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth'      
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'MobileNumber',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: '',
      dataIndex: 'studentUploadId',      
      key: 'studentUploadId',
      render: (studentUploadId) => <a className="l-link" onClick={()=>EditData(studentUploadId)}>Edit</a>
     
    }
  ];
  const selectRow = (rowKey) => {
    console.log("record",rowKey);
   // data.filter(item => item.chosen).map(item => item.key)
   // const selectedRowKeys = [...this.state.selectedRowKeys];
    // if (selectedRowKeys.indexOf(record.key) >= 0) {
    //   selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    // } else {
    //   selectedRowKeys.push(record.key);
    // }
    // this.setState(selectedRowKeys);
  }

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }
  // const populateUploadStudentsDetails = async (studentUploadId) => {  
  const handleRegistrationClick = async()=> {
    
    if(selectedRowKeys.length==0)
    {
      Modal.confirm({
        title: "Please select at least one record",
        okText: "Yes",
        okType: "Error"
       
      });
    }
    else{
    setloading(true);
    console.log("selectedRowKeys",selectedRowKeys);
    const yearOfJoining=form.getFieldValue("yearOfJoining");
    console.log("yearOfJoining",yearOfJoining);
    const result = await studentBulkUploadService.registerStudent(selectedRowKeys,yearOfJoining);
    console.log("result",result);
    let successMessage="";
    if(result!=null)
    {
      successMessage="Total number of records"+ result.totalCount + "Failed Record Count"+ result.failedCount + 
      "Success Record Count"+ result.sucessCount;
    }
    Modal.confirm({
      title: successMessage,
      okText: "Yes",
      okType: "warning",
      onOk: () => {}
    });
    if(result.sucessCount>0)
    {      
      openNotificationWithIcon('success','Data saved success fully');     
    }
    let btnSearchStuden= document.getElementById("btnSearchStudent");       
    if(btnSearchStuden)
    {
      btnSearchStuden.click();
    }
    setloading(false);
  }
   // selectedRowKeys,
   // onChange: onSelectedRowKeysChange(),
  }
  
  return (
    
    <div className="home">
      <div className="l-title-block"><h2>Student Uploaded Details</h2></div>
      <div className="content-block">
      <Spin tip="Loading" size="large" spinning={loading} delay={500}>
      <Form      
      form={form}
      name="frmStudentDetails"      
      onFinish={populateStudentsList}
      autoComplete="off">  
      <div className="row gx-3 gy-4">
      <div className="form-group col-md-6 col-lg-3">      

      <Form.Item name="courseId"  rules={[{ required: true,message: 'Course is required' }]}> 
                <label className="form-label">Course<span className="l-mandatory">*</span></label>                
                <Select className="w-100" value={uploadedData.courseId} disabled={controlStatus}
                  showSearch
                  placeholder="Course" id="ddlCourse"
                  size="large"                
                  optionFilterProp="children"
                  onChange={(e)=>hanleCourseChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                  {courseList&&courseList.map(course => {return <Option value={course.courseId} key={course.courseId}>{course.courseName}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="batchId"  rules={[{ required: true,message: 'Batch is required' }]}> 
                <label className="form-label">Batch<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={uploadedData.batchId} disabled={controlStatus}
                  showSearch
                  placeholder="Batch"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>hanleBatchChange(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                 {batchList&&batchList.map(batch => {return <Option value={batch.batchId} key={batch.batchId}>{batch.batchName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseYearId"  rules={[{ required: true,message: 'Course Year is required' }]}> 
                <label className="form-label">Course Year<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={uploadedData.courseYearId} disabled={controlStatus}
                  showSearch onChange={(e)=>handleCourseYearChange(e)} 
                  placeholder="Course Year"
                  size="large"
                  optionFilterProp="children"                                   
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                {courseYearList&&courseYearList.map(courseYear => {return <Option value={courseYear.courseYearId} key={courseYear.courseYearId}>{courseYear.years}</Option>})}
                 </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="semesterId" rules={[{ required: true,message: 'Semester is required' }]}>
                <label className="form-label">Semester<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={uploadedData.semesterId} disabled={controlStatus}
                  showSearch onChange={(e)=>handleSemesterChange(e)} 
                  placeholder="Semester"
                  size="large"
                  optionFilterProp="children"                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                 {semesterList&&semesterList.map(semester => {return <Option value={semester.semesterId} key={semester.semesterId}>{semester.semester}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="yearOfJoining" rules={[{ required: true,message: 'Year Of Joining is required' }]}>
                <label className="form-label">Year Of Joining<span className="l-mandatory">*</span></label>
                  <Select className="w-100" value={uploadedData.yearOfJoining} disabled={controlStatus}
                  showSearch
                  placeholder="Year Of Joining" onChange={(e)=>handleYearOfJoining(e)} 
                  size="large"                  
                  optionFilterProp="children"  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                
                  {yearList&&yearList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
             
               {uploadedData.studentsFile?                       
               <Form.Item name="uploadFileName">
                <label className="form-label">Uploaded file Name<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Uploaded file Name" 
                  value={uploadedData.studentsFile} disabled={controlStatus}
                 />                 
                </Form.Item>:""}
               
              </div>
             
              </div>
              
              {/* {status!=='new' && */}
                  <div className="l-btn-wrapper mt-2">
                    <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRestSearch}>Clear</Button>
                    <Button htmlType="submit" className="l-btn l-btn--primary" id="btnSearchStudent" >
                    Search
                  </Button>
                  </div>
                  </Form>
             {/* } */}
             <Modal 
title="Edit Student Details" 
visible={visible} 
 onCancel={handleInputFieldRest} 
 onOk={UpdateData} 
 okText="Register" 
>
<div className="upload-widget">
      {/* <div className="l-title-block"><h2 className="mb-0"></h2></div> */}
      <Form      
      form={form1}
      name="frmDetails"      
     // onFinish={populateStudentsList}
      autoComplete="off">  
      <div className="content-block">
        <section className="content">
      
      <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-6">
              <Form.Item name="fullName"  rules={[{ required: true,message: 'Full Name is required' }]}>
                <label className="form-label">Full Name<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Full Name"
                    onChange={(e)=>handleFullNameChange(e)} id="fullName" value={studentUploadDetails.fullName}
                 />                
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-6">
              <Form.Item name="registrationNumber">
                <label className="form-label">Registration Number</label>
                
                <Input type="text" className='form-control' 
                value={studentUploadDetails.registrationNumber}
                onChange={(e)=>handleregistrationNumberChange(e)}
                placeholder="Registration Number" />
                </Form.Item>
              </div>
              </div>
              <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-6">
              <Form.Item name="rollNumber"  rules={[{ required: true,message: 'Roll Number is required' }]}>
                <label className="form-label">Roll Number<span className="l-mandatory">*</span></label>
                <Input type="number" className='form-control' placeholder="Roll Number" 
                value={studentUploadDetails.rollNumber}
                onChange={(e)=>handleRollNumberChange(e)}
                 />                 
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-6">
              <Form.Item name="dateOfBirth"  rules={[{ required: true,message: 'Date of Birth is required'  }]}>
                <label className="form-label">Date of Birth<span className="l-mandatory">*</span></label>
                {console.log(moment((studentUploadDetails.dateOfBirth)))}
                <DatePicker onChange={onChange} format={"DD/MM/YYYY"} allowClear={false}
                 value={moment((studentUploadDetails.dateOfBirth))}             
               
                    disabledDate={(current) => current.isAfter(moment().subtract(16,"year"))}/>   
                </Form.Item>            
              </div>
             </div>
             <div className="row gx-3 gy-4">
              
              <div className="form-group col-md-6 col-lg-6">
              <Form.Item name="gender"  rules={[{ required: true,message: 'Gender is required'}]}>
                <label className="form-label">Gender<span className="l-mandatory">*</span></label>
                <Select className="w-100"  value={studentUploadDetails.gender}
                  showSearch
                  placeholder="Gender"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleGenderChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {genderList&&genderList.map(gender => {return <Option value={gender.genderId} key={gender.genderId}>{gender.genderName}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-6">              
                <Form.Item
                name="email"                
                rules={[
                  { required: true, message: 'E-mail is required' },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}>
              
          <label className="form-label">E-mail<span className="l-mandatory">*</span></label>
                      <Input type="text" className='form-control' placeholder="E-mail" 
                      onChange={(e)=>hanleEmailChange(e)}   value={studentUploadDetails.email} />  
         </Form.Item> 
              </div>
              </div>
              <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-6">
               <Form.Item name="mobileNumber"  rules={[{ required: true,message: 'Mobile Number is required' }]}>
                <label className="form-label">Mobile Number<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" 
                onChange={(e)=>hanleMobileNumberChange(e)} value={studentUploadDetails.mobileNumber}   
                />
               </Form.Item>   
                
              </div>
              </div>

              {/* <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Cancel</Button>
                <Button type="button"  className="l-btn l-btn--primary">
                Register
        </Button>
              
            </div> */}
        
         
        
        </section>
      </div>
      </Form>
    </div>
    
</Modal>
              
        </Spin>         
       {/* (studentList && studentList.length) { */}
       <Tabs defaultActiveKey="1">
    <TabPane tab="Upload Failed Records" key="1">
    {failedStudentList && failedStudentList.length?  ( 
        <section className="content">       
          <Table dataSource={failedStudentList} columns={failureColumns} 
          rowKey="studentUploadId"
          
          /> 
          {/* rowKey="studentUploadId"/>        */}
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
    </TabPane>
    <TabPane tab="Upload Success Records"  key="2">
   
    {sucessStudentList && sucessStudentList.length?  ( 
        <section className="content">       
          <Table dataSource={sucessStudentList} columns={SucessColumns}  
          rowKey={(record) => record.studentUploadId}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
             // console.log("selectedRowKeys",selectedRowKeys);
                setSelectedRowKeys(selectedRowKeys);
            }
        }}
             />       
        </section>
          ):<div className="l-norecords">No records found !!</div>
         } 
          <div className="l-btn-wrapper mt-2">
               
                <Button type="primary" htmlType="button" className="l-btn l-btn--primary" onClick={handleRegistrationClick}>
                Register
        </Button>
              </div>
      </TabPane>
   
  </Tabs>  
  
  

 {/* </Spin>  */}
 {/* </Spin> */}
      </div>
      
    </div>
    
  );
};

export default StudentUploadListWidget;
