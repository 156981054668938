import "./internship-all-list-widget.scss";
import { Table,notification,Button,Form,Input } from 'antd';
import React, { useState, useEffect } from 'react';
import InternshipService from "../../services/internshipService ";
import ReportService from "../../services/reportService";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import InterenshipService from "../../services/internshipService ";


const InternshipAllListWidget = () => {
  const [form] = Form.useForm();
  const [applicationList, setApplicationList] = useState([]);
  //const [studentId, setstudentId] = useState(0);
  const internshipService=new InternshipService();
  const reportService=new ReportService();
  const navigate = useNavigate();
  const interenshipService=new InterenshipService();

  
  const populateApplicationDetails=async()=>
  { 
    
    let applicationNumber=document.getElementById("applicationNumber").value;
    if(applicationNumber==''||applicationNumber==undefined)
    {
      applicationNumber='null';
    }
    else{
      applicationNumber=applicationNumber;
    }
    console.log("applicationNumber",applicationNumber);
    const _applicationList=await internshipService.getAllInternshipList(applicationNumber);
    console.log("_applicationList",_applicationList);
    setApplicationList(_applicationList.data)
   
  }

  
 /* const changeApplicationStatus=async(internshipRequestId)=>
  { 
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let adminId=items.userLoginId;
    console.log("items",items);  

    console.log("adminId",adminId);
    let printInternshipRequest={
      "internShipRequestId":internshipRequestId,
      "userLoginId":adminId,      
      "ApplicationStatusId":6
    }
    const result = await interenshipService.updateInterenshipSatusForAdmin(printInternshipRequest);
    if(result==true)
    {   
      openNotificationWithIcon('success','Application downloaded');
      populateApplicationDetails();
      //navigate("/teacherHome");
    }
  }
  */
 /*
  const onClickPrintApplication=(row)=>{
    console.log("view",row);
    // console.log(studentId);
    if(row.internShipRequestId!=undefined)
    { 
      let filedata=null;
      reportService.generatePdfReport(row.internShipRequestId).then(obj=>
        {
         // console.log(obj);
          const result= obj.data;
         // const arr = new Uint8Array(result);
          //console.log("result",result);
          var blob = new Blob([result],{type:'application/pdf'});
     // console.log("pdf",blob);
      FileSaver.saveAs(blob, row.applicationNumber+".pdf");
     changeApplicationStatus(row.internShipRequestId);
      
  }) 
  
  }
  
  }
  */
  const handleInputFieldRest=()=>{ form.resetFields();}
  const handleApplicationNumberChange=(e)=>{
    form.setFieldsValue({ applicationNumber: e.target.value }); 
  }
  const columns = [
    {
      title: 'Application Number',
      dataIndex: 'applicationNumber',
      key: 'applicationNumber',
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Application Satus',
      dataIndex: 'applicationStatus',
      key: 'applicationStatus',
    },
    {
      title: 'Application Submitted By',
      dataIndex: 'applicationSubmittedBy',
      key: 'applicationSubmittedBy',
    },
  ];
  useEffect(() => {
    //  if(studentId==0)
    //  {
      //  console.log("pageload",studentId);
       populateApplicationDetails();
    //  }
  },[]);
  return (
    <div className="home">
      <div className="l-title-block"><h2>Internship List</h2></div>
      <div className="content-block">
        <section className="content">
        <Form      
      form={form}
      name="frmTeacherList"      
      onFinish={populateApplicationDetails}
      autoComplete="off">  
      <div className="row gx-3 gy-4">
      <div className="form-group col-md-6 col-lg-3">   
      <Form.Item name="applicationNumber"  rules={[{ required: true,message: 'Application Number is required' }]}> 
                <label className="form-label">Application Number<span className="l-mandatory">*</span></label>                
                <Input type="text" className='form-control' placeholder="Application Number" 
                onChange={(e)=>handleApplicationNumberChange(e)} id="applicationNumber"
                 />                 
                </Form.Item>
              </div>
            
              <div className="form-group col-md-6 col-lg-3">              
              </div>              
             
              </div>
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Clear</Button>
                <Button type="button" htmlType="submit" className="l-btn l-btn--primary" id="btnSearchTeacher">
                Search
        </Button>

              </div>
              </Form>
        {applicationList && applicationList.length?  ( 
        <section className="content">       
          <Table dataSource={applicationList} columns={columns} rowKey="internShipRequestId"/>       
        </section>
          ):""
         }
          {/* <Table dataSource={dataSource} columns={columns} />; */}
        </section>
      </div>
    </div>
  );
};

export default InternshipAllListWidget;
