import React from 'react';
import  axiosRequest  from './apiCalls';
 //import axios from 'axios';

class AuthService {
    constructor() {        
        this.getUserLogin = this.getUserLogin.bind(this);        
    }
    async getUserLogin(userloginRequest) { 
      return await axiosRequest.post('Auth/userLogin/',userloginRequest).then(resp => this.data = resp.data) 
    /*  return await axios.post('https://localhost:7042/api/Auth/userLogin/', userloginRequest)
        .then(resp => this.data = resp.data)   
        */     
      /*  console.log("userLoginModel",userloginRequest);
        return  axios({
            method: "post",
            url: 'https://localhost:7042/api/Auth/userLogin',
            data:userloginRequest,           
            config: {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
          })
            .then((response) => {
              console.log("data",response.data);
              return response.data           
            })
            .catch((error) => {
              console.log("the error has occured: " + error);
            });
            */
    }  
    async getUserLogout() {        
      //console.log("userLoginModel",userloginRequest);
      let item= JSON.parse(localStorage.getItem('userDetails'));
      const token = item.token;
      console.log("token",token);
      var _headers = {
        headers: {
          'Authorization': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
   // _headers.headers['Authorization'] = "Bearer" + token;
     // console.log("_headers",_headers);
      //axiosRequest.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return await axiosRequest.post('Auth/UserLogout')
      .then(resp => this.data = resp.data)  
      
     /* return  axios({
          method: "post",
          url: 'https://localhost:7042/api/Auth/UserLogout',
         // data:userloginRequest,           
          config: {
            headers: {
              'Authorization':'Bearer'+ token,
              // 'Accept': "application/json",
              // 'Content-Type': "application/json",
            },
          },
        })
          .then((response) => {
            console.log(response.data);
            return response.data           
          })
          .catch((error) => {
            console.log("the error has occured: " + error);
          });
          */
  }      
}
export default AuthService;