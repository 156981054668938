import "./teacher-home-widget.scss";
import { Table,Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import InternshipService from "../../services/internshipService ";
import { useNavigate } from "react-router-dom";


const TeacherHomeWidget = () => {
  const [interenshipApplicationList, setInterenshipApplicationList] = useState([]);
  const [rejectedApplicationList, setRejectedApplicationList] = useState([]);
  const [approvedApplicationList, setApprovedApplicationList] = useState([]);
  const [teacherId, setteacherId] = useState(0);
  const internshipService=new InternshipService();
  const navigate = useNavigate();
  const TabPane = Tabs.TabPane;

  const populateRequestApplication=async()=>
  {
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let teacherId=items.referenceId;
   // console.log("teacherId",teacherId);
    setteacherId(teacherId);
    if(teacherId>0)
    {
      let applicationStatusId=2;
    const _interenshipApplicationList=await internshipService.getStudentInternshipListForTeacher(teacherId,applicationStatusId);
    //console.log("interenshipApplicationList",_interenshipApplicationList);
    setInterenshipApplicationList(_interenshipApplicationList.data)
    }
  }
  const populateApprovedApplication=async()=>
  {
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let teacherId=items.referenceId;
   // console.log("teacherId",teacherId);
    setteacherId(teacherId);
    if(teacherId>0)
    {
      let applicationStatusId=4;
    const _approvedApplicationList=await internshipService.getStudentInternshipListForTeacher(teacherId,applicationStatusId);
    //console.log("approvedApplicationList",_approvedApplicationList);
    setApprovedApplicationList(_approvedApplicationList.data)
    }
  }
  const populateRejectedApplication=async()=>
  {
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let teacherId=items.referenceId;
   // console.log("teacherId",teacherId);
    setteacherId(teacherId);
    if(teacherId>0)
    {
      let applicationStatusId=3;
    const _rejectedApplicationList=await internshipService.getStudentInternshipListForTeacher(teacherId,applicationStatusId);
    //console.log("rejectedApplicationList",_rejectedApplicationList);
    setRejectedApplicationList(_rejectedApplicationList.data)
    }
  }
  const onClickViewApplication=(internShipRequestId)=>{
    console.log("view",internShipRequestId);
   
    if(internShipRequestId!=undefined)
    { 
        navigate("/verifyInternshipApplication/"+internShipRequestId);       
    }      
  }
  
  const Columns = [
    {
      title: 'Application Number',
      dataIndex: 'applicationNumber',
      key: 'applicationNumber',
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Application Satus',
      dataIndex: 'applicationStatus',
      key: 'applicationStatus',
    },
    {
      title: 'Application Submitted By',
      dataIndex: 'applicationSubmittedBy',
      key: 'applicationSubmittedBy',
    },
    {
      title: '',
      dataIndex: 'internShipRequestId',      
      key: 'internShipRequestId',
      render: (internShipRequestId) => <a className="l-link" onClick={()=>onClickViewApplication(internShipRequestId)}>View</a>
    },
    
  ];
  
  useEffect(() => {
     if(teacherId==0)
     {
       console.log("pageload",teacherId);
       populateRequestApplication();
      populateRejectedApplication();
      populateApprovedApplication();
     }
  },[]);
  return (
    <div className="home">
      <div className="l-title-block"><h2>Home</h2></div>
      <div className="content-block">
        <section className="content">
        <Tabs defaultActiveKey="1">
    <TabPane tab="Requested Applications" key="1">
    {interenshipApplicationList && interenshipApplicationList.length?  ( 
        <section className="content">       
         <Table dataSource={interenshipApplicationList} columns={Columns} rowKey="internShipRequestId"/>   
         
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
    </TabPane>
    <TabPane tab="Approved Applications"  key="2">
   
    {approvedApplicationList && approvedApplicationList.length?  ( 
        <section className="content">       
         <Table dataSource={approvedApplicationList} columns={Columns} rowKey="internShipRequestId"/>   
         
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
              
      </TabPane>
      <TabPane tab="Rejected Applications"  key="3">
   
   {rejectedApplicationList && rejectedApplicationList.length?  ( 
       <section className="content">       
        <Table dataSource={rejectedApplicationList} columns={Columns} rowKey="internShipRequestId"/>   
        
       </section>
         ):<div className="l-norecords">No records found !!</div>
        }
             
     </TabPane>
  </Tabs>  
        {/* {teacherList && teacherList.length?  ( 
        <section className="content">       
          <Table dataSource={teacherList} columns={columns} rowKey="internShipRequestId"/>       
        </section>
          ):""
         } */}
         
        </section>
      </div>
    </div>
  );
};

export default TeacherHomeWidget;
