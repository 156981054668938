import React from 'react';
//import axios from 'axios';
import  axiosRequest  from './apiCalls';
class UserLoginService {
    constructor() {  
        this.updatePassword=this.updatePassword.bind(this);  
    }
    async updatePassword(resetPasswordModel) {
        console.log("service--", resetPasswordModel);
        const result=0;
       return await axiosRequest.put('UserLogin/UpdatePassword/', resetPasswordModel)
            .then(resp => this.result = resp.data)         
      }
}
export default UserLoginService;