import "./student-home-widget.scss";
import { Table } from 'antd';
import React, { useState, useEffect } from 'react';
import InternshipService from "../../services/internshipService ";
import { useNavigate } from "react-router-dom";


const StudentHomeWidget = () => {
  const [studentList, setstudentList] = useState([]);
  const [studentId, setstudentId] = useState(0);
  const internshipService=new InternshipService();
  const navigate = useNavigate();
  // const dataSource = [
  //   {
  //     key: '1',
  //     name: 'Mike',
  //     age: 32,
  //     address: '10 Downing Street',
  //   },
  //   {
  //     key: '2',
  //     name: 'John',
  //     age: 42,
  //     address: '10 Downing Street',
  //   },
  // ];
  const populateStudensDetails=async()=>
  {
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let studentId=items.referenceId;
    console.log("studentId",studentId);
    setstudentId(studentId);
    if(studentId>0)
    {
    const _studentList=await internshipService.getStudentInternshipList(studentId);
    console.log("_studentList",_studentList);
    setstudentList(_studentList.data)
    }

  }
  const onClickViewStudent=(internShipRequestId)=>{
    console.log("view",internShipRequestId);
    // console.log(studentId);
    if(internShipRequestId!=undefined)
    { 
      //setDataToLocalStorage();
      //localStorage.removeItem('searchData');     
       // populateDataFromLocalStorage();
        navigate("/internshipDetails/"+internShipRequestId);       
    }       
    

  }
  const columns = [
    {
      title: 'Application Number',
      dataIndex: 'applicationNumber',
      key: 'applicationNumber',
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: '',
      dataIndex: 'internShipRequestId',      
      key: 'internShipRequestId',
      render: (internShipRequestId) => <a className="l-link" onClick={()=>onClickViewStudent(internShipRequestId)}>View</a>
    }
  ];
  useEffect(() => {
     if(studentId==0)
     {
       console.log("pageload",studentId);
      populateStudensDetails();
     }
  },[]);
  return (
    <div className="home">
      <div className="l-title-block"><h2>Intership Applications</h2></div>
      <div className="content-block">
        <section className="content">
        {studentList && studentList.length?  ( 
        <section className="content">       
          <Table dataSource={studentList} columns={columns} rowKey="internShipRequestId"/>       
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
          {/* <Table dataSource={dataSource} columns={columns} />; */}
        </section>
      </div>
    </div>
  );
};

export default StudentHomeWidget;
