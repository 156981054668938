import "./student-list-widget.scss";
import { Table,TableProps, Select,Input,Form,Button,Modal,notification,Spin } from 'antd';
import { useForm } from "react-hook-form";
import CourseService from "../../services/courseService";
import React, { useState, useEffect } from 'react';
import BatchService from "../../services/batchService";
import SemesterService from "../../services/semesterService";
import CourseYearService from "../../services/courseYearService";
import StudentService from '../../services/studentService';
import { useNavigate } from "react-router-dom";


const StudentListWidget = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [courseList, setCourseList] = useState(0);
  const [batchList, setBatchList] = useState(0);
  const [courseYearList , setcourseYearList] = useState(0);
  const [semesterList , setsemesterList] = useState(0);
  const [yearList , setyearList] = useState(0);
  const [studentList , setstudentList] = useState(0);
  const [searchData , setsearchData] = useState(0);
  const [loading , setloading] = useState(false);
  const [teacherId , setteacherId] = useState(0);

  const courseService = new CourseService();
  const batchService = new BatchService();
  const semesterService = new SemesterService();
  const courseYearService = new CourseYearService();
  const studentService = new StudentService();

  //const studentModel = new StudentModel();
  const navigate = useNavigate();
  const populateCourse = async () => {   
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId;   
     // console.log("populateCourse",teacherId);
    // if(teacherId>0)
    // {
    const _courseList = await courseService.getCoursesForTeacher(teacherId);
    setCourseList(_courseList.data);
    // }
  }
 
  const  populateBatchByCourse=async(courseId)=> {   
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId; 
    const _batchList = await batchService.getBatchesByCourseForTeacher(courseId,teacherId);
    setBatchList(_batchList.data);
  }
  const hanleCourseChange=(e)=>
  {    
    form.setFieldsValue({ courseId: e });  
     populateBatchByCourse(e);
     populateCourseYearByCourse(e);
     setEditedValues("courseId",e); 
  }
  const hanleBatchChange=(e)=>{
    setEditedValues("batchId",e); 
    form.setFieldsValue({ batchId: e });
  }
  const  populateCourseYearByCourse=async(courseId)=> {
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId; 
    const _courseYearList = await courseYearService.getCourseYearForTeacher(courseId,teacherId);
    setcourseYearList(_courseYearList.data);

  }
  const populateSemesterByCourseYear=async(courseYearId)=> {
    const items = JSON.parse(localStorage.getItem('userDetails'));    
    let teacherId=items.referenceId; 
    const _semesterList = await semesterService.getSemesterForTeacher(courseYearId,teacherId);
    setsemesterList(_semesterList.data);
   }
   const handleCourseYearChange = (e) => {
    setEditedValues("courseYearId",e); 
    form.setFieldsValue({ courseYearId: e });
       populateSemesterByCourseYear(e);
  }
  const handleSemesterChange=(e)=>{
    setEditedValues("semesterId",e); 
    form.setFieldsValue({ semesterId: e });
  }
  const handleYearOfJoining=(e)=>
  {
    setEditedValues("yearOfJoining",e); 
    form.setFieldsValue({ yearOfJoining: e });
  }
  const populateYearOfJoining=async()=> {
    const _yearList = await studentService.getYear();  
    setyearList(_yearList);       
  }
  const handleRollNumberChange=(e)=>
  {  
    setEditedValues("rollNumber", e.target.value); 
    form.setFieldsValue({ rollNumber: e.target.value });   
  }
  useEffect(() => { 
   // pouplateTeacher(); 
    setloading(true);    
   
      

    //   console.log("onload",teacherId);
      populateCourse();
      populateYearOfJoining();

      populateDataFromLocalStorage();
    
    setloading(false);
  },[]);
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const setEditedValues = async (fieldName,value) => {
    const _searchData=JSON.parse(JSON.stringify(searchData));    
    if(_searchData!==0)
    {      
        switch(fieldName) {          
          case 'rollNumber':
            _searchData.rollNumber=value;
              break;               
          case 'courseId':
             _searchData.courseId=value;           
               break;          
          case 'batchId':
            _searchData.batchId=value;
               break;
          case 'courseYearId':
            _searchData.courseYearId=value;
              break;          
          case 'semesterId':
            _searchData.semesterId=value;
              break;
          case 'yearOfJoining':
            _searchData.yearOfJoining=value;
              break;           
          default:
              return '';
    }
    setsearchData(_searchData);
    }
   
  }
  const setFormFieldsValue=(_searchData)=>{
    //  console.log("studentDetails",_studentDetails.dateOfBirth )
     // const _studentDetails=JSON.parse(JSON.stringify(studentDetails));
     if(_searchData!==null)
     {     
      console.log("setFormFieldsValue",_searchData);
      if(_searchData.rollNumber!='')
      form.setFieldsValue({ rollNumber: _searchData.rollNumber });  
     
      if(_searchData.courseId!='')
      form.setFieldsValue({ courseId: _searchData.courseId });
      if(_searchData.batchId!='')    
      form.setFieldsValue({ batchId: _searchData.batchId });
      if(_searchData.courseYearId!='')    
      form.setFieldsValue({ courseYearId: _searchData.courseYearId });
      if(_searchData.semesterId!='')   
      form.setFieldsValue({ semesterId: _searchData.semesterId });
      if(_searchData.yearOfJoining!='')   
      form.setFieldsValue({ yearOfJoining: _searchData.yearOfJoining });
     }
      
    }
  const populateDataFromLocalStorage = () => {
    //pouplateTeacher();
    const _searchData = JSON.parse(localStorage.getItem('searchData'));    
    console.log("_searchData",_searchData);
    if(_searchData!==null)
    {  
     // const items = JSON.parse(localStorage.getItem('userDetails'));
   // console.log(items);
     // let teacherId=items.referenceId;
     
     // setTeacherId(items.referenceId);    
     // console.log("_searchData",_searchData);
     populateCourse();
      setsearchData(_searchData);
      setFormFieldsValue(_searchData);
      
         if(_searchData.courseId!=null || _searchData.courseId!=undefined)
         {
           populateBatchByCourse(_searchData.courseId);
           populateCourseYearByCourse(_searchData.courseId);
         }
         if(_searchData.courseYearId!=null || _searchData.courseYearId!=undefined)
         {
            populateSemesterByCourseYear(_searchData.courseYearId);           
         }
         let btnSearchStuden= document.getElementById("btnSearchStudent");       
          if(btnSearchStuden)
          {
            btnSearchStuden.click();
          }          
    }
   
  };
  
  const onClickViewStudent=(studentId)=>{
    console.log("view",studentId);
    // console.log(studentId);
    if(studentId!=undefined)
    { 
      //setDataToLocalStorage();
      //localStorage.removeItem('searchData');     
       // populateDataFromLocalStorage();
        navigate("/StudentDetails/"+studentId);       
    }       
    

  }
  const handleInputFieldRest=()=>{  
    const _searchData = JSON.parse(localStorage.getItem('searchData'));
    
    if(_searchData!==null)
    {
        localStorage.removeItem('searchData');
        _searchData.courseId=null;
        _searchData.batchId=null;
        _searchData.courseYearId=null;
        _searchData.semesterId=null;
        _searchData.yearOfJoining=null;
        _searchData.rollNumber=null;
         setsearchData(_searchData);
         setstudentList(null);
    }
   // console.log("handleInputFieldRest");
    form.resetFields();
   }
   const onDeleteStudent = (studentId) => {
   
    console.log("record",studentId);
    Modal.confirm({
      title: "Are you sure, you want to delete this student record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setloading(true);
       studentService.deleteStudent(studentId).then(res => {
      // console.log("res", res);
       if(res==true)
       {
        openNotificationWithIcon('success','Data deleted success fully');      

          let btnSearchStuden= document.getElementById("btnSearchStudent");       
          if(btnSearchStuden)
          {
            btnSearchStuden.click();
          }
      }
      else
      {
        openNotificationWithIcon('error','Data deletion failed');     
      }
     
    });
    setloading(false);
      },
    
    });
    
  };
  const pouplateTeacher=()=>{
    const items = JSON.parse(localStorage.getItem('userDetails'));
     setteacherId(items.referenceId);
       console.log("teacherId",teacherId);
  }
   const populateStudentsList=async(values)=> {
   // console.log(values.rollNumber);
    setloading(true);
    localStorage.removeItem('searchData');   
    if(values.rollNumber=== undefined || values.rollNumber==='')
      values.rollNumber = "";

    let studentRequest = {
      "courseId": values.courseId,
      "batchId": values.batchId,
      "courseYearId":values.courseYearId,
      "semesterId":values.semesterId,
      "yearOfJoining":values.yearOfJoining,
      "rollNumber":values.rollNumber
      };
      

    const _studentList = await studentService.getStudentsList(studentRequest);//.then(data => {console.log("data",data)});
    //console.log("_studentList",_studentList)
    setstudentList(_studentList);   
    localStorage.setItem('searchData', JSON.stringify(studentRequest)); 
    setloading(false);   
  }
    
  const columns = [
    {
      title: 'Roll Number',
      dataIndex: 'rollNumber',      
      key: 'rollNumber',    
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName)
    },
   
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',     
    },
    {
      title: 'Semester',
      dataIndex: 'semester',
      key: 'semester',
    },
    {
      title: 'Years',
      dataIndex: 'years',
      key: 'years',
    },
    {
      title: 'MobileNumber',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: '',
      dataIndex: 'studentID',      
      key: 'studentID',
      render: (studentID) => <a className="l-link" onClick={()=>onClickViewStudent(studentID)}>View</a>
    },
    {
      title: '',
      dataIndex: 'studentID',      
      key: 'studentID',
      render: (record) => <a className="l-link" onClick={()=>onDeleteStudent(record)}>Delete</a>
    }
  ];
   
  return (
    <div className="home">
      <div className="l-title-block"><h2>Student List</h2></div>
      <div className="content-block">
      
      <Form      
      form={form}
      name="frmStudentList"      
      onFinish={populateStudentsList}
      autoComplete="off">  
       <Spin tip="Loading" size="large" spinning={loading} delay={500}>
      <div className="row gx-3 gy-4">
      <div className="form-group col-md-6 col-lg-3">    

      <Form.Item name="courseId"  rules={[{ required: true,message: 'Course is required' }]}> 
                <label className="form-label">Course<span className="l-mandatory">*</span></label>                
                <Select className="w-100" id="ddlCourse" value={searchData.courseId||undefined}
                  showSearch
                  placeholder="Course"
                  size="large"                
                  optionFilterProp="children"
                  onChange={(e)=>hanleCourseChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                  {courseList&&courseList.map(course => {return <Option value={course.courseId} key={course.courseId}>{course.courseName}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="batchId"  rules={[{ required: true,message: 'Batch is required' }]}> 
                <label className="form-label">Batch<span className="l-mandatory">*</span></label>
                <Select className="w-100" id="ddlBatch" value={searchData.batchId || undefined}
                  showSearch
                  placeholder="Batch"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>hanleBatchChange(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                 {batchList&&batchList.map(batch => {return <Option value={batch.batchId} key={batch.batchId}>{batch.batchName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseYearId"> 
                <label className="form-label">Course Year<span className="l-mandatory"></span></label>
                <Select className="w-100" value={searchData.courseYearId || undefined}
                  showSearch
                  placeholder="Course Year"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleCourseYearChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                {courseYearList&&courseYearList.map(courseYear => {return <Option value={courseYear.courseYearId} key={courseYear.courseYearId}>{courseYear.years}</Option>})}
                 </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="semesterId">
                <label className="form-label">Semester<span className="l-mandatory"></span></label>
                <Select className="w-100" value={searchData.semesterId || undefined}
                  showSearch
                  placeholder="Semester"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleSemesterChange(e)}  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                 {semesterList&&semesterList.map(semester => {return <Option value={semester.semesterId} key={semester.semesterId}>{semester.semester}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="yearOfJoining" rules={[{ required: true,message: 'Year Of Joining is required' }]}>
                <label className="form-label">Year Of Joining<span className="l-mandatory">*</span></label>
                  <Select className="w-100" value={searchData.yearOfJoining || undefined}
                  showSearch
                  placeholder="Year Of Joining"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleYearOfJoining(e)}                   
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                
                  {yearList&&yearList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="rollNumber">
                <label className="form-label">Roll Number<span className="l-mandatory"></span></label>
                <Input type="number" className='form-control' placeholder="Roll Number" value={searchData.rollNumber || undefined}
                onChange={(e)=>handleRollNumberChange(e)}
                 />                 
                </Form.Item>
              </div>
             
              </div>
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Clear</Button>
                <Button type="button" htmlType="submit" className="l-btn l-btn--primary" id="btnSearchStudent">
                Search
        </Button>

              </div>
              </Spin>
              </Form>
       {/* (studentList && studentList.length) { */}
       {studentList && studentList.length?  ( 
        <section className="content">       
          <Table dataSource={studentList} columns={columns} rowKey="studentID"/>       
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
      </div>
    </div>
  );
};

export default StudentListWidget;
