import "./internship-application-widget.scss";
import { Table,TableProps,Select, Button, Radio,Input,DatePicker,RadioChangeEvent,Space,Form,notification,Modal } from "antd";
import { useForm } from "react-hook-form";
import * as moment from 'moment';
// import CourseService from "../../services/courseService";
import React, { useState, useEffect } from 'react';
import InternshipService from "../../services/internshipService ";
import InternShipModel from "./internshipModel";
import { useNavigate } from "react-router-dom";

const InternshipApplicationWidget = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [showStudents , setshowStudents] = useState(0);
  const internShipModel = new InternShipModel();
  const navigate = useNavigate(); 
  const internshipService=new InternshipService();
  let startDate=new Date();
  const handleInputFieldRest=()=>{  
    form.resetFields();
   }
  const handleAddressChange=(e)=>
  {   
    console.log("txt", e.target.value);
    // var newline = e.target.value.fromCharCode(13, 10);
    // console.log("newline", newline);
    form.setFieldsValue({organizationAddress: e.target.value });
  }
  const handleOrganizationName=(e)=>
  {   
    form.setFieldsValue({organizationName: e.target.value });
  }
  const handlePersonNameChange=(e)=>
  {   
    form.setFieldsValue({contactPersonName: e.target.value });
  }
  const handlePersonPhoneNumberChange=(e)=>
  {   
    form.setFieldsValue({contactPersonPhoneNumber: e.target.value });
  }
  const handlePersonDesignationChange=(e)=>
  {   
    form.setFieldsValue({contactPersonDesignation: e.target.value });
  }
  const handleorganizationEmailChange=(e)=>
  {   
    form.setFieldsValue({organizationEmail: e.target.value });
  }
  const handlePersonEmailChange=(e)=>
  {   
    form.setFieldsValue({contactPersonEmail: e.target.value });
  }
  
const onApplicationTypeChange = (e) => {
  if(e.target.value==2)
  setshowStudents(true);
  else
  setshowStudents(false);
  console.log('radio checked', e.target.value);
  form.setFieldsValue({ internshipType: e.target.value }); 
};
 const handlePanelChange=()=>{
  console.log("handlePanelChange");
 }
const onStartDateChange = (date, dateString) => {
  startDate=date;
    form.setFieldsValue({ startDate: date });
 };
 const onEndDateChange = (date, dateString) => {
    form.setFieldsValue({ endDate: date });
 };
 const handleorganizationContactNumberChange = (e) => {
    form.setFieldsValue({ organizationContactNumber: e.target.value });
 };

const openNotificationWithIcon = (type,message) => {
  notification[type]({
    message: 'KLA',
    description:
      message,
  });
};

const handleSubmit = (values) => { 
 let formValid=false;
 form.getFieldsError().forEach((fieldsError, index) => {
  if(fieldsError.errors.length==0)
  {
    //console.log("fieldsError",fieldsError);
    formValid=true;
  }
  else
  {
    formValid=false;
  }
 });
  // for (var i=0; i<str.length; i++) {
  //   console.log(i,str[i]);
  // }
 // console.log("error",form.getFieldsError().errors.length)
  if(formValid=true)
  {
     console.log("valid");
     let address=form.getFieldValue("organizationAddress").toString();
    // console.log("address",address);
    let addressFormat = address.replace(/(?:\r\n|\r|\n)/g, '<br>');
   // console.log("address",addressFormat);
  Modal.confirm({
    title: "Please verify the request details are correct. After submission, you would not able to edit",
    okText: "Yes",
    okType: "warning",
    onOk: () => {
  let fromDate = new Date();
  fromDate = moment(form.getFieldValue("startDate"), 'DD-MM-YYYY');
  let toDate = new Date();
  toDate = moment(form.getFieldValue("endDate"), 'DD-MM-YYYY');
  const items = JSON.parse(localStorage.getItem('userDetails'));
  let studentId=items.referenceId;
    let internshipType;
     internshipType=form.getFieldValue("internshipType")
 
    console.log("internshipType",internshipType); 
  if(internshipType==1)
  {
    values.internshipType="Individual";
  }
  else
  {
    values.internshipType="Group";
  }  
  internShipModel.internShipRequestId=0;
  internShipModel.organizationName=form.getFieldValue("organizationName");
  internShipModel.organizationAddress=addressFormat;//form.getFieldValue("organizationAddress");
  internShipModel.organizationContactNumber=form.getFieldValue("organizationContactNumber");  
  // if(form.getFieldValue("organizationEmail")!== undefined)
  internShipModel.organizationEmail=form.getFieldValue("organizationEmail");
    // else
    // internShipModel.organizationEmail = "";
  internShipModel.contactPersonName=form.getFieldValue("contactPersonName");
  internShipModel.contactNumber=form.getFieldValue("contactPersonPhoneNumber");
  internShipModel.designationName=form.getFieldValue("contactPersonDesignation"); 
  // if(form.getFieldValue("contactEmail")!== undefined)
  internShipModel.contactEmail=form.getFieldValue("contactPersonEmail");
    // else
    // internShipModel.contactEmail = "";
  internShipModel.internshipType=values.internshipType;
  internShipModel.startDate=fromDate;
  internShipModel.endDate=toDate;
  internShipModel.applicationStatusId=2;
  internShipModel.applicationSubmittedBy=studentId;
  console.log("interenShipModel",internShipModel);
  internshipService.saveInternShip(internShipModel).then(val => {
             console.log(val);
        if (val.internShipRequestId > 0 && val.status==1) {
           
            openNotificationWithIcon('success','Data saved success fully');
        //     console.log(teacherModel.teacherID);
             handleInputFieldRest(); 
             navigate('/internshipDetails/'+val.internShipRequestId);
         }
         else{
          if (val.internShipRequestId > 0 && val.status==-100) {
            openNotificationWithIcon('warning','Duplicate record');
          }
          
          else{
           openNotificationWithIcon('error','Data saved failed');
          }
         }
    });
  }
});

  }
  //interenShipModel.crea
  
}
const handleNextClick=(values)=>
{ 
  localStorage.removeItem("internshipData");
  let fromDate = new Date();
  fromDate = moment(form.getFieldValue("startDate"), 'DD-MM-YYYY');
  let toDate = new Date();
  toDate = moment(form.getFieldValue("endDate"), 'DD-MM-YYYY');
  const items = JSON.parse(localStorage.getItem('userDetails'));
  let studentId=items.referenceId;
  let intershipType;
   intershipType=form.getFieldValue(intershipType)
   let intershipTypeName="";
   
  if(intershipType==1)
  {
    intershipTypeName="Individual";
  }
  else
  {
    intershipTypeName="Group";
  }
  let internshipData = {
    "organizationName": form.getFieldValue("organizationName"),
    "organizationAddress": form.getFieldValue("organizationAddress"),
    "organizationContactNumber":form.getFieldValue("organizationContactNumber"),
   // "organizationEmail":form.getFieldValue("organizationEmail"),
    "contactPersonName":form.getFieldValue("contactPersonName"),
    "contactNumber":form.getFieldValue("contactPersonPhoneNumber"),
    "designationName":form.getFieldValue("contactPersonDesignation"),
   // "contactEmail":form.getFieldValue("contactEmail"),
    "internshipType":intershipTypeName,
    "startDate":fromDate,
    "endDate":toDate,
    "applicationStatusId":2,
    "applicationSubmittedBy":studentId,
    "studentId":studentId    
    };
    const contactEmail=form.getFieldValue("contactPersonEmail");
    const  organizationEmail=form.getFieldValue("organizationEmail");
    if(contactEmail)
    {
      internshipData["contactEmail"]=contactEmail;
    }
    else
    {
      internshipData["contactEmail"]='';        
    }
    if(organizationEmail)
    {
      internshipData["organizationEmail"]=organizationEmail;      
    }
    else
    {
      internshipData["organizationEmail"]='';        
    }
    console.log('internshipData',internshipData);
    localStorage.setItem('internshipData',JSON.stringify(internshipData));    
    navigate("/groupInternshipApplication");
 }
  useEffect(() => {
    localStorage.removeItem('internshipData'); 
  },[]);

  return (
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Internship Details</h2></div>
      <div className="content-block">
        <section className="content">

        <Form      
      form={form}
      name="frmInternshipApplication" id="frmInternshipApplication"     
      //onFinish={handleSubmit}    
      autoComplete="off"> 

      <div className="row gx-3 gy-4">
      <div className="form-group col-md-12 col-lg-12"> Application Details</div>
      <div className="form-group col-md-6 col-lg-3"> 
      <Form.Item name="internshipType"  rules={[{ required: true,message: 'Application Type is required' }]}>       
                <label className="form-label">Application Type<span className="l-mandatory">*</span></label>               
                <Radio.Group onChange={onApplicationTypeChange}>
                <Space direction="horizontal">
                  <Radio value="1">Individual</Radio>
                  <Radio value="2">Group</Radio>                 
                </Space>
              </Radio.Group>
          </Form.Item>             
      </div>
      <div className="form-group col-md-6 col-lg-3">
      <Form.Item name="startDate"  rules={[{ required: true,message: 'Start Date  is required' }]}>  
                <label className="form-label">Start Date</label>
                <DatePicker onChange={onStartDateChange} format={"DD/MM/YYYY"}
                 defaultValue={moment(new Date())}   disabledDate={(current) => current.isBefore(moment(new Date()))}/>   
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="endDate"  rules={[{ required: true,message: 'End Date  is required' }]}>  
                <label className="form-label">End Date</label>
                <DatePicker onChange={onEndDateChange} format={"DD/MM/YYYY"}
                defaultValue={moment(new Date())}  onPanelChange={handlePanelChange}
                    disabledDate={(current) => current.isSameOrBefore(moment(new Date(startDate)))}/>  
                 </Form.Item>    
              </div>
        </div> 
            <div className="row gx-3 gy-4">
            <div className="form-group col-md-12 col-lg-12"> Organization Details</div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="organizationName"  rules={[{ required: true,message: ' Name  is required' }]}>  
                <label className="form-label">Name<span className="l-mandatory">*</span></label>
                <input type="text" className='form-control' placeholder="Organization Name" 
                onChange={(e)=>handleOrganizationName(e)}/>
                </Form.Item>    
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="organizationAddress"  rules={[{ required: true,message: ' Address is required' }]}> 
                <label className="form-label">Address<span className="l-mandatory">*</span></label>
                <TextArea rows={3} onChange={(e)=>handleAddressChange(e)} placeholder="Organization Address" />
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="organizationContactNumber"  rules={[{ required: true,message:' Contact Number is required' }]}> 
                <label className="form-label">Contact Number<span className="l-mandatory">*</span></label>
                <input type="text" className='form-control' placeholder="Organization Contact Number"
                onChange={(e)=>handleorganizationContactNumberChange(e)} />
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="organizationEmail"  rules={[{ required: true,message:' Organization Email is required' }]}> 
                <label className="form-label">Email<span className="l-mandatory">*</span></label>
                <input type="text" className='form-control' placeholder="Organization Email" 
                 onChange={(e)=>handleorganizationEmailChange(e)}/>
                </Form.Item>
              </div>
              <div className="form-group col-md-12 col-lg-12"> Contact Person Details</div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="contactPersonName"  rules={[{ required: true,message:' Person Name is required' }]}> 
                <label className="form-label">Person Name<span className="l-mandatory">*</span></label>
                <input type="text" className='form-control' placeholder="Contact Person Name" 
                onChange={(e)=>handlePersonNameChange(e)} />
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="contactPersonPhoneNumber"  rules={[{ required: true,message:'Phone Number is required' }]}> 
                <label className="form-label"> Phone Number<span className="l-mandatory">*</span></label>
                <input type="text" className='form-control' placeholder="Contact Person Phone Number"
                onChange={(e)=>handlePersonPhoneNumberChange(e)} />
                 </Form.Item>
              </div>
            
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="contactPersonDesignation"  rules={[{ required: true,message:'Designation is required' }]}> 
                <label className="form-label">Designation<span className="l-mandatory">*</span></label>
                <input type="text" className='form-control' placeholder="Designation"
                onChange={(e)=>handlePersonDesignationChange(e)} />
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="contactPersonEmail" rules={[{ required: true,message:'contact Person Email is required' }]}>
                <label className="form-label">Email<span className="l-mandatory">*</span></label>
                <input type="text" className='form-control' placeholder="Contact Person Email" 
                 onChange={(e)=>handlePersonEmailChange(e)} />
                </Form.Item>
              </div>
              
              </div>
              
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Cancel</Button>
               { !showStudents?
                <Button htmlType="submit" className="l-btn l-btn--primary"  onClick={handleSubmit}>Apply</Button>
                :null}
                { showStudents?
                <Button htmlType="submit" className="l-btn l-btn--primary" onClick={handleNextClick}>Next</Button>:null}
              </div>
            
          </Form>
        </section>
      </div>
    </div>
  );
};
 
export default InternshipApplicationWidget;
