import React from 'react';
import axios,{AxiosError} from "axios";

 const axiosRequest = axios.create({
  //baseUrl: "https://localhost:7042/api/",timeout:3000
});
 
 
//axiosRequest.defaults.baseURL= "https://localhost:7042/api/";
axiosRequest.defaults.baseURL="https://connectapi.klaconnect.in/api/";

let item= JSON.parse(localStorage.getItem('userDetails'));
 if(item!=null)
 {
  const token = item.token;
  axiosRequest.defaults.headers.common["Authorization"] = `Bearer ${token}`;
 }
// const _userLogin="Auth/userLogin/";

axiosRequest.interceptors.response.use(
  (response) => response,
   (error) => {
       if (error instanceof AxiosError && error.response?.status === 401) 
     {
       //useAuthStore.setState({ signedInAs: undefined });
       localStorage.removeItem('userDetails');
       localStorage.clear();  
       window.location.href='/login';
       window.location.reload();
     }
     return Promise.reject(error);
    },
   );
export default axiosRequest;