import "./StudentSidenav.scss";
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import SvgIcon from "../svg-icon/SvgIcon";

import { useLocation } from 'react-router-dom'


const StudentSidenav = () => {
  const SidebarData = [
    {
      title: "Home",
      path: "/studentHome",
      icon: <SvgIcon icon="dashboard" width="20" height="20" />,
    },
  
    {
      title: "InternshipApplication",
      path: "/InternshipApplication",
      icon: <SvgIcon icon="portfolio" width="20" height="20" />,
    },
    {
      title: "View Profie",
      path: "/viewStudentProfile",
      icon: <SvgIcon icon="view-profile" width="20" height="20" />,
    },
    {
      title: "Resetpassword",
      path: "/resetpassword",
      icon: <SvgIcon icon="rest-password" width="20" height="20" />,
    },
    // {
    //   title: "Contact",
    //   path: "/contact",
    //   icon: <SvgIcon icon="contact" width="20" height="22" />,
    // },
  ];

 
  const location = useLocation();
  const _userDetails = JSON.parse(localStorage.getItem('userDetails'));
  
  return (

    <aside className="sidenav">
      <Menu
        selectedKeys={[location.pathname]}
        mode="inline"
        inlineIndent="20"
      >
        {SidebarData.map((item) => {
          const menuItems =  (
            <Menu.Item key={item.path} icon={item.icon}>
              <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
          );
          return menuItems;
        })}
      </Menu>

    </aside>
  );
};

export default StudentSidenav;


