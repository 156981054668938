import "./teacher-details-widget.scss";
import { Button,Input,Form,notification,Spin,DatePicker,Select } from "antd";
import { useForm } from "react-hook-form";
import DesignationService from "../../services/designationService";
import QualificationService from "../../services/qualificationService";
import React, { useState, useEffect } from 'react';
import TeacherService from '../../services/teacherService';
 import * as moment from 'moment';
import  dayjs from 'dayjs'
import TeacherModel from "./teacherModel";
import { json, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const TeacherDetailsWidget = () => {
 
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { Option } = Select;
  let selectedQualifications = []

  const [designationList, setDesignationList] = useState(0);
  const [qualificationList, setQualificationList] = useState([]);
  const [yearList , setyearList] = useState(0);
  const [teacherDetails, setTeacherDetails] = useState(0);
  const [loading , setloading] = useState(false);
  const navigate = useNavigate();

  let selectedQualificationIds="";

  const onChange = (date, dateString) => { 
    console.log(date," dob ",dateString);
    setEditedValues("dateOfBirth",date); 
    form.setFieldsValue({ dateOfBirth: date });
  };

  const { teacherId } = useParams();
  const designationService = new DesignationService();
  const qualificationService = new QualificationService();  
  const teacherService = new TeacherService();
  const teacherModel = new TeacherModel();
  //const defautDob=new Date();
  const populateDesignation = async () => {
    const _designationList = await designationService.getDesignation();
    setDesignationList(_designationList.data);
  } 
  const  populateQualification=async()=> {   
    const _qualificationList = await qualificationService.getQualification();
    setQualificationList(_qualificationList.data);
}
  const hanleQualificationChange=(e)=>
  { 
    var _qualificationList=qualificationList; 
   // console.log("_qualificationList",_qualificationList);
    //console.log("qualification:", e);
    const qualification=[];
    e.forEach(element => {
      let _qualification;
      if(isNaN(element))
      {
        _qualification=_qualificationList.find(a=>a.qualificationName==element)
      }
      else{
        _qualification=_qualificationList.find(a=>a.qualificationId==element)
      }
      if(_qualification)
      {
        qualification.push(_qualification.qualificationId);
      }
    });
    console.log("qualification:", qualification);
    form.setFieldsValue({ qualification });      
  }
   
  const hanleDesignationChange=(e)=>{
   // console.log("remove",e);
     if(selectedQualificationIds!="")
     {
      selectedQualificationIds=selectedQualificationIds+e;
     }
    setEditedValues("designationIds",e);
    form.setFieldsValue({ designationIds: e });
  }  
const genderList = [        
  { genderName: "Male", genderId: "male" },
  { genderName: "Female", genderId: "female" }     
];
const jobTypeList = [        
  { jobTypeName: "Contratct", jobTypeId: "Contract" },
  { jobTypeName: "Permanent", jobTypeId: "Permanent" },
  { jobTypeName: "Parttime", jobTypeId: "Parttime" }      
];

const handleYearOfJoining=(e)=>
{
  setEditedValues("yearOfJoining",e); 
  form.setFieldsValue({ yearOfJoining: e });
}
const populateYearOfJoining=async()=> {
  const _yearList = await teacherService.getYear();  
  setyearList(_yearList);       
}
const handleFullNameChange=(e)=>
  { 
    setEditedValues("fullName",e.target.value); 
    form.setFieldsValue({ fullName: e.target.value });
  }
  const handleJobTypeChange=(e)=>
  {   
    setEditedValues("jobType",e); 
    form.setFieldsValue({jobType : e });
  }
  const handleGenderChange=(e)=>
  {   
    setEditedValues("gender",e); 
    form.setFieldsValue({ gender: e });
  }
  const handleEmailChange=(e)=>
  {
    setEditedValues("email",e.target.value); 
    form.setFieldsValue({email: e.target.value });
  }
  const handleAlternativeMobileNumberChange=(e)=>{
    setEditedValues("alternativeMobileNumber",e.target.value); 
    form.setFieldsValue({ alternativeMobileNumber: e.target.value });
  }
  const hanleMobileNumberChange=(e)=>
  { 
    setEditedValues("mobileNumber",e.target.value);   
    form.setFieldsValue({ mobileNumber: e.target.value });
  }
  const handleAddressChange=(e)=>
  {  
    setEditedValues("address",e.target.value);  
    form.setFieldsValue({ address: e.target.value });
  }
  
  const populateTeachersDetails = async () => {  
    const _teacherDetails = await teacherService.getTeacherDetails(teacherId);
    const qualification=document.getElementById("qualification");
    console.log("_teacherDetails",_teacherDetails);
    
    selectedQualificationIds=_teacherDetails.data.qualificationIds;
    console.log("selectedQualificationIds",selectedQualificationIds);
   if(_teacherDetails.data.qualificationIds!=undefined &&selectedQualifications.length==0)
   {
       const qualificationArray = _teacherDetails.data.qualificationNames.split(',');

       console.log("qualificationArray ",qualificationArray);
      // qualification.setFieldValue=5;
       form.setFieldValue({ qualificationIds: _teacherDetails.qualificationIds });    
      
        for (let i = 0; i < qualificationArray.length-1; i++) {           
        selectedQualifications.push(qualificationArray[i])
    }
   }
  
  setTeacherDetails(_teacherDetails.data);
   setFormFieldsValue(_teacherDetails.data);   
  }
  const setEditedValues = async (fieldName,value) => {
    const _teacherDetails=JSON.parse(JSON.stringify(teacherDetails));
    switch(fieldName) {
      case 'fullName':
        _teacherDetails.fullName=value;
        break;   
      case 'jobType':
        _teacherDetails.jobType=value;
            break;
      case 'yearOfJoining':
        _teacherDetails.yearOfJoining=value;
              break; 
      case 'dateOfBirth':
        _teacherDetails.dateOfBirth=value;
                break;           
      case 'designationId':
        _teacherDetails.designationId=value;
                break;          
      case 'qualificationIds':
        _teacherDetails.qualificationIds=value;
            break;
      case 'jobType':
        _teacherDetails.jobType=value;
              break;          
      case 'yearOfJoining':
        _teacherDetails.yearOfJoining=value;
                break;            
      case 'gender':
        _teacherDetails.gender=value;
                break;              
      case 'email':
        _teacherDetails.email=value;
          break;  
     case 'mobileNumber':
        _teacherDetails.mobileNumber=value;
       break; 
      case 'alternativeMobileNumber':
        _teacherDetails.alternativeMobileNumber=value;
                    break; 
     case 'address':
      _teacherDetails.address=value;
         break; 
       default:
           return '';
  
    }
    setTeacherDetails(_teacherDetails);
  }
  const setFormFieldsValue=(_teacherDetails)=>{
    
    //  console.log("studentDetails",_studentDetails.dateOfBirth )
     // const _studentDetails=JSON.parse(JSON.stringify(studentDetails));
      console.log("setFormFieldsValue",_teacherDetails);
      if(_teacherDetails.fullName!='')
      form.setFieldsValue({ fullName: _teacherDetails.fullName });  
      if(_teacherDetails.dateOfBirth!='')
      form.setFieldsValue({ dateOfBirth: _teacherDetails.dateOfBirth });
      if(_teacherDetails.designationId!='')
      form.setFieldsValue({ designationId: _teacherDetails.designationId });
      if(_teacherDetails.qualification!='')
      form.setFieldsValue({ qualification: _teacherDetails.qualificationIds });    
      if(_teacherDetails.jobType!='')
      form.setFieldsValue({ jobType: _teacherDetails.jobType });      
      if(_teacherDetails.yearOfJoining!='')
      form.setFieldsValue({ yearOfJoining: _teacherDetails.yearOfJoining });      
      if(_teacherDetails.gender!='')    
      form.setFieldsValue({ gender: _teacherDetails.gender });
      if(_teacherDetails.email!='')
      form.setFieldsValue({ email: _teacherDetails.email });
      if(_teacherDetails.mobileNumber!='')
      form.setFieldsValue({ mobileNumber: _teacherDetails.mobileNumber });
    }
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const handleSubmit = (values) => {

    console.log("values",values.qualification.toString());
    setloading(true);
    let dob = new Date();
    dob = moment(values.dateOfBirth, 'DD-MM-YYYY');
    teacherModel.teacherID=teacherId;
    teacherModel.fullName=values.fullName;
    teacherModel.dateOfBirth = dob;
    teacherModel.qualificationIds =values.qualification.toString();
    teacherModel.jobType = values.jobType;
    teacherModel.designationId = values.designationId;
    teacherModel.yearOfJoining = values.yearOfJoining.toString();        
    teacherModel.gender = values.gender;
    teacherModel.email = values.email;
    teacherModel.mobileNumber = values.mobileNumber; 
    if(values.alternativeMobileNumber!== undefined)
    teacherModel.alternativeMobileNumber = values.alternativeMobileNumber;
    else
    teacherModel.alternativeMobileNumber = "";      
    //teacherModel.alternativeMobileNumber = values.alternativeMobileNumber;     
    
    if(values.address!== undefined)
    teacherModel.address = values.address;
    else
    teacherModel.address = "";    

    //teacherModel.address = values.address;

    console.log("teacherModel",teacherModel);
    teacherService.updateTeacher(teacherModel).then(val => {
       console.log("val.status", val.status);
      if (val==1) {  
           
            openNotificationWithIcon('success','Data saved success fully');
            console.log(teacherModel.teacherID);
            handleInputFieldRest(); 
        }
        else  if (val==-101) { 
          openNotificationWithIcon('warning','Duplicate Mobile Number');  
          }
          else  if (val==-102) {  
            openNotificationWithIcon('warning','Duplicate Email');   
          }
          else  if (val==-103) {
            openNotificationWithIcon('warning','Duplicate Record');      
          }
        else{
          openNotificationWithIcon('error','Data sav failed');
        }
    });
    setloading(false);
 
}
 const handleInputFieldRest=()=>{  
  form.resetFields();
  navigate("/teacherlist");  
 }
  useEffect(() => { 
    setloading(true);
    populateQualification();
    populateDesignation();
    populateYearOfJoining();
   // if(qualificationList.length>0)
   setloading(false);

  },[]);
  useEffect(() => {populateTeachersDetails();},[qualificationList]);
  var customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat);
 
  return (
     
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Teacher Registration</h2></div>
      <div className="content-block">
        <section className="content">
        <Spin tip="Loading" size="large" spinning={loading} delay={500}>
        <Form      
      form={form}
      name="frmStudentRegistration"      
      onFinish={handleSubmit}    
      autoComplete="off">  
               <div className="row gx-3 gy-4">
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="fullName"  rules={[{ required: true,message: 'Full Name is required' }]}>
                <label className="form-label">Full Name<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Full Name"
                    onChange={(e)=>handleFullNameChange(e)} value={teacherDetails.fullName}
                 />                
                </Form.Item>
              </div>            
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="dateOfBirth"  rules={[{ required: true,message: 'Date of Birth is required'  }]}>
                <label className="form-label">Date of Birth<span className="l-mandatory">*</span></label>
                {/* <DatePicker onChange={onChange} format={"DD/MM/YYYY"}  
                defaultValue={moment((teacherDetails.dateOfBirth))}
                    disabledDate={(current) => current.isAfter(moment().subtract(18,"year"))}/>    */}
                    {console.log("  dob  ",dayjs().subtract(18,"year"))}
                    <DatePicker onChange={onChange} format={"DD/MM/YYYY"} allowClear={false}
                  
                      value={moment(teacherDetails.dateOfBirth,"DD/MM/YYYY")}
                    disabledDate={(current) => {
                      {console.log("  dob  ",current)}
                    return  current && current.isAfter(dayjs().subtract(18,"year"))}}/>   
                </Form.Item>            
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="designationId"  rules={[{ required: true,message: 'Designation is required' }]}> 
                <label className="form-label">Designation<span className="l-mandatory">*</span></label>                
                <Select className="w-100" value={teacherDetails.designationId}
                  showSearch id="designationId"
                  placeholder="Designation"
                  size="large"                
                  optionFilterProp="children"
                  onChange={(e)=>hanleDesignationChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {designationList&&designationList.map(designation => {return <Option value={designation.designationId} key={designation.designationId}
                  >{designation.designationName}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="qualification"  rules={[{ required: true,message: 'Qualification is required' }]}> 
                <label className="form-label">qualification<span className="l-mandatory">*</span></label>
                <Select className="w-100" 
               
                           defaultValue={selectedQualifications} 
                id="qualification" 
                  showSearch 
                  placeholder="Qualification"
                  size="large"
                  optionFilterProp="children"
                  mode="multiple"
                  onChange={(e)=>hanleQualificationChange(e)}                  
                 
                >
                 {qualificationList&&qualificationList.map(qualification => 
                  {return <Option value={qualification.qualificationId} key={qualification.qualificationId}>
                    {qualification.qualificationName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="jobType"  rules={[{ required: true,message: 'Job Type is required' }]}> 
                <label className="form-label">Job Type<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={teacherDetails.jobType}
                  showSearch
                  placeholder="Job Type"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleJobTypeChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {jobTypeList&&jobTypeList.map(job => {return <Option value={job.jobTypeId} key={job.jobTypeId}>{job.jobTypeName}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
             
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="yearOfJoining"  rules={[{ required: true,message: 'Year Of Joining is required'}]}>
                <label className="form-label">Year Of Joining<span className="l-mandatory">*</span></label>
                  <Select className="w-100" value={teacherDetails.yearOfJoining}
                  showSearch
                  placeholder="Year Of Joining"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleYearOfJoining(e)}                   
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {yearList&&yearList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="gender"  rules={[{ required: true,message: 'Gender is required'}]}>
                <label className="form-label">Gender<span className="l-mandatory">*</span></label>
                <Select className="w-100" value={teacherDetails.gender}
                  showSearch
                  placeholder="Gender"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleGenderChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {genderList&&genderList.map(gender => {return <Option value={gender.genderId} key={gender.genderId}>{gender.genderName}</Option>})}                 
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              
                <Form.Item  name="email"  
    rules={[
      { required: true, message: 'E-mail is required' },
      {
        type: 'email',
        message: 'The input is not valid E-mail!',
      },
    ]}
  >
    <label className="form-label">E-mail<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="E-mail" 
                onChange={(e)=>handleEmailChange(e)} value={teacherDetails.email}   />  
  </Form.Item> 
              </div>
              <div className="form-group col-md-6 col-lg-3">
               <Form.Item name="mobileNumber"  rules={[{ required: true,message: 'Mobile Number is required' },
               {
               
                 pattern: new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
                 message: 'Phone must be in the right format'
               },
              ]}>
                <label className="form-label">Mobile Number<span className="l-mandatory">*</span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" 
                onChange={(e)=>hanleMobileNumberChange(e)}  value={teacherDetails.mobileNumber} 
                />
               </Form.Item>  
               </div> 
               <div className="form-group col-md-6 col-lg-3">
               <Form.Item name="alternativeMobileNumber"  rules={[{               
               pattern: new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
               message: 'Phone must be in the right format'
             }]}>
                <label className="form-label">Alternative Mobile Number<span className="l-mandatory"></span></label>
                <Input type="text" className='form-control' placeholder="Mobile Number" 
                onChange={(e)=>handleAlternativeMobileNumberChange(e)} value={teacherDetails.alternativeMobileNumber}    
                />
               </Form.Item>  
               </div> 
             
               <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="address">
                <label className="form-label">Address<span className="l-mandatory"></span></label>
                <TextArea rows={4} onChange={(e)=>handleAddressChange(e)} value={teacherDetails.address}   />
                </Form.Item>
              </div> 
              <div className="form-group col-md-6 col-lg-6"></div>
               
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Cancel</Button>
                <Button type="primary" htmlType="submit" className="l-btn l-btn--primary">
                Update
        </Button>
              </div>
            </div>
         {/* </form> */}
         </Form>
         </Spin>
        </section>
      </div>
    </div>
    
  );
};
 
export default TeacherDetailsWidget;
