import "./admin-home-widget.scss";
import { Table,notification,Button,Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import InternshipService from "../../services/internshipService ";
import ReportService from "../../services/reportService";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import InterenshipService from "../../services/internshipService ";


const AdminHomeWidget = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [loading , setloading] = useState(false);
  //const [studentId, setstudentId] = useState(0);
  const internshipService=new InternshipService();
  const reportService=new ReportService();
  const navigate = useNavigate();
  const interenshipService=new InterenshipService();
 
  const populateApplicationDetails=async()=>
  {     
    let applicationStatusId=4;
    console.log("adminhome")
    const _applicationList=await internshipService.getStudentInternshipListForAdmin(applicationStatusId);
    console.log("_applicationList",_applicationList);
    setApplicationList(_applicationList.data)
   
  }
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const changeApplicationStatus=async(internshipRequestId)=>
  { 
    const items = JSON.parse(localStorage.getItem('userDetails'));
    let adminId=items.userLoginId;
    console.log("items",items);  

    console.log("adminId",adminId);
    let printInternshipRequest={
      "internShipRequestId":internshipRequestId,
      "userLoginId":adminId,      
      "ApplicationStatusId":6
    }
    const result = await interenshipService.updateInterenshipSatusForAdmin(printInternshipRequest);
    if(result==true)
    {   
      openNotificationWithIcon('success','Application downloaded');
      populateApplicationDetails();
      //navigate("/teacherHome");
    }
  }
  
  const onClickSendEmail=(row)=>{
    console.log("view",row);
    if(row.internShipRequestId!=undefined)
    { 
      setloading(true);     
      reportService.generatePdfReport(row.internShipRequestId).then(obj=>
        {         
          const result= obj.data;  
          console.log("result",result); 
          if(result==true)  
          {
            changeApplicationStatus(row.internShipRequestId);           
          }        
  }) 
  setloading(false);
  }
  
  }
  const columns = [
    {
      title: 'Application Number',
      dataIndex: 'applicationNumber',
      key: 'applicationNumber',
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Application Satus',
      dataIndex: 'applicationStatus',
      key: 'applicationStatus',
    },
    {
      title: 'Application Submitted By',
      dataIndex: 'applicationSubmittedBy',
      key: 'applicationSubmittedBy',
    },
   
    {
      title: '',
      dataIndex: 'internShipRequestId',      
      key: 'internShipRequestId',
      render: (text, record, index) => < div className = "btn-wrap"
      style = {
        {
          width: "200px"
        }
      } > <Button onClick = {
        (e) => {
          console.log("corresponding email is :", record)
          onClickSendEmail(record)
        }
      } > Send Email </Button></div >
    },
     
    
  ];
  useEffect(() => {
    //  if(studentId==0)
    //  {
      //  console.log("pageload",studentId);
       populateApplicationDetails();
    //  }
  },[]);
  return (
    <div className="home">
       <Spin tip="Loading" size="large" spinning={loading} delay={500}>
      <div className="l-title-block"><h2>Internship Applications</h2></div>
      <div className="content-block">
        <section className="content">
        {applicationList && applicationList.length?  ( 
        <section className="content">       
          <Table dataSource={applicationList} columns={columns} rowKey="internShipRequestId"/>       
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
          {/* <Table dataSource={dataSource} columns={columns} />; */}
        </section>

      </div>
      </Spin>
    </div>
  );
};

export default AdminHomeWidget;
