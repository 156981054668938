import "./internship-details-widget.scss";
import { Table,TableProps,Select, Button, Input,DatePicker,Space,Form,notification,Modal } from "antd";
import { useForm } from "react-hook-form";
import * as moment from 'moment';
import React, { useState, useEffect } from 'react';
import InterenshipService from "../../services/internshipService ";
import { json, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const InternshipDetailsWidget = () => {
  const [form] = Form.useForm();

  const { internshipRequestId } = useParams();

  const [internshipApplicationDetails , setinternshipApplicationDetails] = useState(0);
  const [studentList , setStudentList] = useState([]); 
  //const interenShipModel = new InterenShipModel();
  const navigate = useNavigate(); 
  const interenshipService=new InterenshipService();

const populateIntershipDetails = async () => {  
  const result = (await interenshipService.getInternshipDetails(internshipRequestId));
  console.log("result",result.data);
  var _internshipDetails=result.data.internshipDetailsResponse;
  console.log("_internshipDetails",_internshipDetails);
  setinternshipApplicationDetails(_internshipDetails);
  var _internshipGroup=result.data.internshipGroupResponse;
  setStudentList(_internshipGroup);
 
}
const columns = [
  {
    title: 'Roll Number',
    dataIndex: 'rollNumber',      
    key: 'rollNumber', 
  },
  {
    title: 'Batch Name',
    dataIndex: 'batchName',      
    key: 'batchName', 
  }, 
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',    
  }, 
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',    
  }, 
  
 
];

const handelCancel=()=>
{
  navigate("/studentHome");
}

  useEffect(() => {
    if(internshipApplicationDetails!=null)
    {
        populateIntershipDetails();
    }
    localStorage.removeItem('internshipData'); 
  },[]);

  return (
    <div className="upload-widget">
      <div className="l-title-block"><h2 className="mb-0">Interenship Application Details</h2></div>
      <div className="content-block">
        <section className="content">
       <div className="row gx-3 gy-4">
      <div className="form-group col-md-12 col-lg-12"><b>Application Details</b> </div>
      <div className="form-group col-md-6 col-lg-3"> 
        <label className="form-label">Application Number<span className="l-mandatory"></span></label>               
                <label className="form-label">{internshipApplicationDetails.applicationNumber}</label>       
      </div>
      <div className="form-group col-md-6 col-lg-3"> 
        <label className="form-label">Application Type<span className="l-mandatory"></span></label>               
                <label className="form-label">{internshipApplicationDetails.internshipType}</label>       
      </div>
      <div className="form-group col-md-6 col-lg-3">        
                <label className="form-label">Start Date</label>
                <label className="form-label">{internshipApplicationDetails.startDate}</label>       
             </div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label">End Date</label>
                <label className="form-label">{internshipApplicationDetails.endDate}</label>               
              </div>
        </div> 
            <div className="row gx-3 gy-4">
            <div className="form-group col-md-12 col-lg-12"> Organization Details</div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label">Name</label>
                <label className="form-label">{internshipApplicationDetails.organizationName}</label>  
               </div>
              <div className="form-group col-md-6 col-lg-3">             
                <label className="form-label">Address</label>
                <label className="form-label">{internshipApplicationDetails.organizationAddress}</label>  
              </div>
              <div className="form-group col-md-6 col-lg-3">                
                <label className="form-label">Contact Number<span className="l-mandatory">*</span></label>
                <label className="form-label">{internshipApplicationDetails.organizationContactNumber}</label>  
              </div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label">Email<span className="l-mandatory"></span></label>
                <label className="form-label">{internshipApplicationDetails.organizationEmail}</label>                
              </div>
              <div className="form-group col-md-12 col-lg-12"> Contact Person Details</div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label">Person Name</label>               
                <label className="form-label">{internshipApplicationDetails.contactPersonName}</label>
              </div>
              <div className="form-group col-md-6 col-lg-3">              
                <label className="form-label"> Phone Number<span className="l-mandatory">*</span></label>
                <label className="form-label">{internshipApplicationDetails.contactNumber}</label>                
              </div>
            
              <div className="form-group col-md-6 col-lg-3">             
                <label className="form-label">Designation<span className="l-mandatory">*</span></label>
                <label className="form-label">{internshipApplicationDetails.designationName}</label>               
              </div>
              <div className="form-group col-md-6 col-lg-3">             
                <label className="form-label">Email</label>
                <label className="form-label">{internshipApplicationDetails.contactEmail}</label>                
              </div>
              
              </div>
              {studentList && studentList.length?  ( 
              <section className="content">       
                <Table dataSource={studentList} columns={columns} rowKey="studentId" pagination={false} />       
              </section>
                ):<div className="l-norecords">No records found !!</div>
              }
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handelCancel}>Cancel</Button>              
              </div>
           </section>
       
      </div>
    </div>
  );
};
 
export default InternshipDetailsWidget;
