import React from 'react';
import  axiosRequest  from './apiCalls';
//import axios from 'axios';


class TeacherAllocationService {

    constructor() {       
        this.saveTeacherAllocation = this.saveTeacherAllocation.bind(this);
        this.getAllocationDetails = this.getAllocationDetails.bind(this); 
        this.getAllocationList = this.getAllocationList.bind(this);  
        this.deactivateAllocation=this.deactivateAllocation.bind(this);      
    }
   
   async saveTeacherAllocation(teacherAllocationModel) {
      // console.log("service--", teacherAllocationModel);
       const id = 0;
       teacherAllocationModel.teacherAllocationID=0;      
       return await axiosRequest.post('TeacherAllocation/SaveTeacherAllocation/', teacherAllocationModel)
           .then(resp => this.id = resp.data)        
    }

    async deactivateAllocation(teacherAllocationId) {
      console.log("service--", teacherAllocationId);
      const result=false;
      return axiosRequest.patch('TeacherAllocation/DeactivateTeacherAllocation?teacherAllocationId='+teacherAllocationId+'&isActive=false')
      .then((response) => {
         console.log("response",response.data);
         return response.data 
       })
        
    }
  
    async getAllocationDetails(teacherAllocationId) {
      return await axiosRequest.get(`TeacherAllocation/GetTeacherAllocationDetails/?teacherAllocationId=${teacherAllocationId}`)
      .then((response) => {
       // console.log("allocationDetails",response.data);
        return response.data;
     
      });
  }
    async getAllocationList() {
        return  axiosRequest({
            method: "get",
            url: 'TeacherAllocation/GetTeacherAllocationList',                  
            config: {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
          })
            .then((response) => {
              console.log(response.data);
              return response.data;           
            })
            .catch((error) => {
              console.log("the error has occured: " + error);
            });
    }
    async updateTeacherAllocation(teacherAllocationModel) {
      console.log("service--", teacherAllocationModel);
      const result=0;
     
      return await axiosRequest.put('TeacherAllocation/UpdateTeacherAllocation/', teacherAllocationModel)
          .then(resp => this.result = resp.data) 
      
    }
}


export default TeacherAllocationService;