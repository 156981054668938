import "./group-internship-application-widget.scss";
import { Table,TableProps,Select, Button, Input,Space,Form,notification,Modal } from "antd";
import { useForm } from "react-hook-form";
import * as moment from 'moment';
import CourseService from "../../services/courseService";
import React, { useState, useEffect } from 'react';
import BatchService from "../../services/batchService";
//import SemesterService from "../../services/semesterService";
import InternShipService from "../../services/internshipService ";
import StudentService from "../../services/studentService";
import { useNavigate } from "react-router-dom";

const GroupInternshipApplicationWidget = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  
   const [batchList, setBatchList] = useState(0);
   const [showStudents , setshowStudents] = useState(0);
  const [studentList , setStudentList] = useState([]); 
  const [studentData , setStudentsData] = useState(0); 
  const [studentId , setstudentId] = useState(0); 
  const [submittedBy , setsubmittedBy] = useState(0); 
  const batchService = new BatchService();
  const internShipService = new InternShipService();
  const navigate = useNavigate();
  const studentService=new StudentService();

  
  const  populateBatchByCourse=async(courseId)=> {   
    console.log("courseId",courseId);
    const _batchList = await batchService.getBatches(courseId);
    setBatchList(_batchList.data);
}
  
const handleBatchChange=(e)=>{
  form.setFieldsValue({ batchId: e });
}
const onRemoveApplicant = (studentID) => {
  console.log("studentID",studentID)
  const _studentList =[...studentList]; // make a separate copy of the array
  let sliceData =_studentList.filter(item => studentID !== item.studentId);
  
  if (sliceData.length>0) {
      console.log("array",sliceData);
    setStudentList(sliceData);
   
  }
}
const addStudentToGroup = (values) => {
  let rollNumber=form.getFieldValue("rollNumber");
  let batchId=form.getFieldValue("batchId");
  studentService.getStudentsDetaisForGroup(rollNumber,batchId).then(val => {
       populateTableData(val);
        console.log("newArray save",val);
        console.log("save data",studentList);
  })
   //console.log("rollNumber",form.getFieldValue("rollNumber"));
}

const handleRollNumberChange=(e)=>
{
  form.setFieldsValue({ rollNumber: e.target.value });
}
const populateTableData=(studentData)=>
{
  if(studentData.studentID>0)
  {
    //console.log("populateTableData");
    let rollNumberValue=studentData.rollNumber;
    let newArray= studentList.some(item => studentData.rollNumber === item.rollNumber);
    //  console.log("existingItems",newArray);
    if(newArray==false)
    {
      var studentItem ={'rollNumber':studentData.rollNumber,"batchName":studentData.batchName,
      "fullName":studentData.fullName,"email":studentData.email,"studentId":studentData.studentID};
      setStudentList([...studentList, studentItem]);
        form.resetFields();
    }
  }
}
const populateStudentDetails=async()=>{ 
  const items = JSON.parse(localStorage.getItem('internshipData'));
  console.log("items",items);
  let studentId=items.studentId;
  let SubmittedBy=items.applicationSubmittedBy;
  console.log("SubmittedBy",SubmittedBy)
  setsubmittedBy(SubmittedBy);
  // if(SubmittedBy==studentId)
  // {
  //   setshowHide(true);
  // }
  setstudentId(studentId);
  console.log("studentId",studentId);
  const _studentData= await studentService.getStudentsDetails(studentId);
  console.log("_studentData",_studentData.data);
  populateBatchByCourse(_studentData.data.courseId);
  setStudentsData(_studentData.data);
  populateTableData(_studentData.data);  
}
const handleSubmit = (values) => {
  const _internshipData =JSON.parse(localStorage.getItem('internshipData'));  
 
  console.log("internshipData",_internshipData);
  console.log("studentList",studentList);
  var groupInterenShipModel={interenShipModel:_internshipData,internShipDetailsRequestes:studentList};
  Modal.confirm({
    title: "Please verify the request details are correct. After submission, you would not able to edit",
    okText: "Yes",
    okType: "warning",
    onOk: () => {
  internShipService.saveGroupInternShip(groupInterenShipModel).then(val => {
      console.log("After submit val",val);
      if (val[0].internShipRequestId > 0) {
        openNotificationWithIcon('success','Data saved success fully');
        navigate('/internshipDetails/'+val[0].internShipRequestId);
      }
   });
 }
});
}
const columns = [
  {
    title: 'Roll Number',
    dataIndex: 'rollNumber',      
    key: 'rollNumber', 
  },
  {
    title: 'Batch Name',
    dataIndex: 'batchName',      
    key: 'batchName', 
  }, 
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',    
  }, 
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',    
  },
  
  {
    title: '',
    dataIndex: 'studentId',      
    key: 'studentId',
    render: (val, row, index) => row?.studentId !== submittedBy ? <Button type="button" className="l-btn l-btn--primary" onClick={()=>onRemoveApplicant(studentId)}>Remove</Button> :""
    //render: (studentId) => <Button type="button" className="l-btn l-btn--primary" onClick={()=>onRemoveApplicant(studentId)}>Remove</Button>
  }
  
];
//return columns.filter(col => col.studentId !== studentId);
const openNotificationWithIcon = (type,message) => {
  notification[type]({
    message: 'KLA',
    description:
      message,
  });
};

// const handleCourseYearChange = (event) => {
//   //  toast.success("Success Notification !");
//    // this.state.courseYearId = event.value;
//     populateSemesterByCourseYear(event);
// }
 /* async populateCourseYearByCourseId() {
    const courseYeearList = await courseYearService.getCourseYear(this.state.courseId);
    this.setState({ courseYeearList: courseYeearList.data.map((opt, i) => ({ label: opt.years, value: opt.courseYearId })) });
    this.setState({ courseYearLoading: false });
}*/
 //console.log("load",courseList);
  useEffect(() => {
   
      populateStudentDetails();
    
    //handleAdd();
  //  const _studentList=[ {
  //     key: 1,
  //     rollNumber:'',
  //     batchName: '',
     
  //   }];
  //   setStudentList(_studentList);
    // Update the document title using the browser API
   
   // populateCourse();
  },[]);
//export class UploadWidget extends Component
// {
 
 //}
  return (
    <div className="groupDetails">
      <div className="l-title-block"><h2 className="mb-0">Interenship Applicant Details</h2></div>
      <div className="content-block">
        <section className="content">
        <Form      
      form={form}
      name="frmInternshipApplication"      
      // onFinish={handleSubmit}    
      autoComplete="off"> 
   
            <div className="row gx-3 gy-4">
           
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="rollNumber"> 
              <label className="form-label">Roll Number<span className="l-mandatory">*</span></label>
              <input type="text" className='form-control' placeholder="Roll Number" onChange={(e)=>handleRollNumberChange(e)}/>   
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">            
              <Form.Item name="batchId"> 
                <label className="form-label">Batch<span className="l-mandatory">*</span></label>
                <Select className="w-100" id="ddlBatch" 
                  showSearch
                  placeholder="Batch"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleBatchChange(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                 {batchList&&batchList.map(batch => {return <Option value={batch.batchId} key={batch.batchId}>{batch.batchName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Button htmlType="submit" className="l-btn l-btn--primary" onClick={addStudentToGroup}>Add</Button>
              </div>
              </div>
              <div className="row gx-3 gy-4">
           
             <div className="form-group col-md-12 col-lg-12">
              {/* {console.log("studentList",studentList)} */}
            {studentList && studentList.length?  ( 
        <section className="content">       
          <Table dataSource={studentList} columns={columns} rowKey="studentId" pagination={false} />       
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
        </div>
       </div>
      
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline">Cancel</Button>                
                <Button type="button" className="l-btn l-btn--primary" onClick={handleSubmit}>Apply</Button>
              </div>
            
          </Form>
        </section>
      </div>
    </div>
  );
};
 
export default GroupInternshipApplicationWidget;
