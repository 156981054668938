
export default class UserloginModel {   
    
     userName;
     password;
     
    constructor(userName,password) {       
        this.userName = userName;
        this.password = password;
      
    }
  
}

