import "./teacher-allocation-widget.scss";
import { Table,TableProps, Select,Input,Form,Button,Modal,notification,Spin } from 'antd';
import { useForm } from "react-hook-form";
import CourseService from "../../services/courseService";
import React, { useState, useEffect } from 'react';
import BatchService from "../../services/batchService";
import SemesterService from "../../services/semesterService";
import CourseYearService from "../../services/courseYearService";
// import StudentService from '../../services/studentService';
import { useNavigate } from "react-router-dom";
import TeacherService from '../../services/teacherService';
import TeacherAllocationModel from "./teacherAllocationModel";
import TeacherAllocationService from '../../services/teacherAllocationService';


const TeacherAllocationWidget = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [courseList, setCourseList] = useState(0);
  const [batchList, setBatchList] = useState(0);
  const [courseYearList , setcourseYearList] = useState(0);
  const [semesterList , setsemesterList] = useState(0);
  const [teacherList , setTeacherList] = useState(0);
  const [allocationList , setallocationList] = useState(0);
  const [allocationData , setallocationData] = useState(0);
  const [btnText , setBtnText] = useState(0);
  const [loading , setloading] = useState(false);
  const [teacherAllocationId , setTeacherAllocationId] = useState(0);

  const courseService = new CourseService();
  const batchService = new BatchService();
  const semesterService = new SemesterService();
  const courseYearService = new CourseYearService();
  const teacherService = new TeacherService();
  const teacherAllocationService=new TeacherAllocationService();
  const teacherAllocationModel = new TeacherAllocationModel();
  //const teacherAllocationId=0;

  const navigate = useNavigate();
  const populateCourse = async () => {
    const _courseList = await courseService.getCourse();
    setCourseList(_courseList.data);
  } 
  const  populateBatchByCourse=async(courseId)=> {   
    const _batchList = await batchService.getBatches(courseId);
    setBatchList(_batchList.data);
  }
  const hanleCourseChange=(e)=>
  {    
    form.setFieldsValue({ courseId: e });  
     populateBatchByCourse(e);
     populateCourseYearByCourse(e);
     setEditedValues("courseId",e); 
  }
  const hanleBatchChange=(e)=>{
    setEditedValues("batchId",e); 
    form.setFieldsValue({ batchId: e });
  }
  const  populateCourseYearByCourse=async(courseId)=> {
    const _courseYearList = await courseYearService.getCourseYear(courseId);
    setcourseYearList(_courseYearList.data);

  }
  const populateSemesterByCourseYear=async(courseYearId)=> {
    const _semesterList = await semesterService.getSemester(courseYearId);
    setsemesterList(_semesterList.data);
   }
  const populateTeacher=async()=> {
    const _teacherList = await teacherService.getTeacheres();
    //console.log("_teacherList",_teacherList);
    setTeacherList(_teacherList);
   }
   const handleCourseYearChange = (e) => {
    setEditedValues("courseYearId",e); 
    form.setFieldsValue({ courseYearId: e });
       populateSemesterByCourseYear(e);
  }
  const handleSemesterChange=(e)=>{
    setEditedValues("semesterId",e); 
    form.setFieldsValue({ semesterId: e });
  }
  
  const handleTeacherChange=(e)=>
  {  
    setEditedValues("teacherID",e); 
    form.setFieldsValue({ teacherID: e });   
  }
  useEffect(() => { 
    setloading(true); 
    setBtnText("Save"); 
    populateCourse();   
    populateTeacher();  
    populateAllocationList(); 
    setloading(false);
  },[]);
  const openNotificationWithIcon = (type,message) => {
    notification[type]({
      message: 'KLA',
      description:
        message,
    });
  };
  
  const setEditedValues = async (fieldName,value) => {
    const _allocationData=JSON.parse(JSON.stringify(allocationData)); 
     if(_allocationData!==null)
    { 
      
      console.log("setEditedValues",fieldName);    
        switch(fieldName) {          
           case 'teacherID':
            _allocationData.teacherID=value;
               break;               
         case 'courseId':
          _allocationData.courseId=value;           
               break;          
        case 'batchId':
          _allocationData.batchId=value;
               break;
          case 'courseYearId':
            _allocationData.courseYearId=value;
               break;          
         case 'semesterId':
          _allocationData.semesterId=value;
             break;
 
    }
    setallocationData(_allocationData);
   }
   
 }
  const setFormFieldsValue=(_allocationData)=>{
  //   //  console.log("studentDetails",_studentDetails.dateOfBirth )
  // const _studentDetails=JSON.parse(JSON.stringify(studentDetails));
     if(_allocationData!==null)
     {     
  //     console.log("setFormFieldsValue",_searchData);
  //     if(_searchData.rollNumber!='')
  //     form.setFieldsValue({ rollNumber: _searchData.rollNumber });  
     
      if(_allocationData.courseId!='')
      form.setFieldsValue({ courseId: _allocationData.courseId });
      if(_allocationData.batchId!='')    
      form.setFieldsValue({ batchId: _allocationData.batchId });
      if(_allocationData.courseYearId!='')    
      form.setFieldsValue({ courseYearId: _allocationData.courseYearId });
      if(_allocationData.semesterId!='')   
      form.setFieldsValue({ semesterId: _allocationData.semesterId });
      if(_allocationData.teacherID!='')   
      form.setFieldsValue({ teacherID: _allocationData.teacherID });
      }
      
    }
  const viewAllocationDetails =async(teacherAllocationId) => {
    const _allocationData = await teacherAllocationService.getAllocationDetails(teacherAllocationId);
    console.log("allocationData",_allocationData)
    populateBatchByCourse(_allocationData.courseId);
    populateCourseYearByCourse(_allocationData.courseId);
    populateSemesterByCourseYear(_allocationData.courseYearId);
    //teacherAllocationId=_allocationData.teacherAllocationID;
    setTeacherAllocationId(teacherAllocationId);
    //console.log("teacherAllocationId ",teacherAllocationId)
    setBtnText("Update");
    setallocationData(_allocationData);
    setFormFieldsValue(_allocationData);
     //CourseYear(_searchData.courseYearId);          
    };
  
  const onClickViewAllocation=(teacherAllocationId)=>{
    console.log("view",teacherAllocationId);
    teacherAllocationId=teacherAllocationId;
    // console.log(studentId);
    if(teacherAllocationId!=undefined)
    { 
      viewAllocationDetails(teacherAllocationId);
       // navigate("/StudentDetails/"+studentId);       
    }       
    

  }
  const handleInputFieldRest=()=>{  
       form.resetFields();
   }
   const onDeActivateStudent = (teacherAllocationId) => {
   
    console.log("record",teacherAllocationId);
    Modal.confirm({
      title: "Are you sure, you want to delete this allocation record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setloading(true);
       teacherAllocationService.deactivateAllocation(teacherAllocationId).then(res => {     
       if(res==true)
       {
        openNotificationWithIcon('success','Data deleted success fully');      
        populateAllocationList();        
      }
      else
      {
        openNotificationWithIcon('error','Data deletion failed');     
      }     
    });
    setloading(false);
      },
    
    });
    
  };
   const populateAllocationList=async()=> {
    setloading(true);
    const _allocationList = await teacherAllocationService.getAllocationList();    
    setallocationList(_allocationList);  
    setloading(false);   
  }
  const handleSubmit = (values) => {
    console.log("teacherAllocationId",values.teacherID);
    if(teacherAllocationId==0)    
    {
        teacherAllocationModel.teacherID = values.teacherID;
        teacherAllocationModel.courseId = values.courseId;
        teacherAllocationModel.batchId = values.batchId;
        teacherAllocationModel.courseYearId = values.courseYearId;
        teacherAllocationModel.semesterId = values.semesterId;        
        teacherAllocationService.saveTeacherAllocation(teacherAllocationModel).then(val => {
          if (val > 0) {         
              openNotificationWithIcon('success','Data saved success fully');
              console.log(teacherAllocationModel.teacherAllocationID);
              handleInputFieldRest(); 
              populateAllocationList();              
          }
            else{
              if(val==-100)
              {
                openNotificationWithIcon('warning','Duplicate record');
              }
            else{
              openNotificationWithIcon('error','Data saving failed');
            }
            }
        });
   }
   else
   {
       console.log("teacherAllocationId",teacherAllocationId)
       teacherAllocationModel.teacherID = values.teacherID;
       teacherAllocationModel.courseId = values.courseId;
       teacherAllocationModel.batchId = values.batchId;
       teacherAllocationModel.courseYearId = values.courseYearId;
       teacherAllocationModel.semesterId = values.semesterId;  
       teacherAllocationModel.teacherAllocationID=teacherAllocationId;
       teacherAllocationService.updateTeacherAllocation(teacherAllocationModel).then(val => {
        //console.log("val", val);
        if (val==1) {   
          handleInputFieldRest();       
          openNotificationWithIcon('success','Data updated success fully');
          populateAllocationList();
          
      }
        else{  
          if(val==-100)
              {
                openNotificationWithIcon('warning','Duplicate record');
              }
            else{
              openNotificationWithIcon('error','Data sav failed');
            }
        }
       })
   }
  }
  const columns = [
   
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName)
    },   
    {
      title: 'Course Name',
      dataIndex: 'courseName',
      key: 'courseName',
     
    },
    {
      title: 'Batch Name',
      dataIndex: 'batchName',
      key: 'batchName',
    },
    {
      title: 'Semester',
      dataIndex: 'semester',
      key: 'semester',
    },
    
    {
      title: 'Course Year',
      dataIndex: 'courseYear',
      key: 'courseYear',
    },
    {
      title: '',
      dataIndex: 'teacherAllocationId',      
      key: 'teacherAllocationId',
      render: (teacherAllocationId) => <a className="l-link" onClick={()=>onClickViewAllocation(teacherAllocationId)}>View</a>
    },
    {
      title: '',
      dataIndex: 'teacherAllocationId',      
      key: 'teacherAllocationId',
      render: (record) => <a className="l-link" onClick={()=>onDeActivateStudent(record)}>DeActivate</a>
    }
  ];
   
  return (
    <div className="home">
      <div className="l-title-block"><h2>Teacher Allocation</h2></div>
      <div className="content-block">
      
      <Form      
      form={form}
      name="frmStudentList"      
      onFinish={handleSubmit}
      autoComplete="off">  
       <Spin tip="Loading" size="large" spinning={loading} delay={500}>
      <div className="row gx-3 gy-4">
      <div className="form-group col-md-6 col-lg-3">    

      <Form.Item name="courseId"  rules={[{ required: true,message: 'Course is required' }]}> 
                <label className="form-label">Course<span className="l-mandatory">*</span></label>                
                <Select className="w-100" id="ddlCourse" value={allocationData.courseId}
                  showSearch
                  placeholder="Course"
                  size="large"                
                  optionFilterProp="children"
                  onChange={(e)=>hanleCourseChange(e)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                  {courseList&&courseList.map(course => {return <Option value={course.courseId} key={course.courseId}>{course.courseName}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="batchId"  rules={[{ required: true,message: 'Batch is required' }]}> 
                <label className="form-label">Batch<span className="l-mandatory">*</span></label>
                <Select className="w-100" id="ddlBatch" value={allocationData.batchId}
                  showSearch
                  placeholder="Batch"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>hanleBatchChange(e)}                  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                 {batchList&&batchList.map(batch => {return <Option value={batch.batchId} key={batch.batchId}>{batch.batchName}</Option>})}
              </Select>
              </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="courseYearId" rules={[{ required: true,message: 'Course Year is required' }]}> 
                <label className="form-label">Course Year<span className="l-mandatory"></span></label>
                <Select className="w-100" value={allocationData.courseYearId}
                  showSearch
                  placeholder="Course Year"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleCourseYearChange(e)}                 
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>                
                {courseYearList&&courseYearList.map(courseYear => {return <Option value={courseYear.courseYearId} key={courseYear.courseYearId}>{courseYear.years}</Option>})}
                 </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="semesterId" rules={[{ required: true,message: 'Semester is required' }]}>
                <label className="form-label">Semester<span className="l-mandatory"></span></label>
                <Select className="w-100" value={allocationData.semesterId}
                  showSearch
                  placeholder="Semester"
                  size="large"
                  optionFilterProp="children"
                  onChange={(e)=>handleSemesterChange(e)}  
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                 {semesterList&&semesterList.map(semester => {return <Option value={semester.semesterId} key={semester.semesterId}>{semester.semester}</Option>})}
                </Select>
                </Form.Item>
              </div>
              <div className="form-group col-md-6 col-lg-3">
              <Form.Item name="teacherID" rules={[{ required: true,message: 'Teacher is required' }]}>
                <label className="form-label">Teacher<span className="l-mandatory">*</span></label>
                  <Select className="w-100" value={allocationData.teacherID}
                  showSearch
                  placeholder="teacher"
                  size="large"                  
                  optionFilterProp="children"  
                  onChange={(e)=>handleTeacherChange(e)}                   
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }>
                 {teacherList&&teacherList.map(te => {return <Option value={te.teacherID} key={te.teacherID}>{te.fullName}</Option>})}
                  {/* {teacherList&&teacherList.map(yr => {return <Option value={yr.year} key={yr.year}>{yr.year}</Option>})}                  */}
                </Select>
                </Form.Item>
              </div>
             
             
              </div>
              <div className="l-btn-wrapper mt-2">
                <Button type="button" className="l-btn l-btn--outline" onClick={handleInputFieldRest}>Clear</Button>
                <Button type="button" htmlType="submit" className="l-btn l-btn--primary">
                {btnText}
        </Button>

              </div>
              </Spin>
              </Form>
       
       {allocationList && allocationList.length?  ( 
        <section className="content">       
          <Table dataSource={allocationList} columns={columns} rowKey="teacherAllocationId"/>       
        </section>
          ):<div className="l-norecords">No records found !!</div>
         }
      </div>
    </div>
  );
};

export default TeacherAllocationWidget;
