import Url from 'assets/images/sprite.svg';
const SvgIcon = (props) => {
  return (
    <svg className="l-svg-icon" {...props}>
      <use xlinkHref={`${Url}#${props.icon}`} />
    </svg>
  );
}

export default SvgIcon;
