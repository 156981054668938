import "./header.scss";
import logo from "assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt,faBars } from "@fortawesome/free-solid-svg-icons";
import AuthService from '../../../services/authService';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

const sidebarToggle = () => {
  document.querySelector('.main').classList.toggle("open");
}

const Header = () => {
  const navigate = useNavigate();
  const authService = new AuthService();
  const [showLogout , setshowLogout] = useState(false);

  const logout=()=>{  
    console.log("logout");
    authService.getUserLogout();
    localStorage.removeItem('userDetails');
    localStorage.clear();
    
    navigate("/login"); 
    window.location.reload();
     
  }
  const checkUserLoggedIn=()=>
  {
    const items = JSON.parse(localStorage.getItem('userDetails'));
    if(items)
    {
    let referenceId=items.referenceId;
    let roleId=items.roleId;
    if(roleId>0 && referenceId>0)
    {
      setshowLogout(true);
    }
    else{
      setshowLogout(false);
    }
  }
  }
  useEffect(() => {   
    checkUserLoggedIn();
   },[]);
  return (
   
    <header className="header">
      
      <div className="logo">
        <img src={logo} alt="Kerala Law Academy" title="Kerala Law Academy" width="200" />
      </div>
      <div className="d-flex align-items-center">
        <a className="header__toggle" onClick={sidebarToggle}>
        <FontAwesomeIcon icon={faBars} />
        </a>
        {showLogout?
        <a className="logout" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} /><span className="ms-2">Logout</span></a>
        :""}
      </div>
    </header>
  );
}

export default Header;