import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import UploadPage from 'pages/UploadPage';
import StudentRegistrationPage from 'pages/StudentRegistrationPage'
import TeacherRegistrationPage from 'pages/TeacherRegistrationPage'
import Footer from 'components/shared/footer/Footer';
import Header from 'components/shared/header/Header';
import Sidenav from 'components/shared/sidenav/Sidenav';
import NotFoundPage from 'pages/NotFoundPage';
import HomePage from 'pages/HomePage';
import StudentUploadPage from 'pages/StudentUploadPage';
import StudentDetailsPage from 'pages/StudentDetailsPage';
import TeacherDetailsPage from 'pages/TeacherDetailsPage';

import StudentListPage from 'pages/StudentListPage';
import TeacherListPage from 'pages/TeacherListPage';
import InternshipApplicationPage from 'pages/InternshipApplicationPage';
import StudentUploadListPage from 'pages/StudentUploadListPage';
import UserLoginDetailsPage from 'pages/UserLoginPage';
import TeacherAllocationPage from 'pages/TeacherAllocationPage';
import StudentSidenav from 'components/shared/studentSidenav/StudentSidenav';
import GroupInternshipApplicationPage from 'pages/GroupInternshipApplicationPage';
import InternshipDetailsPage from'pages/InternshipDetailsPage';
import StudentHomePage from'pages/StudentHomePage';
import StudentProfilePage from 'pages/StudentProfilePage';
import ResetPasswordPage from'pages/ResetPasswordPage';
import TeacherSidenav from 'components/shared/teacherSidenav/TeacherSidenav';
import TeacherHomePage from 'pages/TeacherHomePage';
import VerifyInternshipApplicationPage from 'pages/VerifyInternshipApplicationPage'
import TeacherProfilePage from 'pages/TeacherProfilePage'
import StudentPromotionPage from 'pages/StudentPromotionPage'
import AdminHomePage from 'pages/AdminHomePage'
import InternshipAllApplicationPage from 'pages/InternshipAllApplicationPage';
import SuperAdminHomePage from 'pages/SuperAdminHomePage';
import SuperAdminSidenav from 'components/shared/superAdminsidenav/SuperAdminSidenav'

const Main=() => {
 
          const _userDetails = JSON.parse(localStorage.getItem('userDetails'));
console.log("_userDetails",_userDetails);
    return(
      <div className='wrapper'>
       {<Header/>}
     
      <main className="main">
      {
            {
             "1":<TeacherSidenav/>,
             "2":<StudentSidenav/>,
             "3":<Sidenav/>,
             "4":<SuperAdminSidenav/>
           }[_userDetails?.roleId?.toString()] || <></>
        }
        
        <Routes>
          <Route path="/studentupload" element={<StudentUploadPage />}></Route>
           <Route path="/studentUploadList" element={<StudentUploadListPage />}></Route>
          <Route path="/studentRegistration" element={<StudentRegistrationPage />}></Route>
          {/* <Route path="/studentDetails" element={<StudentDetailsPage />}></Route> */}
          <Route path="/studentDetails/:studentId" element={<StudentDetailsPage />} />
          <Route path="/teacherDetails/:teacherId" element={<TeacherDetailsPage />} />
          <Route path="/teacherRegistration" element={<TeacherRegistrationPage />}></Route>
          <Route path="/studentList" element={<StudentListPage />}></Route>
          <Route path="/teacherList" element={<TeacherListPage />}></Route>
          {/* <Route path="/teacherList" element={<TeacherListPage />}></Route> */}
          <Route path="/teacherAllocation" element={<TeacherAllocationPage />}></Route>
          <Route path="/internshipApplication" element={<InternshipApplicationPage />}></Route>
          <Route path="/groupInternshipApplication" element={<GroupInternshipApplicationPage />}></Route>

          <Route path="/internshipDetails/:internshipRequestId" element={<InternshipDetailsPage />} />
          <Route path="/verifyInternshipApplication/:internshipRequestId" element={<VerifyInternshipApplicationPage />} />

          <Route path="/home" element={<HomePage />}></Route>
          <Route path="/studentHome" element={<StudentHomePage/>}></Route>
          <Route path="/teacherHome" element={<TeacherHomePage/>}></Route>
          <Route path="/adminHome" element={<AdminHomePage/>}></Route>
          <Route path="/superAdminHome" element={<SuperAdminHomePage/>}></Route>

          <Route path="/internshipAllApplications" element={<InternshipAllApplicationPage/>}></Route>

          <Route path="/viewStudentProfile" element={<StudentProfilePage />} />
          <Route path="/teacherProfile" element={<TeacherProfilePage />} />
          <Route path="/resetpassword" element={<ResetPasswordPage />} />         
          <Route path="/login" element={<UserLoginDetailsPage />}></Route>
          <Route path="/promotion" element={<StudentPromotionPage />} />      
          <Route path="*" element={<UserLoginDetailsPage />}></Route>
          
          {/* <Route path="*" element={<NotFoundPage />}></Route> */}
        </Routes>
      </main>
      {<Footer/>}
    </div>
    )
  }


const App = () => {
  const _userDetails = JSON.parse(localStorage.getItem('userDetails'));

  return (
    <Router>    
     {_userDetails?
      <Main/>:<UserLoginDetailsPage/>}
    </Router>
    
  );
}



export default App;